import axios from 'axios'
import * as utils from './apiUtils'

export default {
  getAll() {
    return axios.get(utils.getApiUrl('/masters'))
  },
  create({ lovName, lovKey, data }) {
    return axios.post(utils.getApiUrl(`/masters/${lovName}/${lovKey}`), data)
  },
  update({ lovName, lovKey, data }) {
    return axios.put(utils.getApiUrl(`/masters/${lovName}/${lovKey}`), data)
  },
  delete({ lovName, lovKey }) {
    return axios.delete(utils.getApiUrl(`/masters/${lovName}/${lovKey}`))
  },
}
