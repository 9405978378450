<template src="./template.html"></template>

<script>
export default {
  name: 'inspectionReportPhotos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isEdited: false,
      nextPath: '',
      showConfirmBeforeLeaveModal: false,
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next()
      return
    }
    this.nextPath = to.path
    this.showConfirmBeforeLeaveModal = true
    next(false)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  methods: {
    onEdit() {
      if (this.isEdited) { return }
      this.isEdited = true
    },
    resetEditStatus() {
      this.isEdited = false
      this.nextPath = ''
    },
    confirmBeforeUnload(e) {
      if (this.isEdited) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    closeConfirmBeforeLeaveModal() {
      this.isEdited = false
      this.$router.push(this.nextPath)
      this.nextPath = ''
      this.showConfirmBeforeLeaveModal = false
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
