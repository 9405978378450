<template src="./template.html"></template>

<script>
import inspectionReportApi from '@/api/inspectionReport'
import userApi from '@/api/user'

export default {
  name: 'printInspectionReport',
  props: [ 'id' ],
  data() {
    return {
      reports: [],
      user: {},
      userApi: userApi,
      reportType: 'default',
    }
  },
  mounted() {
    this.addGlobalPageStyle('A4 portrait')
    this.getReport_()
    this.getUser()
  },
  methods: {
    addGlobalPageStyle(sizeStr) {
      const styleNode = document.createElement('style')
      styleNode.appendChild(document.createTextNode(`@page { size: ${sizeStr}; }`))
      document.querySelector('head').appendChild(styleNode)
    },
    getUser() {
      return this.userApi.getMe()
        .then(({ data }) => {
          this.user = data
          if (this.office_name === '' || !this.office_name) {
            // this.office_name = this.user.office_name;
          }
        })
    },
    getReport_() {
      return inspectionReportApi.get_by_ids({ ids: [this.id].flat() })
        .then(({ data }) => {
          this.reports = data.map(report => this.convData(report)).flat()
        })
    },
    ensureMetaInfoStructure_(report) {
      if (!report.meta_info) { report.meta_info = {} }
      if (!report.meta_info.inspection_result_content) {
        report.meta_info.inspection_result_content = {}
      }
      if (!report.meta_info.inspection_result_content.html_quill) {
        // 元のテキストデータを入れておく
        report.meta_info.inspection_result_content.html_quill =
          report.inspection_result_content
      }
      if (!report.meta_info.inspection_result_content.tables) {
        report.meta_info.inspection_result_content.tables = []
      }
      if (!report.meta_info.shutokou_inspector) {
        report.meta_info.shutokou_inspector = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.inspection_indicate) {
        report.meta_info.inspection_indicate = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.inspection_indicate2) {
        report.meta_info.inspection_indicate2 = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.other1) {
        report.meta_info.other1 = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!Object.hasOwn(report.meta_info, 'reportMode')) {
        report.meta_info.reportMode = ''
        report.meta_info.reportModeOtherReportName = ''
      }
      if (!Object.hasOwn(report.meta_info, 'timeDispMode')) {
        report.meta_info.timeDispMode = 'from'
      }
    },
    parseJson_(data) {
      try {
        // 配列に変換
        data.construction.construction_side_witnesses =
          JSON.parse(data.construction.construction_side_witnesses)
      } catch (e) {
        // なにか入ってるかもしれないのでとりあえずそのまま配列に入れておく
        data.construction.construction_side_witnesses =
          [data.construction.construction_side_witnesses]
      }
      try {
        // 配列に変換
        data.construction_side_witnesses =
          JSON.parse(data.construction_side_witnesses)
      } catch (e) {
        // 工事件名マスタの施工側立会者を入れておく
        data.construction_side_witnesses =
          data.construction.construction_side_witnesses.slice()
      }

      try {
        data.meta_info = JSON.parse(data.meta_info)
      } catch (e) {
        // do nothing
      }
      this.ensureMetaInfoStructure_(data)

      return data
    },
    convData(report) {
      report = this.parseJson_(report)

      report.isReportModeTachiaiKensa = !report.meta_info.reportMode ||
        report.meta_info.reportMode === 'tachiai'
      report.isReportModeGenbaKakunin = report.meta_info.reportMode === 'genba'
      report.isReportModeOther = report.meta_info.reportMode === 'other'

      report.isTimeDispModeFrom = report.meta_info.timeDispMode === 'from'
      report.isTimeDispModeFromTo = report.meta_info.timeDispMode === 'from-to'
      report.isTimeDispModeYakan = report.meta_info.timeDispMode === 'yakan'
      report.isTimeDispModeNone = report.meta_info.timeDispMode === 'none'

      const htmlSplitter1 = '<br/>'
      // 検査名称
      report.inspectionNameHtmlRows =
        (this.replaceReturn(report.name) || '').split(htmlSplitter1)
      report.inspectionNameHtml = report.inspectionNameHtmlRows.join(htmlSplitter1)
      // 検査場所
      report.inspectionLocationHtmlRows =
        (this.replaceReturn(report.inspection_location) || '').split(htmlSplitter1)
      report.inspectionLocationHtml = report.inspectionLocationHtmlRows.join(htmlSplitter1)
      // 首都高速道路立会者
      report.shutokouInspectorHtmlRows =
        (this.replaceReturn(report.shutokou_inspector) || '').split(htmlSplitter1)
      report.shutokouInspectorHtml = report.shutokouInspectorHtmlRows.join(htmlSplitter1)
      // 立会者
      report.inspectorsText =
        (report.inspectors || []).map(e => e.name).join('、')
      // 施工側立会者
      report.constructionSideWitnessesText =
        (report.construction_side_witnesses || []).join('、')
      // 検査概要
      report.inspectionSummaryHtmlRows =
        (this.replaceReturn(report.inspection_summary) || '').split(htmlSplitter1)
      // 検査確認項目
      report.inspectionContentHtmlRows =
        (this.replaceReturn(report.inspection_content) || '').split(htmlSplitter1)
      // 検査確認項目 (写真)
      report.usedInspectionContentPhotos =
        report.inspection_content_photos
      // 検査結果
      report.inspectionResultContentHtmlRows =
        ((report.meta_info.inspection_result_content || {}).html_quill || '')
          .match(/<p.*?>.+?<\/p>/g)
      // 検査結果 (表)
      report.inspectionResultContentTables =
        ((report.meta_info.inspection_result_content || {}).tables || [])
      // 検査結果 (写真)
      report.usedInspectionResultContentPhotos =
        report.inspection_result_content_photos
      // 検査結果合否
      report.inspectionResultHtmlRows =
        (this.replaceReturn(report.inspection_result) || '').split(htmlSplitter1)
      // 現場指摘事項
      report.inspectionIndicateHtmlRows =
        (this.replaceReturn(report.inspection_indicate) || '').split(htmlSplitter1)
      // 現場指導事項
      report.inspectionIndicate2HtmlRows =
        (this.replaceReturn(report.inspection_indicate2) || '').split(htmlSplitter1)
      // 品質関係指摘事項
      report.qualityManagementRemarksHtmlRows =
        (this.replaceReturn(report.quality_management_remarks) || '').split(htmlSplitter1)
      // 安全関係指摘事項
      report.safetyManagementRemarksHtmlRows =
        (this.replaceReturn(report.safety_management_remarks) || '').split(htmlSplitter1)
      // その他報告事項
      report.other1HtmlRows =
        (this.replaceReturn(report.other1) || '').split(htmlSplitter1)
      // 別添資料
      report.attachedDocumentsHtmlRows =
        (this.replaceReturn(report.attached_documents) || '').split(htmlSplitter1)

      // 改ページ
      const pages = this.doPageBreak(report)

      return pages
    },
    getFirstPageOffsetTop(report) {
      // 検査概要のハコの上端のシート上端からのoffsetを返す.

      // ハコの縦padding
      const cellPadding = 4
      // 行のborder-bottom
      const borderBottom = 1
      // 文章1行分の高さ
      const htmlRowHeight = 20 // px
      // 1行の最大文字数(全て全角文字前提)
      const numLettersInRow = 38
      // ハコの高さの計算
      const getRowHeight = (numRows) => {
        return cellPadding * 2 + htmlRowHeight * numRows
      }

      // 工事件名のハコの上端のシート上端からのoffset.
      let ret = 165
      // 工事件名
      ret += getRowHeight(1) + borderBottom
      // 受注者名
      ret += getRowHeight(1) + borderBottom
      // 検査名称
      ret += getRowHeight(report.inspectionNameHtmlRows.length) + borderBottom
      // 検査日時、天気
      ret += getRowHeight(1) + borderBottom
      // 検査場所
      ret += getRowHeight(report.inspectionLocationHtmlRows.length) + borderBottom
      // 立会者
      const estRowNum1 = Math.ceil(report.inspectorsText.length / numLettersInRow)
      ret += getRowHeight(estRowNum1) + borderBottom
      // 施工側立会者
      const estRowNum2 = Math.ceil(report.constructionSideWitnessesText.length / numLettersInRow)
      ret += getRowHeight(estRowNum2) + borderBottom
      // 首都高側立会者
      if (report.meta_info.shutokou_inspector.is_visible) {
        ret += getRowHeight(Math.max(2, report.shutokouInspectorHtmlRows.length)) + borderBottom
      }
      return ret
    },
    getEstimatedFullWidthLetterCount(row) {
      // タグがあったら取る
      row = row
        .replace(/<("[^"]*"|'[^']*'|[^'">])+>/g, '')
        .replace(/<\/[^'">]>/g, '')
      let ret = 0
      for (let i = 0, len = row.length; i < len; i++) {
        // 半角は0.5文字、全角は1.0文字として計算
        const asciiCode = row.charCodeAt(i)
        ret += asciiCode < 128 ? 0.5 : 1.0
      }
      return ret
    },
    getEstimatedTableHeight(table) {
      // セルのborder
      const tableCellBorder = 1
      // セルのpadding
      const tableCellPadding = 4
      // 文章1行分の高さ
      const htmlRowHeight = 20

      // 表のハコ上側のmargin
      let ret = 10

      // 表題
      if (table.title) {
        ret += htmlRowHeight
      }

      // 行数から表の高さを算出
      let estimatedTableHeight = 0
      // 全行分の罫線
      estimatedTableHeight += (table.numRows + 1) * tableCellBorder
      // 全行分のpadding
      estimatedTableHeight += table.numRows * (tableCellPadding * 2)
      // (改行含む)文章全行分の高さ
      estimatedTableHeight += htmlRowHeight * table.numDispRows

      // 算出した高さと表のoffsetHeightを比較. 高い方を使ってみる
      ret += Math.max(table.displayHeight, estimatedTableHeight)

      // 表のハコ下側のmargin
      ret += 8

      return ret
    },
    doPageBreak(report) {
      // シート全体の高さ
      const sheetHeight = 1122.5 // 297mm
      // 上側の隙間
      const offsetTop = 94 // 30 + 立会検査報告書のヘッダ
      // 下側の隙間
      const offsetBottom = 30
      // ハコの縦padding
      const cellPadding = 4
      // 行のborder-bottom
      const borderBottom = 1
      // 検査概要のハコの上端のシート上端からのoffset.
      const firstPageEstimateOffsetTop = this.getFirstPageOffsetTop(report)
      // 文章1行分の高さ
      const htmlRowHeight = 20 // px
      // 1行の最大文字数(全て全角文字前提)
      const numLettersInRow = 38
      // 写真1行分の高さ
      const photoRowHeight = 161 // px

      const rowPropsArr = this.getRowPropsArr(report)
      // 可変データを引っこ抜く
      const dataMap = {}
      for (const propInfo of rowPropsArr) {
        let arr = report[propInfo.prop] || []
        if (arr.length < propInfo.min) {
          arr = Array.from({length: propInfo.min}, () => '')
        }
        dataMap[propInfo.prop] = arr
        if (propInfo.type === 'photo') {
          // 写真は2個ずつ
          const chunkArr = []
          for (let i = 0, len = arr.length; i < len; i += 2) {
            if (i < len - 1) {
              chunkArr.push([arr[i], arr[i + 1]])
            } else {
              chunkArr.push([arr[i]])
            }
          }
          dataMap[propInfo.prop] = chunkArr
        }
        delete report[propInfo.prop]
      }
      const baseReport = JSON.parse(JSON.stringify(report))

      // 残りの余白. 1ページ目は、 検査概要以下の項目に残されたheight
      let currentUsableHeight = sheetHeight - firstPageEstimateOffsetTop - offsetBottom
      let currentReport = JSON.parse(JSON.stringify(baseReport))
      // 1枚目のみ
      currentReport.isFirstPageOfReport = true
      const pages = []
      for (const propInfo of rowPropsArr) {
        if (!propInfo.shouldShow) { continue }
        const rows = dataMap[propInfo.prop]
        if (!rows.length && propInfo.type !== 'html') { continue }

        if (propInfo.type === 'html') {
          // 行の上側padding
          currentUsableHeight -= cellPadding
        }
        for (const [idx, row] of rows.entries()) {
          let rowHeight
          if (propInfo.type === 'html') {
            rowHeight = htmlRowHeight
          } else if (propInfo.type === 'photo') {
            rowHeight = photoRowHeight
          } else if (propInfo.type === 'table') {
            rowHeight = this.getEstimatedTableHeight(row)
          }
          const marginRowHeight = htmlRowHeight

          if (currentUsableHeight - (rowHeight + marginRowHeight) < 0) {
            // 残りスペースが2行分以下になったら改ページ
            if (idx > 0 || ['photo', 'table'].includes(propInfo.type)) {
              // 項目の途中で改ページになった場合のみ、なんか表示する
              const showNextPageProp = propInfo.prop.replace(/Rows$/, '') + 'ShowNextPage'
              currentReport[showNextPageProp] = true
            }
            if (currentReport.isFirstPageOfReport) {
              // 1ページ目の場合は高さを調整して余白を埋める
              const nextPageMarkRowHeight = htmlRowHeight
              currentReport.chouseiHeight = currentUsableHeight- (nextPageMarkRowHeight + cellPadding + borderBottom)
            }
            pages.push(currentReport)
            currentReport = JSON.parse(JSON.stringify(baseReport))
            currentUsableHeight = sheetHeight - offsetTop - offsetBottom
          }
          if (!currentReport[propInfo.prop]) { currentReport[propInfo.prop] = [] }
          if (propInfo.type === 'html') {
            // 全角文字換算の文字数をざっくり算出する
            const letterCnt = this.getEstimatedFullWidthLetterCount(row)
            // 表示上の行数をざっくり算出する (ここでページまたぎしてしまうと
            // 改ページがうまくいかないのだが、そこはユーザーに調整してもらおう)
            const estimatedDispRows = Math.ceil(letterCnt / numLettersInRow) || 1
            currentReport[propInfo.prop].push(row)
            currentUsableHeight -= rowHeight * estimatedDispRows
          } else if (propInfo.type === 'photo') {
            // 写真は配列になっとる
            currentReport[propInfo.prop].push(...row)
            currentUsableHeight -= rowHeight
          } else if (propInfo.type === 'table') {
            // 表はオブジェクトになっとる
            const tableObj = {
              title: row.title,
              contents: row.contents,
            }
            currentReport[propInfo.prop].push(tableObj)
            currentUsableHeight -= rowHeight
          }
        }
        if (propInfo.type === 'html') {
          // 行の下側padding
          currentUsableHeight -= cellPadding
          // 行の下側罫線
          currentUsableHeight -= borderBottom
        }
      }
      if (currentReport) {
        if (currentReport.isFirstPageOfReport) {
          // 1ページ目の場合は高さを調整して余白を埋める
          currentReport.chouseiHeight = currentUsableHeight - (cellPadding + borderBottom)
        }

        pages.push(currentReport)
      }

      const numPages = pages.length
      for (const [idx, page] of pages.entries()) {
        page.pageInfo = {
          pageNum: idx + 1,
          numPages: numPages,
        }
        for (const propInfo of rowPropsArr) {
          const srcProp = propInfo.prop
          if (!page[srcProp]) { continue }
          if (propInfo.type === 'html') {
            const dstProp = srcProp.replace(/Rows$/, '')
            let glue = '<br/>'
            if (propInfo.type2 === 'quill') {
              glue = ''
            }
            page[dstProp] = page[srcProp].join(glue)
            // 何もないと行ごと消えてしまうのでなんか入れる
            if (!page[dstProp]) { page[dstProp] = ' ' }
          }
        }
      }

      return pages
    },
    getRowPropsArr(report) {
      const ret = []
      const push = (obj, opt) => {
        opt = opt || { tachiai: true, genba: true, other: true }
        if (report.isReportModeTachiaiKensa && opt.tachiai) {
          obj.shouldShow = true
        }
        if (report.isReportModeGenbaKakunin && opt.genba) {
          obj.shouldShow = true
        }
        if (report.isReportModeOther && opt.other) {
          obj.shouldShow = true
        }
        ret.push(obj)
      }
      // タイプ
      const typeHtml = 'html'
      const typePhoto = 'photo'
      const typeTable = 'table'
      let opt
      let isShow = false

      // 検査概要
      push({ prop: 'inspectionSummaryHtmlRows', type: typeHtml, min: 1 })
      // 検査確認項目
      push({ prop: 'inspectionContentHtmlRows', type: typeHtml, min: 1 })
      // 検査確認項目 (写真)
      push({ prop: 'usedInspectionContentPhotos', type: typePhoto, min: 0 })
      // 検査結果
      opt = { tachiai: true }
      push({ prop: 'inspectionResultContentHtmlRows', type: typeHtml, type2: 'quill', min: 1 }, opt)
      push({ prop: 'inspectionResultContentTables', type: typeTable, min: 0 }, opt)
      push({ prop: 'usedInspectionResultContentPhotos', type: typePhoto, min: 0 }, opt)
      // 検査結果合否
      opt = { tachiai: true }
      push({ prop: 'inspectionResultHtmlRows', type: typeHtml, min: 1 }, opt)
      // 現場指摘事項
      isShow = report.meta_info.inspection_indicate.is_visible
      opt = { tachiai: isShow, genba: isShow, other: isShow }
      push({ prop: 'inspectionIndicateHtmlRows', type: typeHtml, min: 1 }, opt)
      // 現場指導事項
      isShow = report.meta_info.inspection_indicate2.is_visible
      opt = { genba: isShow, other: isShow }
      push({ prop: 'inspectionIndicate2HtmlRows', type: typeHtml, min: 1 }, opt)
      // 品質関係指摘事項
      push({ prop: 'qualityManagementRemarksHtmlRows', type: typeHtml, min: 1 })
      // 安全関係指摘事項
      push({ prop: 'safetyManagementRemarksHtmlRows', type: typeHtml, min: 1 })
      // その他報告事項
      isShow = report.meta_info.other1.is_visible
      opt = { tachiai: isShow, genba: isShow, other: isShow }
      push({ prop: 'other1HtmlRows', type: typeHtml, min: 1 }, opt)
      // 別添資料
      push({ prop: 'attachedDocumentsHtmlRows', type: typeHtml, min: 1 })

      return ret
    },
    reporteeDepDisp(dep) {
      return (this.reporteeDepMap[dep] || {}).name
    },
    replaceReturn(str) {
      if (!str) { return }
      return str.replace(/\r?\n/g, '<br/>')
    },
    sortOrderAsc() {
      this.reports.sort((a, b) => {
        const m1 = a.inspection_date.match(/(\d{4})-(\d{2})-(\d{2})/)
        const inspection_date1 = m1 ? new Date(parseInt(m1[1]), parseInt(m1[2]) - 1, parseInt(m1[3])) : new Date(0, 0, 0)
        const m2 = b.inspection_date.match(/(\d{4})-(\d{2})-(\d{2})/)
        const inspection_date2 = m2 ? new Date(parseInt(m2[1]), parseInt(m2[2]) - 1, parseInt(m2[3])) : new Date(0, 0, 0)
        if (inspection_date1.getTime() !== inspection_date2.getTime()) {
          // 日付昇順
          return inspection_date1 < inspection_date2 ? -1 : 1
        }
        if (a.inspection_time !== b.inspection_time) {
          // 時刻昇順
          return a.inspection_time < b.inspection_time ? -1 : 1
        }
        if (a.id !== b.id) {
          // id昇順
          return a.id < b.id ? -1 : 1
        }
        // ページ番号昇順
        return a.pageInfo.pageNum < b.pageInfo.pageNum ? -1 : 1
      })
    },
    sortOrderDesc() {
      this.sortOrderAsc()
      this.reports.reverse()
      this.reports.sort((a, b) => {
        if (a.id === b.id) {
          // ページ番号は常に昇順
          return a.pageInfo.pageNum < b.pageInfo.pageNum ? -1 : 1
        }
        return 0
      })
    },
    showPrintDialog() {
      window.print()
    },
    showInspectionContentChousei(report) {
      if (!report.chouseiHeight) { return false }

      return (report.isReportModeTachiaiKensa && !report.inspectionResultContentHtml) ||
        report.isReportModeGenbaKakunin ||
        report.isReportModeOther
    },
    showInspectionResultContentChousei(report) {
      if (!report.chouseiHeight) { return false }

      return report.isReportModeTachiaiKensa
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
