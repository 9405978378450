<template>
<calendar
  v-model="inputValue"
  v-bind="$props"
  @keyup="onKeyup"
></calendar>
</template>

<script>
import Vue from 'vue'
import Calendar from '@/components/lib/Calendar'
import { calendarDefaults } from '@/consts/calendar'

export default {
  name: 'my-calendar',
  props: {
    // value or dateValue is required
    value: {
      type: String
    },
    dateValue: {
      type: [String, Date]
    },

    format: {
      type: String,
      default: calendarDefaults.format
    },
    inputClassObj: {
      type: Object,
    },
    width: {
      type: String,
      default: '160px'
    },
    inputClearButton: {
      type: Boolean,
      default: calendarDefaults.inputClearButton
    },
    clearButton2Disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: calendarDefaults.readonly
    },
    placeholder: {
      type: String
    },
    lang: {
      type: String,
      default: calendarDefaults.lang
    },
    pane: {
      type: Number,
      default: calendarDefaults.pane
    },
    dayHeaderFormat: {
      type: String,
      default: calendarDefaults.dayHeaderFormat
    },
    disableSwitchMonthView: {
      type: Boolean,
      default: calendarDefaults.disableSwitchMonthView
    },
    onDayClick: {
      type: Function,
      default() {}
    },
    changePane: {
      type: Function,
      default() {}
    },
    rangeBus: {
      type: Function,
      default() {
        // return new Vue()
      }
    },
    dateInputBgColor: {
      type: String,
    },
  },
  data() {
    return {
      calendarDefaults: calendarDefaults,
    }
  },
  computed: {
    inputValue: {
      get() {
        // 'value' has higher priority
        if (this.value !== null && this.value !== undefined) {
          return this.value
        }

        // accept date or string as 'dateValue'
        if (!this.dateValue) { return '' }
        const dtFormat = Vue.filter('dtFormat')
        return dtFormat(new Date(this.dateValue), this.format)
      },
      set(val) {
        // emit string
        this.$emit('input', val)
        // emit date
        const dt = Vue.filter('ensureDate')(val)
        if (isNaN(dt)) {
          this.$emit('date-input', null)
        } else {
          this.$emit('date-input', dt)
        }
      },
    },
  },
  methods: {
    onKeyup($event) {
      this.$emit('keyup', $event)
    }
  },
  components: { Calendar }
}
</script>
