export default {
  methods: {
    handleErrorResponse(err) {
      const errors = {}
      const errRes = err.response.data
      Object.keys(errRes).forEach(k => {
        if (!errors[k]) { errors[k] = [] }
        errors[k] = errors[k].concat(errRes[k])
      })
      this.errors = errors
    },
  }
}
