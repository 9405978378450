import Vue from 'vue'

export default {
  methods: {
    getListInitialSearchParams() {
      // 直近30日
      const dtFormat = Vue.filter('dtFormat')
      const dtTo = new Date()
      const dtFrom = new Date(dtTo.valueOf() - 86400*30*1000)

      const dtStrFrom = dtFormat(dtFrom, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(dtTo, 'yyyy/mm/dd')
      return { dtStrFrom, dtStrTo }
    },
    getListSearchParamsForTheMonth() {
      // 当月初日〜末日
      const dtFormat = Vue.filter('dtFormat')
      const dt = new Date()
      const y = dt.getFullYear()
      const m = dt.getMonth()
      const dtFrom = new Date(y, m, 1)
      const dtTo = new Date(y, m + 1, 0)

      const dtStrFrom = dtFormat(dtFrom, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(dtTo, 'yyyy/mm/dd')
      return { dtStrFrom, dtStrTo }
    },
    getListSearchOptionsForSaveStatus() {
      return [
        { key: true, val: '下書き' },
        { key: false, val: '作成済' },
      ]
    },
  }
}
