import userApi from '@/api/user'
import * as types from '../mutation-types'

// the definition of initial state is required.
const state = {
  id: -1,
  uid: '',
  name: '',
  role: '',
  has_role_admin: false,
  has_role_soukatsu: false,
  has_role_shunin: false,
  has_role_sagyou: false,
  is_admin: false,
  is_soukatsu: false,
  is_shunin: false,
  is_sagyou: false,
  env: '',
}

const getters = {
  isLoggedIn: state => state.id !== -1
}

const actions = {
  getMe({ commit, state, getters }) {
    if (getters.isLoggedIn) {
      return Promise.resolve(Object.assign({}, state))
    }
    return userApi.getMe()
      .then(({ data }) => {
        commit(types.SET_LOGIN_USER, { data })
        return Object.assign({}, state)
      })
  },
  login({ commit }, data) {
    return userApi.login(data)
      .then(res => {
        const token = res.headers['authorization'].split(' ')[1]
        commit(types.SET_LOGIN_USER, { data: res.data })
        return { access_token: token }
      })
  },
  logout({ commit }) {
    return userApi.logout()
      .then(() => {
        commit(types.CLEAR_LOGIN_USER)
      })
  }
}

const mutations = {
  [types.SET_LOGIN_USER](state, { data }) {
    Object.keys(data).forEach(k => {
      state[k] = data[k]
    })
  },
  [types.CLEAR_LOGIN_USER](state) {
    state.id = -1
    state.uid = ''
    state.name = ''
    state.role = ''
    state.has_role_admin = false
    state.has_role_soukatsu = false
    state.has_role_shunin = false
    state.has_role_sagyou = false
    state.is_admin = false
    state.is_soukatsu = false
    state.is_shunin = false
    state.is_sagyou = false
    state.env = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
