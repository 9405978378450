<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import constructionApi from '@/api/construction'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'
import textAreaMixin from '@/mixin/textAreaMixin'

export default {
  name: 'constructionDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      isNew: true,
      construction: {
        name: '',
        orderer_name: '',
        construction_side_witnesses: [''],
        office_id: '',
        construction_kind_type: '',
        construction_summary: '',
        use_start_date: '',
        use_end_date: '',
      },
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      offices: [],
      constructionKindTypes: [],
      errors: {},
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const msgs = Object.keys(this.errors).map(k => {
        return this.errors[k]
      }, [])
      return Array.from(new Set([].concat(...msgs)))
    },
  },
  mounted() {
    this.waitForUserAndMasters().then(() => {
      this.prepareMasters()
      if (this.$route.name === 'constructionNew') {
        this.isNew = true
        this.prepareNewConstruction_()
      } else {
        this.isNew = false
        this.getConstruction_()
      }
    })
  },
  mixins: [dateMixin, errorHandleMixin, validatorMixin, masterMixin, textAreaMixin],
  methods: {
    prepareMasters() {
      this.offices = this.filterOffice(window.master.offices)
      const lovs = window.master.lovs
      this.constructionKindTypes = lovs.construction_kind_type.vals_inuse
    },
    filterOffice(data) {
      if (this.user.is_shunin || this.user.is_soukatsu) {
        data = data.filter(e => e.id === this.user.office_id)
      }
      return data
    },
    convConstruction_(data) {
      data.use_start_date = Vue.filter('dtFormat')(data.use_start_date, 'yyyy/mm/dd')
      data.use_end_date = Vue.filter('dtFormat')(data.use_end_date, 'yyyy/mm/dd')

      data.office = this.offices.find(e => e.id === data.office_id)
      data.disp_construction_kind_type =
        this.constructionKindTypes.find(e => e.key === data.construction_kind_type)

      try {
        data.construction_side_witnesses = JSON.parse(data.construction_side_witnesses)
      } catch (e) {
        data.construction_side_witnesses = [data.construction_side_witnesses]
      }

      return data
    },
    prepareNewConstruction_() {
      const now = new Date()
      const construction = {
        name: '',
        orderer_name: '',
        construction_side_witnesses: [''],
        office_id: '',
        construction_kind_type: '',
        construction_summary: '',
        use_start_date: Vue.filter('dtFormat')(now, 'yyyy/mm/dd'),
        use_end_date: Vue.filter('dtFormat')(now, 'yyyy/mm/dd'),
      }
      this.construction = construction
    },
    getConstruction_() {
      const obj = {id: this.id}
      constructionApi.show(obj)
        .then(({ data }) => {
          this.construction = this.convConstruction_(data)
        })
    },
    addConstructionSideWitness() {
      if (this.construction.construction_side_witnesses.length > 99) { return }
      this.construction.construction_side_witnesses.push('')
    },
    removeConstructionSideWitness(idx) {
      this.construction.construction_side_witnesses.splice(idx, 1)
    },
    checkInputs() {
      this.errors = {}
      let val

      // 工事件名
      val = this.construction.name
      if (!val) {
        this.errors['name'] = ['工事件名は必須項目です']
      } else if (val.length > 255) {
        this.errors['name'] = ['工事件名は255文字以内で入力して下さい']
      }

      // 受注者
      val = this.construction.orderer_name
      if (!val) {
        this.errors['orderer_name'] = ['受注者名は必須項目です']
      } else if (val.length > 255) {
        this.errors['orderer_name'] = ['受注者名は255文字以内で入力して下さい']
      }

      // 工期開始日
      if (!this.isDateString(this.construction.use_start_date)) {
        this.errors['use_start_date'] = ['工期開始日は必須項目です']
      }

      // 工期終了日
      if (!this.isDateString(this.construction.use_end_date)) {
        this.errors['use_end_date'] = ['工期終了日は必須項目です']
      }

      // 施工側立会者
      this.construction.construction_side_witnesses =
        this.construction.construction_side_witnesses.filter(e => e)
      const uniqueConstructionSideWItnesses =
        Array.from(new Set(this.construction.construction_side_witnesses))
      val = this.construction.construction_side_witnesses
      if (val.length === 0) {
        this.construction.construction_side_witnesses = ['']
        this.errors['construction_side_witnesses'] = ['施工側立会者は必須項目です']
      } else if (val.length > uniqueConstructionSideWItnesses.length) {
        this.errors['construction_side_witnesses'] = ['施工側立会者が重複しています']
      } else if (val.some(e => e.length > 255)) {
        this.errors['construction_side_witnesses'] = ['施工側立会者は255文字以内で入力して下さい']
      }

      // 工事概要
      val = this.construction.construction_summary
      if (val && val.length > 255) {
        this.errors['construction_summary'] = ['工事概要は255文字以内で入力して下さい']
      }

      // 事務所名
      val = this.construction.office_id
      if (!val) {
        this.errors['office_id'] = ['事務所名は必須項目です']
      }
      return Object.keys(this.errors).length === 0
    },
    getReqObj() {
      const construction = JSON.parse(JSON.stringify(this.construction))

      // conv date
      construction.use_start_date = Vue.filter('dtFormat')(
        construction.use_start_date, 'yyyy-mm-dd')
      construction.use_end_date = Vue.filter('dtFormat')(
        construction.use_end_date, 'yyyy-mm-dd')
      // conv construction_side_witnesses
      construction.construction_side_witnesses =
        JSON.stringify(construction.construction_side_witnesses)

      const ret = { data: construction }
      if (construction.id) {
        ret.id = construction.id
      }
      return ret
    },
    createConstruction() {
      if (!this.checkInputs()) { return }

      const reqObj = this.getReqObj()
      constructionApi.create(reqObj)
        .then(({ data }) => {
          this.construction = this.convConstruction_(data)
          this.showCreateCompleteModal = true
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    saveConstruction() {
      if (!this.checkInputs()) { return }

      const reqObj = this.getReqObj()
      constructionApi.update(reqObj)
        .then(({ data }) => {
          this.construction = this.convConstruction_(data)
          this.showUpdateCompleteModal = true
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    closeCreateCompleteModal() {
      this.$router.replace({
        name: 'constructionDetail',
        params: { id: this.construction.id },
      })
      this.showCreateCompleteModal = false
      window.setTimeout(() => {
        this.isNew = false
        this.getConstruction_()
      }, 0)
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
