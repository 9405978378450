const props = {
  inspection_report: {
    inspector_office_id: { disp_name: '事務所名', type: 'select', option_name: 'offices', option_key: 'id', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    orderer_name: { disp_name: '受注者名', type: 'text', table: 'constructions' },
    shutokou_department_name: { disp_name: '首都高速道路 部署名', type: 'text', table: 'inspection_reports' },
    shutokou_office_name: { disp_name: '首都高速道路 事務所名', type: 'text', table: 'inspection_reports' },
    construction_id: { disp_name: '工事件名', type: 'select', option_name: 'constructions', option_key: 'id', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    name: { disp_name: '検査名称/確認名称', type: 'text', table: 'inspection_reports' },
    construction_kind_id: { disp_name: '工種', type: 'select', option_name: 'construction_kinds', option_key: 'key', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    climate_id: { disp_name: '天気', type: 'select', option_name: 'climates', option_key: 'key', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    inspection_date: { disp_name: '検査年月日/確認年月日', type: 'date', table: 'inspection_reports' },
    inspection_time: { disp_name: '検査開始時刻/確認開始時刻', type: 'time', table: 'inspection_reports' },
    inspection_time_end: { disp_name: '検査終了時刻/確認終了時刻', type: 'time', table: 'inspection_reports' },
    inspection_location: { disp_name: '検査場所/確認場所', type: 'text', table: 'inspection_reports' },
    shutokou_inspector: { disp_name: '首都高速道路立会者', type: 'text', table: 'inspection_reports' },
    inspector_id: { disp_name: '立会者', type: 'select', option_name: 'inspectors', option_key: 'id', option_disp: 'name', multiple: true, table: 'inspection_report_inspector_rels', foreign_key: 'inspection_report_id' },
    construction_side_witnesses: { disp_name: '施工側立会者', type: 'text', table: 'inspection_reports' },
    inspection_summary: { disp_name: '検査概要/現場確認概要', type: 'text', table: 'inspection_reports' },
    inspection_content: { disp_name: '検査確認項目/現場確認項目', type: 'text', table: 'inspection_reports' },
    inspection_result_content: { disp_name: '検査結果', type: 'text', table: 'inspection_reports' },
    inspection_result: { disp_name: '検査結果合否', type: 'select', option_name: 'inspection_results', option_key: 'sentence', option_disp: 'sentence', multiple: true, table: 'inspection_reports' },
    inspection_indicate: { disp_name: '現場指摘事項', type: 'text', table: 'inspection_reports' },
    inspection_indicate2: { disp_name: '現場指導事項', type: 'text', table: 'inspection_reports' },
    quality_management_remarks: { disp_name: '品質関係指摘事項', type: 'text', table: 'inspection_reports' },
    safety_management_remarks: { disp_name: '安全関係指摘事項', type: 'text', table: 'inspection_reports' },
    other1: { disp_name: 'その他報告事項', type: 'text', table: 'inspection_reports' },
    attached_documents: { disp_name: '別添資料', type: 'text', table: 'inspection_reports' },
    is_draft: { disp_name: '作成状況', type: 'select', option_name: 'save_statuses', option_key: 'key', option_disp: 'val', multiple: true, table: 'inspection_reports' },
  },
  correction_report: {
    serial_number: { disp_name: '指摘事項報告書番号', type: 'number', table: 'correction_reports' },
    construction_kind_id: { disp_name: '工種', type: 'select', option_name: 'construction_kinds', option_key: 'key', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    correction_point: { disp_name: '指摘事項', type: 'text', table: 'correction_reports' },
    created_at: { disp_name: '作成日', type: 'date', table: 'correction_reports' },
    construction_summary: { disp_name: '工事概要', type: 'text', table: 'correction_reports' },
    construction_id: { disp_name: '工事件名', type: 'select', option_name: 'constructions', option_key: 'id', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    orderer_name: { disp_name: '受注者名', type: 'text', table: 'constructions' },
    correction_content: { disp_name: '是正内容', type: 'text', table: 'correction_reports' },
    before_correction_content: { disp_name: '是正前', type: 'text', table: 'correction_reports' },
    after_correction_content: { disp_name: '是正後', type: 'text', table: 'correction_reports' },
    occurrence_cause: { disp_name: '発生要因', type: 'text', table: 'correction_reports' },
    repair_plan: { disp_name: '補修方法', type: 'text', table: 'correction_reports' },
    improvement_plan: { disp_name: '改善方法', type: 'text', table: 'correction_reports' },
    inspector_office_id: { disp_name: '事務所名', type: 'select', option_name: 'offices', option_key: 'id', option_disp: 'name', multiple: true, table: 'inspection_reports' },
    name: { disp_name: '検査名称/確認名称', type: 'text', table: 'inspection_reports' },
    inspection_date: { disp_name: '検査年月日/確認年月日', type: 'date', table: 'inspection_reports' },
    is_draft: { disp_name: '作成状況', type: 'select', option_name: 'save_statuses', option_key: 'key', option_disp: 'val', multiple: true, table: 'correction_reports' },
  },
}

export default {
  methods: {
    getAdvancedSearchFormInfo(key) {
      return props[key]
    },
  }
}
