<template>
  <div class="table-input">

    <button class="tbl-add-btn btn btn-lg btn-default"
        @click.self="showTablePicker = !showTablePicker"
        @blur="showTablePicker = false">
      <span @click="showTablePicker = !showTablePicker">表追加</span>
      <div class="tbl-picker" v-if="showTablePicker">
        <div class="row-picker"
            v-for="row in tablePickerMaxRows" :key="row"
            v-if="row >= tableMinRows">
          <div class="col-picker"
              v-for="col in tablePickerMaxCols" :key="col"
              v-if="col >= tableMinCols"
              @click.stop="addTable(row, col)">
            {{row}}x{{col}}
          </div>
        </div>
      </div>
    </button>

    <div class="table-editor-box" v-if="tables.length > 0">
      <div class="editor-container"
          v-for="(table, index) in tables" :key="index"
          v-if="table.contents">
        <div class="toolbar">
          <div class="tbl-delete-btn btn btn-sm btn-danger"
              @click="tableToDelete = table">
            <i class="fa fa-trash mr-3"></i>表削除
          </div>
        </div>
        <div class="table-wrap">
          <input class="tbl-title form-control fs-14 bg-blue"
            type="text" placeholder="表題" maxLength="36"
            v-model="table.title">
          <div class="col-btn-wrap">
            <div class="col-delete-btn pointer"
                v-if="table.numCols > tableMinCols"
                @click="confirmColumnDelete(table)">
              <i class="fa fa-times"></i>
            </div>
            <div class="col-add-btn pointer"
                v-if="table.numCols < tableMaxCols"
                @click="addColumn(table)">
              <i class="fa fa-arrow-right"></i>
            </div>
          </div>
          <div class="tbl-contents form-control fs-14 bg-blue"
            contenteditable
            v-html="table.contents"
            @drop.prevent
            @paste="onPaste($event)"
            @input="onInput(index, $event)"
            @blur="updateInput(table, $event)">
          </div>
          <div class="row-btn-wrap">
            <div class="row-delete-btn pointer"
                v-if="table.numRows > tableMinRows"
                @click="confirmRowDelete(table)">
              <i class="fa fa-times"></i>
            </div>
            <div class="row-add-btn pointer"
                v-if="table.numRows < tableMaxRows"
                @click="addRow(table)">
              <i class="fa fa-arrow-down"></i>
            </div>
          </div>
        </div>
        <div class="header-btn-wrap">
          <div class="btn-group">
            <button class="btn btn-sm" :class="{
              'btn-white': !isHeaderModeNone(table),
              'btn-info': isHeaderModeNone(table),
            }" @click="unsetHeader(table)">
              強調なし
            </button>
            <button class="btn btn-sm" :class="{
              'btn-white': !isHeaderModeRow(table),
              'btn-info': isHeaderModeRow(table),
            }" @click="setRowHeader(table)">
              1行目太字
            </button>
            <button class="btn btn-sm" :class="{
              'btn-white': !isHeaderModeColumn(table),
              'btn-info': isHeaderModeColumn(table),
            }" @click="setColumnHeader(table)">
              1列目太字
            </button>
          </div>
        </div>
      </div>
    </div>

    <modal
        v-if="showRowDeleteConfirmModal"
        title="削除確認"
        @close="deleteRow()"
        @dismiss="showRowDeleteConfirmModal = false">
      <div slot="body">
        <div class="wrap1">
          <p class="p1">{{tableToRowDelete.numRows}}行目を削除します。よろしいですか？</p>
        </div>
      </div>
    </modal>

    <modal
        v-if="showColumnDeleteConfirmModal"
        title="削除確認"
        @close="deleteColumn()"
        @dismiss="showColumnDeleteConfirmModal = false">
      <div slot="body">
        <div class="wrap1">
          <p class="p1">{{tableToColumnDelete.numCols}}列目を削除します。よろしいですか？</p>
        </div>
      </div>
    </modal>

    <modal
        v-if="tableToDelete"
        title="削除確認"
        @close="deleteTable()"
        @dismiss="tableToDelete = null">
      <div slot="body">
        <div class="wrap1">
          <p class="p1">
            表<span v-if="tableToDelete.title">：{{tableToDelete.title}} </span>を削除します。よろしいですか？
          </p>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
export default {
  name: 'table-input',
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      tablePickerMaxRows: 5,
      tablePickerMaxCols: 5,
      tableMinRows: 2,
      tableMinCols: 2,
      tableMaxRows: 10,
      tableMaxCols: 10,

      tables: [],

      tableToDelete: null,
      tableToRowDelete: null,
      tableToColumnDelete: null,

      showTablePicker: false,
      showRowDeleteConfirmModal: false,
      showColumnDeleteConfirmModal: false,
    }
  },
  mounted() {
    this.tables = this.value
  },
  methods: {
    emitInput() {
      this.$emit('input', this.tables)
    },
    styleCell(cellNode) {
      cellNode.style['border'] = '1px solid black'
      cellNode.style['padding'] = '4px'
    },
    addTable(rows, cols) {
      const tableNode = document.createElement('table')
      const lastIdx = this.tables.length - 1
      const tableId = lastIdx < 0 ? 1 : this.tables[lastIdx].id + 1
      tableNode.id = `table-${tableId}`
      tableNode.className = 'table'
      tableNode.style['margin-bottom'] = '0px'
      tableNode.style['white-space'] = 'normal'
      for (let rowIdx = 0; rowIdx < rows; rowIdx++) {
        const rowNode = tableNode.insertRow(-1)
        for (let colIdx = 0; colIdx < cols; colIdx++) {
          const cellNode = rowNode.insertCell(-1)
          this.styleCell(cellNode)
        }
      }
      const tableInfo = {
        id: tableId,
        title: '',
        contents: tableNode.outerHTML,
        numRows: rows,
        numCols: cols,
        numDispRows: rows,
        displayHeight: 36 * rows, // px
        headerMode: 'none',
      }
      this.tables.push(tableInfo)
      this.emitInput()
      this.showTablePicker = false
    },
    getNumDispRows(table) {
      // とりあえず文字数は計算しない
      const tableNode = this.getTableElementById(table.id)
      let numDispRows = 0
      for (const row of tableNode.rows) {
        let estimatedNumDispRows = 1
        for (const cell of row.cells) {
          const numRows = cell.innerHTML.replace(/<br>$/, '').split('<br>').length
          estimatedNumDispRows = Math.max(estimatedNumDispRows, numRows)
        }
        numDispRows += estimatedNumDispRows
      }
      return numDispRows
    },
    onPaste(e) {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      document.execCommand('insertHTML', false, text)
    },
    onInput(index, e) {
      const tables = JSON.parse(JSON.stringify(this.value))
      const table = tables[index]
      const divNode = e.target
      const tableNode = divNode.querySelector('table')
      const numRows = divNode.querySelectorAll('tr').length
      if (divNode.childNodes.length > 1) {
        e.stopPropagation()
        divNode.innerHTML = tableNode.outerHTML
        return
      }
      if (!tableNode || numRows !== table.numRows) {
        e.stopPropagation()
        divNode.innerHTML = table.contents
        return
      }
      table.contents = divNode.innerHTML
      this.$emit('input', tables)
    },
    updateInput(table, e) {
      if (table.contents === e.target.innerHTML) { return }
      table.contents = e.target.innerHTML
      table.numDispRows = this.getNumDispRows(table)
      table.displayHeight = e.target.offsetHeight
      this.emitInput()
    },
    updateTableInfo(table, tableNode, headerMode = null) {
      table.contents = tableNode.outerHTML
      table.numCols = tableNode.rows.length ? tableNode.rows[0].cells.length : 0
      table.numRows = tableNode.rows.length
      table.numDispRows = this.getNumDispRows(table)
      table.displayHeight = tableNode.offsetHeight
      if (headerMode) {
        table.headerMode = headerMode
      }
      this.emitInput()
    },
    getTableElementById(id) {
      return document.getElementById(`table-${id}`)
    },
    isFirstChild(childNode) {
      return childNode.parentNode.firstChild === childNode
    },
    replaceCell(oldCell, newEl) {
      const newCell = document.createElement(newEl)
      newCell.innerHTML = oldCell.innerHTML
      this.styleCell(newCell)
      oldCell.outerHTML = newCell.outerHTML
    },
    isHeaderModeNone(table) {
      return table.headerMode === 'none'
    },
    isHeaderModeRow(table) {
      return table.headerMode === 'row'
    },
    isHeaderModeColumn(table) {
      return table.headerMode === 'column'
    },
    unsetHeader(table) {
      const tableNode = this.getTableElementById(table.id)
      for (const row of tableNode.rows) {
        if (this.isHeaderModeRow(table)) {
          for (const cell of row.childNodes) {
            this.replaceCell(cell, 'td')
          }
          break
        }
        if (this.isHeaderModeColumn(table)) {
          this.replaceCell(row.firstChild, 'td')
        }
      }
      this.updateTableInfo(table, tableNode, 'none')
    },
    setRowHeader(table) {
      const tableNode = this.getTableElementById(table.id)
      for (const row of tableNode.rows) {
        if (this.isFirstChild(row)) {
          for (const cell of row.cells) {
            if (cell.nodeName === 'TH') { continue }
            this.replaceCell(cell, 'th')
          }
          continue
        }
        if (this.isHeaderModeColumn(table)) {
          this.replaceCell(row.firstChild, 'td')
        }
      }
      this.updateTableInfo(table, tableNode, 'row')
    },
    setColumnHeader(table) {
      const tableNode = this.getTableElementById(table.id)
      for (const row of tableNode.rows) {
        if (this.isHeaderModeRow(table) && this.isFirstChild(row)) {
          for (const cell of row.childNodes) {
            if (this.isFirstChild(cell)) { continue }
            this.replaceCell(cell, 'td')
          }
        }
        const firstCell = row.firstChild
        if (firstCell.nodeName === 'TH') { continue }
        this.replaceCell(firstCell, 'th')
      }
      this.updateTableInfo(table, tableNode, 'column')
    },
    addRow(table) {
      const tableNode = this.getTableElementById(table.id)
      const rowNode = tableNode.insertRow(-1)
      let numCols = table.numCols || 1
      for (numCols; numCols--;) {
        const cellNode = rowNode.insertCell(-1)
        this.styleCell(cellNode)
      }
      this.updateTableInfo(table, tableNode)
      if (this.isHeaderModeRow(table)) { this.setRowHeader(table) }
      if (this.isHeaderModeColumn(table)) { this.setColumnHeader(table) }
    },
    addColumn(table) {
      if (!table.numRows) {
        this.addRow(table)
        return
      }
      const tableNode = this.getTableElementById(table.id)
      for (const rowNode of tableNode.rows) {
        const cellNode = rowNode.insertCell(-1)
        this.styleCell(cellNode)
      }
      this.updateTableInfo(table, tableNode)
      if (this.isHeaderModeRow(table)) { this.setRowHeader(table) }
      if (this.isHeaderModeColumn(table)) { this.setColumnHeader(table) }
    },
    confirmRowDelete(table) {
      if (table.numRows <= this.tableMinRows) { return }
      this.tableToRowDelete = table
      this.showRowDeleteConfirmModal = true
    },
    confirmColumnDelete(table) {
      if (table.numCols <= this.tableMinCols) { return }
      this.tableToColumnDelete = table
      this.showColumnDeleteConfirmModal = true
    },
    deleteRow() {
      const tableNode = this.getTableElementById(this.tableToRowDelete.id)
      tableNode.deleteRow(-1)
      this.updateTableInfo(this.tableToRowDelete, tableNode)
      this.tableToRowDelete = null
      this.showRowDeleteConfirmModal = false
    },
    deleteColumn() {
      const tableNode = this.getTableElementById(this.tableToColumnDelete.id)
      if (tableNode.rows[0].cells.length > 1) {
        for (const rowNode of tableNode.rows) {
          rowNode.deleteCell(-1)
        }
      } else {
        const tbodyNode = tableNode.firstChild
        while (tbodyNode.lastChild) {
          tbodyNode.removeChild(tbodyNode.lastChild)
        }
      }
      this.updateTableInfo(this.tableToColumnDelete, tableNode)
      this.tableToColumnDelete = null
      this.showColumnDeleteConfirmModal = false
    },
    deleteTable() {
      const index = this.tables.findIndex(table => {
        return table.id === this.tableToDelete.id
      })
      this.tables.splice(index, 1)
      this.emitInput()
      this.tableToDelete = null
    },
  },
}
</script>

<style lang="scss" scoped>
$border-color: #e7eaec;
.table-input {
  width: 100%;
}
.tbl-add-btn {
  position: relative;
}
.tbl-picker {
  position: absolute;
  top: 45px;
  left: 0px;
  z-index: 1000;
  display: grid;
  grid-template-rows: repeat(4, 30px);
  grid-gap: 4px;
  padding: 4px;
  border: 1px solid $border-color;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  background-color: #ffffff;
  font-size: 14px;
}
.row-picker {
  display: grid;
  grid-template-columns: repeat(4, 30px);
  grid-gap: 4px;
}
.col-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-color;
  &:hover {
    background-color: $border-color;
  }
}
.editor-container {
  position: relative;
  margin: 15px 0px;
  padding: 10px 17px;
  background-color: #e9f1fc57;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}
.toolbar {
  margin-bottom: 5px;
  text-align: right;
}
.table-wrap {
  .form-control {
    &.tbl-title {
      padding: 4px;
    }
    &.tbl-contents {
      height: 100%;
      padding: 0px;
    }
  }
}
.pointer {
  cursor: pointer;
}
.col-btn-wrap {
  display: flex;
  position: relative;
  right: -13px;
  justify-content: flex-end;
  .col-delete-btn {
    margin-right: 5px;
  }
}
.row-btn-wrap {
  position: absolute;
  left: 3px;
  bottom: 33px;
  .row-add-btn {
    margin: -2px -1px;
  }
}
.header-btn-wrap {
  margin-top: 10px;
  text-align: center;
}
.modal-container {
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #676a6c;
}
</style>
