import axios from 'axios'
import * as utils from './apiUtils'

export default {
  search(obj) {
    return axios.get(utils.getApiUrl('/inspection_reports/search'), {
      params: obj || {}
    })
  },
  get_by_ids(ids) {
    return axios.post(utils.getApiUrl(`/inspection_reports/ids`), ids)
  },
  create({ data }) {
    return axios.post(utils.getApiUrl(`/inspection_reports`), data)
  },
  show({ id, photo_type }) {
    return axios.get(utils.getApiUrl(`/inspection_reports/${id}`), {
      params: { photo_type: photo_type } || {}
    })
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/inspection_reports/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/inspection_reports/${id}`))
  },
  downloadCsv(ids) {
    return axios.post('/inspection_reports/download.csv', ids, {
      responseType: 'blob'
    })
  },
  downloadIdentityManagement(ids) {
    return axios.post('/inspection_reports/identity_management.csv', ids, {
      responseType: 'blob'
    })
  },
  addPhoto({ id, photo }) {
    return axios.post(utils.getApiUrl(`/inspection_reports/${id}/report_photos`), photo)
  },
  bulkUpdatePhotos({ id, photos }) {
    return axios.patch(utils.getApiUrl(`/inspection_reports/${id}/report_photos`), { photos: photos })
  },
  deletePhoto({ id, reportPhotoId }) {
    return axios.delete(utils.getApiUrl(`/inspection_reports/${id}/report_photos/${reportPhotoId}`))
  },
  addDocument({ id, formData }) {
    return axios.post(utils.getApiUrl(`/inspection_reports/${id}/inspection_report_documents`), formData)
  },
  updateDocument({ id, document }) {
    return axios.put(utils.getApiUrl(`/inspection_reports/${id}/inspection_report_documents/${document.id}`), document)
  },
  deleteDocument({ id, document }) {
    return axios.delete(utils.getApiUrl(`/inspection_reports/${id}/inspection_report_documents/${document.id}`))
  },
}
