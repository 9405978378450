import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/inside_work_contents'), {
      params: obj || {}
    })
  },
  create(data) {
    return axios.post(utils.getApiUrl(`/inside_work_contents`), data)
  },
  show(id) {
    return axios.get(utils.getApiUrl(`/inside_work_contents/${id}`))
  },
  update(id, data) {
    return axios.put(utils.getApiUrl(`/inside_work_contents/${id}`), data)
  },
  delete(id) {
    return axios.delete(utils.getApiUrl(`/inside_work_contents/${id}`))
  },
}
