export default {
  methods: {
    // lovに応じた表示列名などの列情報を取得する
    getLovDispColInfo(lovName, lov) {
      const uiSpec = this.getMasterDispEditSpec()[lovName]
      const dispSpec = uiSpec.disp
      const editSpec = uiSpec.edit

      // lovの表示列名
      const props = Object.keys(lov.prop_map).map(k => {
        return {
          key: k,
          disp_name: lov.prop_map[k].disp,
          order: lov.prop_map[k].order,
          disp_spec: dispSpec[k],
          edit_spec: editSpec[k] || {},
          // DB上のカラム名
          orig_prop: `val${lov.prop_map[k].order}`,
        }
      }).sort((a, b) => {
        const v1 = a.order
        const v2 = b.order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
      this.insertDispCols_(lovName, props, { dispSpec, editSpec })
      this.setEditParams(props)
      return props
    },
    insertDispCols_(lovName, arr, { dispSpec, editSpec }) {
      // 表示用に使いたいやつがあれば足す.
      // keyと表示名は常に足す.
      arr.splice(0, 0, {
        key: 'key',
        disp_name: 'ID',
        disp_spec: '',
        edit_spec: {},
        // DB上のカラム名
        orig_prop: 'key',
      })
      arr.splice(1, 0, {
        key: 'disp_order',
        disp_name: '表示順',
        disp_spec: '',
        edit_spec: { type: 'text' },
        // DB上のカラム名
        orig_prop: 'disp_order',
      })
    },
    setEditParams(arr) {
      // 編集用に必要な処理があれば行う
      arr.forEach(e => {
        if (e.edit_spec.type === 'select') {
          e.edit_spec.option_key = e.edit_spec.option_key || 'key'
          e.edit_spec.option_disp = e.edit_spec.option_disp || 'name'
        }
      })
    },
    convLovEntry(lovName, entry) {
      // 元のやつを汚さないよう、コピー.
      const ret = Object.assign({}, entry)
      // 日付
      ret.start_use = new Date(ret.start_use)
      ret.end_use = new Date(ret.end_use)
      // 編集モードかどうか
      ret.isEditMode = false
      return ret
    },
    getMasterDispEditSpec() {
      return {
        climate: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:天候
        },
        construction_kind_type: {
          disp: {},
          edit: {
            name: { type: 'text' },
          },
          // name:工種分類
        },
        construction_kind: {
          disp: {
            type: 'construction_kind_type.name',
          },
          edit: {
            name: { type: 'text' },
            type: { type: 'select', lov_name: 'construction_kind_type' },
          },
          // name:工種
        },
        inspection_result: {
          disp: {},
          edit: {
            sentence: { type: 'text' },
          },
          // name:検査結果合否
        },
      }
    },
  }
}
