import axios from 'axios'
import * as utils from './apiUtils'

export default {
  search(obj) {
    return axios.get(utils.getApiUrl('/photos/search'), {
      params: obj || {}
    })
  },
  create({ formData }) {
    return axios.post(utils.getApiUrl(`/photos`), formData)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/photos/${id}`))
  },
  bulkDelete(ids) {
    return axios.delete(utils.getApiUrl('/photos'), {
      params: ids
    })
  },
}
