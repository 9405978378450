import axios from 'axios'
import * as utils from './apiUtils'

export default {
  search(obj) {
    return axios.get(utils.getApiUrl('/correction_reports/search'), {
      params: obj || {}
    })
  },
  get_by_ids(ids) {
    return axios.post(utils.getApiUrl(`/correction_reports/ids`), ids)
  },
  create({ inspection_report_id, data }) {
    return axios.post(utils.getApiUrl(`/inspection_reports/${inspection_report_id}/correction_reports`), data)
  },
  show({ inspection_report_id, id }) {
    return axios.get(utils.getApiUrl(`/inspection_reports/${inspection_report_id}/correction_reports/${id}`))
  },
  update({ inspection_report_id, id, data }) {
    return axios.put(utils.getApiUrl(`/inspection_reports/${inspection_report_id}/correction_reports/${id}`), data)
  },
  delete({ inspection_report_id, id }) {
    return axios.delete(utils.getApiUrl(`/inspection_reports/${inspection_report_id}/correction_reports/${id}`))
  },
  downloadCsv(ids) {
    return axios.post('/correction_reports/download.csv', ids, {
      responseType: 'blob'
    })
  },
  addPhoto({ id, photo }) {
    return axios.post(utils.getApiUrl(`/correction_reports/${id}/report_photos`), photo)
  },
  deletePhoto({ id, reportPhotoId }) {
    return axios.delete(utils.getApiUrl(`/correction_reports/${id}/report_photos/${reportPhotoId}`))
  },
}
