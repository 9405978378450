<template>
  <div class="enlargeable-image">
    <div class="default-image-wrap">
      <http-img class="default-image" :src="src"
        @click="showEnlargedImage = true">
      </http-img>
    </div>
    <div class="modal-container" v-if="showEnlargedImage">
      <div class="enlarged-image-container">
        <div class="enlarged-image-wrap">
          <http-img class="enlarged-image" :src="src"></http-img>
          <div class="bottom-bar">
            <button class="btn btn-lg btn-default"
                @click="showEnlargedImage = false">
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'enlargeable-image',
  props: {
    src: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      showEnlargedImage: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.enlargeable-image {
  width: 100%;
  height: 100%;
}
.default-image-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  .default-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    &:hover {
      opacity: 0.7;
      cursor: zoom-in;
    }
  }
}
.modal-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  justify-content: center;
  background: rgba(128,128,128,0.5);
}
.enlarged-image-container {
  display: flex;
  margin: 30px;
  justify-content: center;
}
.enlarged-image-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  .enlarged-image {
    max-width: 100%;
    max-height: 90%;
    border: 10px solid #ffffff;
    background-color: #ffffff;
    object-fit: contain;
  }
  .bottom-bar {
    display: flex;
    width: 100%;
    padding: 0 5px 10px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
}
</style>
