<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import inspectionReportApi from '@/api/inspectionReport'
import userApi from '@/api/user'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import textAreaMixin from '@/mixin/textAreaMixin'
import masterMixin from '@/mixin/masterMixin'
import HeaderBar from '@/components/inspectionReport/InspectionReportDetail/HeaderBar'
import Draggable from 'vuedraggable'
import TableInput from '@/components/lib/TableInput'

export default {
  name: 'inspectionReportDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      isNew: true,
      isEdited: false,
      nextPath: '',
      report: null,
      showInspectorModal: false,
      showConstructionSideWitnessModal: false,
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      showConfirmBeforeLeaveModal: false,
      climates: [],
      constructions: [],
      orig_construction_kinds: [],
      construction_kinds: [],
      construction_kind_types: [],
      inspection_results: [],
      officeUsers: [],

      isToolbarOpen: false,
      errors: {},

      isSaveModeDraft: false,

      tmpConstructionSideWitness: '',
      tmpConstructionSideWitnessCands: [],
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    inspectorCands() {
      return this.officeUsers.filter(user => {
        return this.report.inspectors.findIndex(inspector => inspector.id === user.id) === -1
      })
    },
    constructionSideWitnessCands() {
      const cands1 = this.report.construction.construction_side_witnesses
      const cands2 = this.tmpConstructionSideWitnessCands
      const cands = cands1.concat(cands2)
      return cands.filter(name => {
        return this.report.construction_side_witnesses.indexOf(name) === -1
      })
    },
    isReportModeTachiaiKensa() {
      return !this.report.meta_info.reportMode ||
        this.report.meta_info.reportMode === 'tachiai'
    },
    isReportModeGenbaKakunin() {
      return this.report.meta_info.reportMode === 'genba'
    },
    isReportModeOther() {
      return this.report.meta_info.reportMode === 'other'
    },
    isShowShutokouInspector: {
      get() {
        return this.report.meta_info.shutokou_inspector.is_visible
      },
      set(val) {
        this.report.meta_info.shutokou_inspector.is_visible = val
      }
    },
    isShowInspectionIndicate: {
      get() {
        return this.report.meta_info.inspection_indicate.is_visible
      },
      set(val) {
        this.report.meta_info.inspection_indicate.is_visible = val
      }
    },
    isShowInspectionIndicate2: {
      get() {
        return this.report.meta_info.inspection_indicate2.is_visible
      },
      set(val) {
        this.report.meta_info.inspection_indicate2.is_visible = val
      }
    },
    isShowOther1: {
      get() {
        return this.report.meta_info.other1.is_visible
      },
      set(val) {
        this.report.meta_info.other1.is_visible = val
      }
    },
    isTimeDispModeFrom() {
      return this.report.meta_info.timeDispMode === 'from'
    },
    isTimeDispModeFromTo() {
      return this.report.meta_info.timeDispMode === 'from-to'
    },
    isTimeDispModeYakan() {
      return this.report.meta_info.timeDispMode === 'yakan'
    },
    isTimeDispModeNone() {
      return this.report.meta_info.timeDispMode === 'none'
    },
    hasInspectionContentPhotos() {
      return this.report.inspection_content_photos.length > 0
    },
    hasInspectionResultContentPhotos() {
      return this.report.inspection_result_content_photos.length > 0
    },
    validConstructions() {
      // 検査日現在有効な工事のみ表示
      return this.constructions.filter(e => {
        const dateStrToDate = Vue.filter('ensureDate')
        const inspectionDate = dateStrToDate(this.report.inspection_date)
        const useStartDate = dateStrToDate(e.use_start_date)
        const useEndDate = dateStrToDate(e.use_end_date)
        return useStartDate <= inspectionDate && useEndDate >= inspectionDate
      })
    },
    isValidConstructionSideWitness() {
      if (this.report.construction_side_witnesses.length > 99) { return false }

      const tmpConstructionSideWitness = this.tmpConstructionSideWitness
      const isTmpConstructionSideWitnessOk = tmpConstructionSideWitness &&
        tmpConstructionSideWitness.length <= 255
      const constructionSideWitnesses1 = this.report.construction.construction_side_witnesses
      const constructionSideWitnesses2 = this.tmpConstructionSideWitnessCands
      const constructionSideWitnesses = constructionSideWitnesses1.concat(constructionSideWitnesses2)
      const isTmpConstructionSideWitnessDuplicated =
        constructionSideWitnesses.find(name => name === tmpConstructionSideWitness)

      if (
        isTmpConstructionSideWitnessOk &&
        !isTmpConstructionSideWitnessDuplicated
      ) { return true }

      return false
    },
  },
  mounted() {
    const promises = [
      this.waitForUserAndMasters(),
      this.getMyOfficeUser()
    ]

    Promise.all(promises).then(() => {
      this.prepareMasters()
      if (this.$route.name === 'inspectionReportNew') {
        this.isNew = true
        this.prepareNewReport_()
      } else if (this.$route.name === 'inspectionReportDetailCopy') {
        this.isNew = true
        this.getCopiedNewReport_()
      } else {
        this.isNew = false
        this.getReport_()
      }
    })
    this.setListeners()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next()
      return
    }
    this.nextPath = to.path
    this.showConfirmBeforeLeaveModal = true
    next(false)
  },
  beforeDestroy() {
    this.removeListeners()
  },
  mixins: [dateMixin, errorHandleMixin, validatorMixin, textAreaMixin, masterMixin],
  methods: {
    setListeners() {
      window.addEventListener('beforeunload', this.confirmBeforeUnload, false)
    },
    removeListeners() {
      window.removeEventListener('beforeunload', this.confirmBeforeUnload, false)
    },
    confirmBeforeUnload(e) {
      if (this.isEdited) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    convConstructions(data) {
      if (this.user.is_shunin || this.user.is_soukatsu) {
        return data.filter(e => {
          return e.office_id === this.user.office_id
        })
      }
      return data
    },
    prepareMasters() {
      const lovs = window.master.lovs
      this.constructions = this.convConstructions(window.master.constructions)
      this.orig_construction_kinds = lovs.construction_kind.vals_inuse
      this.construction_kinds = this.orig_construction_kinds
      this.construction_kind_types = lovs.construction_kind_type.vals_inuse
      this.inspection_results = lovs.inspection_result.vals_inuse
      this.climates = lovs.climate.vals_inuse
    },
    getMyOfficeUser() {
      return userApi.getMyOfficeUser()
        .then(({ data }) => {
          this.officeUsers = data.filter(user => {
            const today = new Date(new Date().setHours(0, 0, 0, 0))
            const dateStrToDate = Vue.filter('ensureDate')
            const useStartDate = dateStrToDate(user.use_start_date)
            const useEndDate = dateStrToDate(user.use_end_date)
            return useStartDate <= today && today <= useEndDate
          })
        })
    },
    prepareNewReport_() {
      const now = new Date()
      const formatNow = (format) => {
        return Vue.filter('dtFormat')(now, format)
      }
      const report = {
        id: -1,
        dt: this.dt,
        timeslot: this.timeslot,
        shutokou_department_name: this.user.office.shutokou_department_name,
        shutokou_office_name: this.user.office.shutokou_office_name,
        inspector_office: {name: this.user.office_name},
        construction: {orderer_name: ''},
        construction_id: '',
        orderer_name: '',
        name: '',
        construction_kind_id: '',
        inspection_date: formatNow('yyyy/mm/dd'),
        inspection_time: parseInt(formatNow('HHMM')),
        inspection_time_h: formatNow('HH'),
        inspection_time_m: formatNow('MM'),
        inspection_time_end: parseInt(formatNow('HHMM')),
        inspection_time_end_h: formatNow('HH'),
        inspection_time_end_m: formatNow('MM'),
        climate_id: '',
        inspection_location: '',
        shutokou_inspector: '',
        inspectors: [this.user],
        construction_side_witnesses: [],
        inspection_summary: '',
        inspection_content: '',
        inspection_result_content: '',
        inspection_result: '',
        inspection_indicate: '',
        inspection_indicate2: '',
        quality_management_remarks: '',
        safety_management_remarks: '',
        other1: '',
        attached_documents: '',
        inspection_content_photos: [],
        inspection_result_content_photos: [],
        photos: [],
        documents: [],
        is_draft: false,
      }
      this.ensureMetaInfoStructure_(report)
      this.report = report
    },
    convReport_(data) {
      if (data.inspection_time !== null) {
        const strInspectionTime = '000' + data.inspection_time.toString()
        data.inspection_time_h = strInspectionTime.slice(-4, -2)
        data.inspection_time_m = strInspectionTime.slice(-2)
      }
      if (data.inspection_time_end !== null) {
        const strInspectionTimeEnd = '000' + data.inspection_time_end.toString()
        data.inspection_time_end_h = strInspectionTimeEnd.slice(-4, -2)
        data.inspection_time_end_m = strInspectionTimeEnd.slice(-2)
      }
      if (data.inspection_time !== null && data.inspection_time_end === null) {
        data.inspection_time_end_h = data.inspection_time_h
        data.inspection_time_end_m = data.inspection_time_m
      }
      this.onTimeInput(data)
      this.onTimeEndInput(data)

      data.construction = Object.assign({}, this.constructions.find(e => {
        return e.id === data.construction_id
      })) || {}

      data = this.parseJson_(data)

      // 実際に選択された工種から工種分類を取得
      const constructionKindMst = this.orig_construction_kinds.find(e => {
        return e.key === data.construction_kind_id
      })
      if (constructionKindMst) {
        data.tmp_construction_kind_type = constructionKindMst.type
      }
      // 工種絞り込み
      this.filterConstructionKind(data)

      this.tmpConstructionSideWitnessCands = data.construction_side_witnesses.filter(name => {
        return data.construction.construction_side_witnesses.indexOf(name) === -1
      })

      return data
    },
    parseJsonConstruction_(data) {
      try {
        // 配列に変換
        data.construction.construction_side_witnesses =
          JSON.parse(data.construction.construction_side_witnesses)
      } catch (e) {
        // なにか入ってるかもしれないのでとりあえずそのまま配列に入れておく
        data.construction.construction_side_witnesses =
          [data.construction.construction_side_witnesses]
      }
      try {
        // 配列に変換
        data.construction_side_witnesses =
          JSON.parse(data.construction_side_witnesses)
      } catch (e) {
        // デフォルトは未選択
        data.construction_side_witnesses = []
      }
      return data
    },
    parseJsonMeta_(data) {
      try {
        data.meta_info = JSON.parse(data.meta_info)
      } catch (e) {
        // console.error(e)
      }
      return data
    },
    ensureMetaInfoStructure_(report) {
      if (!report.meta_info) { report.meta_info = {} }
      if (!report.meta_info.inspection_result_content) {
        report.meta_info.inspection_result_content = {}
      }
      if (!report.meta_info.inspection_result_content.html_quill) {
        // 元のテキストデータを入れておく
        report.meta_info.inspection_result_content.html_quill =
          report.inspection_result_content
      }
      if (!report.meta_info.inspection_result_content.tables) {
        report.meta_info.inspection_result_content.tables = []
      }
      if (!report.meta_info.shutokou_inspector) {
        report.meta_info.shutokou_inspector = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.inspection_indicate) {
        report.meta_info.inspection_indicate = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.inspection_indicate2) {
        report.meta_info.inspection_indicate2 = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!report.meta_info.other1) {
        report.meta_info.other1 = {
          // 非表示にしておく
          is_visible: false
        }
      }
      if (!Object.hasOwn(report.meta_info, 'reportMode')) {
        report.meta_info.reportMode = ''
        report.meta_info.reportModeOtherReportName = ''
      }
      if (!Object.hasOwn(report.meta_info, 'timeDispMode')) {
        report.meta_info.timeDispMode = 'from-to'
      }
    },
    parseJson_(data) {
      data = this.parseJsonConstruction_(data)
      data = this.parseJsonMeta_(data)
      this.ensureMetaInfoStructure_(data)
      return data
    },
    getReport_() {
      const obj = {id: this.id}
      inspectionReportApi.show(obj)
        .then(({ data }) => {
          if (!this.isEditable(data)) { return }
          this.report = this.convReport_(data)
        })
    },
    getCopiedNewReport_() {
      const obj = {id: this.id}
      inspectionReportApi.show(obj)
        .then(({ data }) => {
          // コピーしたくない項目を初期化する
          data.id = -1

          data.shutokou_department_name = this.user.office.shutokou_department_name
          data.shutokou_office_name = this.user.office.shutokou_office_name
          data.inspector_office = {name: this.user.office_name}
          data.inspection_date = Vue.filter('dtFormat')(new Date(), 'yyyy/mm/dd')
          const currentUser = data.inspectors.find(inspector => inspector.id === this.user.id)
          if (!currentUser) { data.inspectors.push(this.user) }

          const paramArr = [
            'inspection_time',
            'inspection_time_end',
            'inspection_time_end_h',
            'inspection_time_end_m',
            'inspection_content',
            'inspection_result',
            'inspection_result_content',
            'inspection_indicate',
            'inspection_indicate2',
            'quality_management_remarks',
            'safety_management_remarks',
            'attached_documents',
          ]
          paramArr.forEach(val => {
            data[val] = ''
          })

          data.inspection_content_photos = []
          data.inspection_result_content_photos = []
          data.photos = []
          data.documents = []

          data.is_draft = false

          this.report = this.convReport_(data)

          // オブジェクトに変換後meta_info内を初期化
          const metaInfoProp = {
            'inspection_result_content': {},
          }
          Object.keys(metaInfoProp).forEach(k => {
            this.report.meta_info[k] = metaInfoProp[k]
          })
        })
    },
    isEditable(report) {
      if (this.user.is_sagyou) {
        return !!report.inspectors.find(inspector => inspector.id === this.user.id)
      }
      if (this.user.is_shunin || this.user.is_soukatsu) {
        return report.inspector_office_id === this.user.office_id
      }
      if (this.user.is_admin) {
        return true
      }
      return false
    },
    isDraggable(e) {
      if (e.draggedContext.element.id !== this.user.id) {
        return true
      } else if (e.from === e.to) {
        return true
      }
      return false
    },
    addInspector(inspector) {
      this.report.inspectors.push(inspector)
      this.onEdit()
    },
    removeInspector(idx) {
      this.report.inspectors.splice(idx, 1)
      this.onEdit()
    },
    addConstructionSideWitness(constructionSideWitness) {
      this.report.construction_side_witnesses.push(constructionSideWitness)
      this.onEdit()
    },
    removeConstructionSideWitness(idx) {
      this.report.construction_side_witnesses.splice(idx, 1)
      this.onEdit()
    },
    addTmpConstructionSideWitness() {
      this.tmpConstructionSideWitnessCands.push(this.tmpConstructionSideWitness)
      this.addConstructionSideWitness(this.tmpConstructionSideWitness)
      this.tmpConstructionSideWitness = ''
    },
    onEdit() {
      if (this.isEdited) { return }
      this.isEdited = true
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.report.inspection_date)) {
        this.hasInputError = true
      }
    },
    onTimeInput(report) {
      if (!report) { report = this.report }
      const h = report.inspection_time_h
      const m = report.inspection_time_m
      report.inspection_time = parseInt(h + m)
    },
    onTimeEndInput(report) {
      if (!report) { report = this.report }
      const h = report.inspection_time_end_h
      const m = report.inspection_time_end_m
      report.inspection_time_end = parseInt(h + m)
    },
    onEditorInput(prop, text) {
      // set editor text content
      this.report[prop] = text
    },
    onConstructionChange() {
      const construction = Object.assign({}, this.constructions.find(e => {
        return e.id === this.report.construction_id
      })) || {}
      this.report.construction = construction
      this.parseJsonConstruction_(this.report)

      // 工事件名から工種分類を取得
      this.report.tmp_construction_kind_type =
        this.report.construction.construction_kind_type
      // 工種絞り込み
      this.filterConstructionKind(this.report)
    },
    filterConstructionKind(report) {
      // 未選択の場合は全て表示
      if (!report.tmp_construction_kind_type) {
        this.construction_kinds = this.orig_construction_kinds
        return
      }
      // 工種絞り込み
      this.construction_kinds = this.orig_construction_kinds.filter(e => {
        return e.type === report.tmp_construction_kind_type
      })
    },
    getRequiredItemMap() {
      let ret = {}
      if (this.isReportModeTachiaiKensa) {
        ret = {
          construction_id: '工事名称',
          name: '検査名称',
          construction_kind_id: '工種',
          inspection_date: '検査日',
          inspection_time_h: '検査時刻(時)',
          inspection_time_m: '検査時刻(分)',
          inspection_time_end_h: '検査終了時刻(時)',
          inspection_time_end_m: '検査終了時刻(分)',
          climate_id: '天気',
          inspection_location: '検査場所',
          inspectors: '立会者',
          construction_side_witnesses: '施工側立会者',
          inspection_summary: '検査概要',
          inspection_content: '検査確認項目',
          inspection_result_content: '検査結果',
          inspection_result: '検査結果合否',
        }
      } else if (this.isReportModeGenbaKakunin) {
        ret = {
          construction_id: '工事名称',
          name: '確認名称',
          inspection_date: '確認日',
          inspection_time_h: '確認時刻(時)',
          inspection_time_m: '確認時刻(分)',
          inspection_time_end_h: '確認終了時刻(時)',
          inspection_time_end_m: '確認終了時刻(分)',
          climate_id: '天気',
          inspection_location: '確認場所',
          inspectors: '立会者',
          construction_side_witnesses: '施工側立会者',
          inspection_summary: '現場確認概要',
          inspection_content: '現場確認項目',
        }
      } else if (this.isReportModeOther) {
        ret = {
          construction_id: '工事名称',
          name: '確認名称',
          inspection_date: '確認日',
          inspection_time_h: '確認時刻(時)',
          inspection_time_m: '確認時刻(分)',
          inspection_time_end_h: '確認終了時刻(時)',
          inspection_time_end_m: '確認終了時刻(分)',
          climate_id: '天気',
          inspection_location: '確認場所',
          inspectors: '立会者',
          construction_side_witnesses: '施工側立会者',
          inspection_summary: '現場確認概要',
          inspection_content: '現場確認項目',
        }
      }

      if (this.isSaveModeDraft) {
        // 下書き保存時の必須項目
        const required = [
          'construction_id',
          'name',
          'construction_kind_id',
          'inspection_date',
          'inspection_time_h',
          'inspection_time_m',
          'inspection_time_end_h',
          'inspection_time_end_m',
          'climate_id',
          'inspectors',
        ]
        // それ以外は除外する
        for (const k in ret) {
          if (required.includes(k)) { continue }
          delete ret[k]
        }
      }

      return ret
    },
    getLengthCheckItemMap() {
      let ret = {}
      if (this.isReportModeTachiaiKensa) {
        ret = {
          name: '検査名称',
          inspection_location: '検査場所',
          shutokou_inspector: '首都高速道路 立会者',
          attached_documents: '別添資料',
        }
      } else if (this.isReportModeGenbaKakunin) {
        ret = {
          name: '確認名称',
          inspection_location: '確認場所',
          shutokou_inspector: '首都高速道路 立会者',
          attached_documents: '別添資料',
        }
      } else if (this.isReportModeOther) {
        ret = {
          name: '確認名称',
          inspection_location: '確認場所',
          shutokou_inspector: '首都高速道路 立会者',
          attached_documents: '別添資料',
        }
      }
      return ret
    },
    setReportSaveMode(isSaveModeDraft) {
      this.isSaveModeDraft = isSaveModeDraft
    },
    checkInputs() {
      this.errors = {}
      let val

      const requiredItemMap = this.getRequiredItemMap()
      for (const [column, dispName] of Object.entries(requiredItemMap)) {
        val = this.report[column]
        if (!val || val.length === 0) {
          this.errors[column] = [dispName + 'は必須項目です']
        }
      }

      if (this.isReportModeOther) {
        val = this.report.meta_info.reportModeOtherReportName
        if (!val || val.length === 0) {
          this.errors.report_mode_other_report_name = ['報告書名は必須項目です']
        }
      }

      const lengthCheckItemMap = this.getLengthCheckItemMap()
      for (const [column, dispName] of Object.entries(lengthCheckItemMap)) {
        val = this.report[column]
        if (!(column in this.errors)) {
          if (val.length > 255) {
            this.errors[column] = [dispName + 'は255文字以内で入力してください']
          }
        }
      }

      return Object.keys(this.errors).length === 0
    },
    getReportForReq() {
      const report = JSON.parse(JSON.stringify(this.report))
      // conv construction_side_witnesses
      report.construction_side_witnesses =
        JSON.stringify(report.construction_side_witnesses)
      // conv meta_info
      report.meta_info = JSON.stringify(report.meta_info)
      // 下書き保存
      report.is_draft = this.isSaveModeDraft

      return report
    },
    async createReport({ isSaveModeDraft } = { isSaveModeDraft: false }) {
      this.setReportSaveMode(isSaveModeDraft)

      if (!this.checkInputs()) { return }

      const reqObj = { data: this.getReportForReq() }
      try {
        const { data } = await inspectionReportApi.create(reqObj)
        this.report = this.convReport_(data)
        this.isEdited = false
        this.nextPath = ''
        this.showCreateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    async saveReport({ isSaveModeDraft } = { isSaveModeDraft: false }) {
      this.setReportSaveMode(isSaveModeDraft)

      if (!this.checkInputs()) { return }

      const reportForReq = this.getReportForReq()
      const reqObj = { id: reportForReq.id, data: reportForReq }
      try {
        await inspectionReportApi.update(reqObj)
        await this.getReport_()
        this.isEdited = false
        this.nextPath = ''
        this.showUpdateCompleteModal = true
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    closeCreateCompleteModal() {
      this.$router.replace({
        name: 'inspectionReportDetail',
        params: { id: this.report.id },
      })
      this.showCreateCompleteModal = false
      window.setTimeout(() => {
        this.isNew = false
        this.getReport_()
      }, 0)
    },
    closeConfirmBeforeLeaveModal() {
      this.isEdited = false
      this.$router.push(this.nextPath)
      this.nextPath = ''
      this.showConfirmBeforeLeaveModal = false
    },
    setTimeDispMode(val) {
      this.report.meta_info.timeDispMode = val

      if (val === 'from') {
        // toをfromに揃える
        this.report.inspection_time_end_h = this.report.inspection_time_h
        this.report.inspection_time_end_m = this.report.inspection_time_m
      } else if (val === 'from-to') {
        // そのまま
      } else if (val === 'yakan') {
        // 夜間時間帯の開始(17:40)に揃える
        this.report.inspection_time_h = '17'
        this.report.inspection_time_m = '40'
        this.report.inspection_time_end_h = '17'
        this.report.inspection_time_end_m = '40'
      } else if (val === 'none') {
        // 00:00に揃える
        this.report.inspection_time_h = '00'
        this.report.inspection_time_m = '00'
        this.report.inspection_time_end_h = '00'
        this.report.inspection_time_end_m = '00'
      }
      this.onTimeInput()
      this.onTimeEndInput()
    },
    setReportMode(val) {
      this.report.meta_info.reportMode = val
      // if (val === 'tachiai') {
      // } else if (val === 'genba') {
      // } else if (val === 'other') {
      // }
    }
  },
  components: { HeaderBar, Draggable, TableInput }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
