<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import officeApi from '@/api/office'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import masterMixin from '@/mixin/masterMixin'

export default {
  name: 'office',
  data() {
    const { dtStrFrom, dtStrTo } = this.getListInitialSearchParams()
    return {
      search: {
        dtFrom: dtStrFrom,
        dtTo: dtStrTo,
      },
      hasInputError: false,
      errors: [],
      list: [],
      listItemsPerPage: 20,
      currentPage: 1,
      isSearchResultEmpty: false,

      officeToDelete: null,

      officeListMaxHeight: 2000, // 適当
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.officeToDelete !== null
    },
    canSeeOfficeListPage() {
      return this.has_role_shunin
    },
  },
  async mounted() {
    await this.waitForUserAndMasters()
    if (!this.canSeeOfficeListPage) {
      location.href = '/'
    }

    this._doSearch()
    this.setOfficeListMaxHeight()
    window.addEventListener('resize', this.setOfficeListMaxHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setOfficeListMaxHeight)
  },
  mixins: [dateMixin, listPageMixin, masterMixin],
  methods: {
    setOfficeListMaxHeight() {
      const windowH = window.innerHeight
      const officeListOffsetTop = this.$refs.officeList.offsetTop
      const officeListOffsetBottom = 20
      this.officeListMaxHeight = windowH - officeListOffsetTop - officeListOffsetBottom
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dtFrom)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dtTo)) {
        this.hasInputError = true
      }
    },
    doSearch() {
      if (this.hasInputError) { return }
      this._doSearch()
    },
    _doSearch() {
      this.isSearchResultEmpty = false
      this.list = []
      this.currentPage = 1

      const obj = {
        from: new Date(this.search.dtFrom),
        to: new Date(this.search.dtTo)
      }
      officeApi.index().then(({ data }) => {
        if (!data || data.length === 0) {
          this.isSearchResultEmpty = true
          return
        }
        if (!this.has_role_admin) {
          // 管理者以外は自事務所のみ表示
          data = data.filter(e => e.id === this.user.office_id)
        }
        this.list = data
      })
    },
    confirmDeleteOffice(e) {
      this.errors = {}
      this.officeToDelete = e
    },
    deleteOffice() {
      const obj = {
        id: this.officeToDelete.id,
      }
      officeApi.delete(obj)
        .then(() => {
          this.officeToDelete = null
          this._doSearch()
        })
        .catch(err => {
          this.errors = err.response.data
        })
    },
  }
}
</script>

<style lang="scss" scoped>
  .btn-wrap1 {
    display: inline-block;
    margin-left: 8px;
  }
  .csv-download {
    margin-left: 8px;
  }
  .wrap1 {
    .p1 {
      margin: 4px 0;
    }
    .p2 {
      margin: 32px 0 16px;
    }
  }
</style>
