import EXIF from 'exif-js'

export default {
  methods: {
    deletctFaAwesomeClass(content_type) {
      switch (content_type){
        case "application/pdf":
          return "fa fa-file-pdf-o"
        case "application/vnd.ms-excel":
          return "fa fa-file-excel-o"
        case "application/vnd.ms-powerpoint":
          return "fa fa-file-powerpoint-o"
        case "application/msword":
          return "fa file-word-o"
        default:
          return "fa fa-file-text-o"
      }
    },
  }
}
