<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import constructionApi from '@/api/construction'
import masterMixin from '@/mixin/masterMixin'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'construction',
  data() {
    return {
      search: {
        dt_to: '',
        office_id: '',
      },
      hasInputError: false,
      errors: [],
      offices: [],
      list: [],
      listItemsPerPage: 20,
      currentPage: 1,
      isSearchResultEmpty: false,

      constructionToDelete: null,

      constructionListMaxHeight: 2000, // 適当
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.constructionToDelete !== null
    },
  },
  mounted() {
    this.waitForUserAndMasters().then(() => {
      this.offices = window.master.offices
      this.prepareSearchParams()
      this._doSearch()
      this.setConstructionListMaxHeight()
      window.addEventListener('resize', this.setConstructionListMaxHeight)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.setConstructionListMaxHeight)
  },
  mixins: [dateMixin, listPageMixin, masterMixin],
  methods: {
    prepareSearchParams() {
      let dtTo, officeId

      const listSearchParams = this.getListInitialSearchParams()
      if (listSearchParams.dtStrTo) {
        dtTo = listSearchParams.dtStrTo
      }

      const storage = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        dtTo: null,
        officeId: this.user.is_admin ? '' : this.user.office_id,
      }

      if (storage.dtTo) {
        dtTo = storage.dtTo
      }
      if (storage.officeId) {
        officeId = storage.officeId
      }

      if (this.user.is_sagyou || this.user.is_shunin || this.user.is_soukatsu) {
        officeId = this.user.office_id
      }

      this.search = {
        dt_to: dtTo,
        office_id: officeId,
      }
    },
    setConstructionListMaxHeight() {
      const windowH = window.innerHeight
      const constructionListOffsetTop = this.$refs.constructionList.offsetTop
      const constructionListOffsetBottom = 20
      this.constructionListMaxHeight =
        windowH - constructionListOffsetTop - constructionListOffsetBottom
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dt_to)) {
        this.hasInputError = true
      }
    },
    doSearch() {
      if (this.hasInputError) { return }
      this._doSearch()
    },
    _doSearch() {
      sessionStorage.setItem(this.$route.name, JSON.stringify({
        dtTo: this.search.dt_to,
        officeId: this.search.office_id,
      }))

      this.isSearchResultEmpty = false
      this.list = []
      this.currentPage = 1

      const obj = {
        date: this.search.dt_to,
        office_id: this.search.office_id,
      }
      constructionApi.search(obj).then(({ data }) => {
        if (!data || data.length === 0) {
          this.isSearchResultEmpty = true
          return
        }
        this.list = data
      })
    },
    confirmDeleteConstruction(e) {
      this.errors = {}
      this.constructionToDelete = e
    },
    deleteConstruction() {
      const obj = {
        id: this.constructionToDelete.id,
      }
      constructionApi.delete(obj)
        .then(() => {
          this.constructionToDelete = null
          this._doSearch()
        })
        .catch(err => {
          this.errors = err.response.data
        })
    },
  }
}
</script>

<style lang="scss" scoped>
  .btn-wrap1 {
    display: inline-block;
    margin-left: 8px;
  }
  .csv-download {
    margin-left: 8px;
  }
  .wrap1 {
    .p1 {
      margin: 4px 0;
    }
    .p2 {
      margin: 32px 0 16px;
    }
  }
</style>
