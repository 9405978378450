<template>
<div class="date-time-input">
  <my-calendar
    :date-value="dt"
    @date-input="onDateInput"
    :input-class-obj="dateInputClassObj"
    :date-input-bg-color="dateInputBgColor"
    :width="dateInputWidth"
    :input-clear-button="dateInputShowClearButton"
    :readonly="dateInputIsReadOnly"
  ></my-calendar>
  <time-input :value="tm_h" @input="onHourInput"
    mode="hour" class="ml-8">
  </time-input>
  <span class="colon ib ml-2 mr-2">:</span>
  <time-input :value="tm_m" @input="onMinuteInput" mode="minute">
  </time-input>
</div>
</template>

<script>
import Vue from 'vue'
import TimeInput from '@/components/lib/TimeInput'

export default {
  name: 'date-time-input',
  props: {
    dateInputBgColor: {
      type: String,
      default: ""
    },
    value: {
      type: Date, String,
    },
    dateInputClassObj: {
      type: Object,
      default() { return {} }
    },
    dateInputWidth: {
      type: String,
      default: '110px',
    },
    dateInputShowClearButton: {
      type: Boolean,
      default: false,
    },
    dateInputIsReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dt: '',
      tm_h: '',
      tm_m: '',
    }
  },
  watch: {
    value() {
      const date = Vue.filter('ensureDate')(this.value)
      if (!date || isNaN(date.valueOf())) {
        this.dt = ''
        this.tm_h = ''
        this.tm_m = ''
        return
      }
      // normalize
      date.setSeconds(0)
      date.setMilliseconds(0)

      this.dt = date
      this.tm_h = date.getHours()
      this.tm_m = date.getMinutes()
    },
  },
  methods: {
    onDateInput(val) {
      this.dt = val
      this.onChange()
    },
    onHourInput(val) {
      if (val === '') { val = -1 }
      this.tm_h = val
      this.onChange()
    },
    onMinuteInput(val) {
      if (val === '') { val = -1 }
      this.tm_m = val
      this.onChange()
    },
    onChange() {
      if (!this.dt || this.tm_h === -1 || this.tm_m === -1) {
        this.$emit('input', null)
        return
      }
      this.dt.setHours(this.tm_h)
      this.dt.setMinutes(this.tm_m)
      if (isNaN(this.dt.valueOf())) {
        this.$emit('input', null)
        return
      }
      this.$emit('input', this.dt)
    },
  },
  components: { TimeInput }
}
</script>

<style lang="scss" scoped>
</style>
