<template src="./template.html"></template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import masterMixin from '@/mixin/masterMixin'
import masterDetailMixin from '@/mixin/masterDetailMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'masterDetail',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lovs: null,
      info: {
        name: '',
        is_creatable: false,
        is_updatable: false,
        is_deletable: false,
        props: [],
      },
      list: [],

      showCreateModal: false,
      createEntry: {},

      showDeleteConfirmModal: false,
      deleteEntry: {},

      errors: {},

      masterListMaxHeight: 2000, // 適当
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const acc = []
      const tmpMap = {}
      const ret = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      acc.forEach(e => {
        if (tmpMap[e]) { return }
        tmpMap[e] = true
        ret.push(e)
      })
      return ret
    },
  },
  mounted() {
    this.waitForMasters().then(() => {
      this.lovs = window.master.lovs
      const lov = this.lovs[this.id]
      if (!lov) { return }
      if (!lov.is_displayable) { return }

      // マスタ名
      this.info.name = lov.disp_name
      // 編集とか可否
      this.info.is_creatable = lov.is_creatable
      this.info.is_updatable = lov.is_updatable
      this.info.is_deletable = lov.is_deletable

      // 列
      this.info.props = this.getLovDispColInfo(this.id, lov)
      this.info.props.forEach(propInfo => {
        if (propInfo.edit_spec.type === 'select') {
          propInfo.edit_spec.options =
            this.lovs[propInfo.edit_spec.lov_name].vals
        }
      })

      // 行
      this.list = this.convDataArr(lov.vals)
      this.setMasterListMaxHeight()
    })
    window.addEventListener('resize', this.setMasterListMaxHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setMasterListMaxHeight)
  },
  mixins: [masterMixin, masterDetailMixin, errorHandleMixin],
  methods: {
    setMasterListMaxHeight() {
      const windowH = window.innerHeight
      const masterListOffsetTop = this.$refs.masterList.offsetTop
      const masterListOffsetBottom = 20
      this.masterListMaxHeight = windowH - masterListOffsetTop - masterListOffsetBottom
    },
    convDataArr(arr) {
      return arr.map(e => this.convData(this.id, e))
    },
    convData(lovName, entry) {
      // 元のオブジェクトをcloneして必要なparamがあれば追加
      const ret = this.convLovEntry(lovName, entry)
      // 各列の表示文字列を変換
      this.info.props.forEach(propInfo => {
        const prop = propInfo.key
        let val = ret[prop]
        const dispRule = propInfo.disp_spec
        if (dispRule) {
          // 表示名ルールがある場合、別のlovから取ってくる.
          const [refLovName, refLovProp] = dispRule.split('.')
          const refLov = this.lovs[refLovName]
          if (!refLov) {
            // shouldn't happen
            console.error(`lovName=${refLovName} is wrong`)
          }
          const targetLovEntry = refLov.map[val]
          if (targetLovEntry) {
            val = targetLovEntry[refLovProp]
          } else {
            // 途中までしか値が入ってないことはありうる.
            // 路線名-方向 とか.
            // が、値が入ってて取れないのはおかしい.
            if (val) {
              // shouldn't happen
              console.error(`${val} not found for lovName=${refLovName}`)
            }
          }
        }
        // 全部'_disp'を付けた別のプロパティに入れる
        ret[`${prop}_disp`] = val
      })
      return ret
    },
    doCSVDownload() {
      // TODO
    },
    clearErrors() {
      this.errors = {}
    },
    checkEntry(entry) {
      this.clearErrors()
      let ret = true
      if (!entry.key_edit) {
        this.errors['key'] = ['IDは必須項目です']
        ret = false
      }
      if (isNaN(entry.disp_order_edit)) {
        this.errors['disp_order'] = ['表示順には数値を入力してください']
        ret = false
      }
      this.info.props.forEach(prop => {
        const origProp = prop.orig_prop
        // 最初のやつは必須項目
        if (origProp === 'val1') {
          const val = entry[`${prop.key}_edit`]
          if (val === null || val === undefined || val === '') {
            this.errors[prop.key] = [`${prop.disp_name}は必須項目です`]
            ret = false
          }
        }
      })
      if (!entry.start_use_edit) {
        this.errors['start_use'] = ['利用開始日は必須項目です']
        ret = false
      }
      if (!entry.end_use_edit) {
        this.errors['end_use'] = ['利用終了日は必須項目です']
        ret = false
      }

      return ret
    },
    getReqObj(entry) {
      const dataObj = {}
      this.info.props.forEach(propInfo => {
        const fromProp = `${propInfo.key}_edit`
        let toProp = propInfo.orig_prop || propInfo.key
        dataObj[toProp] = entry[fromProp]
      })
      dataObj.disp_order = parseInt(dataObj.disp_order)
      dataObj.start_use = entry.start_use_edit
      dataObj.end_use = entry.end_use_edit
      return {
        lovName: this.id,
        lovKey: dataObj.key,
        data: dataObj,
      }
    },
    reOrderList() {
      this.list = this.list.sort((a, b) => {
        const v1 = a.disp_order
        const v2 = b.disp_order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
    },
    showAddEntryModal() {
      this.clearErrors()
      this.createEntry = {
        start_use_edit: new Date(),
        end_use_edit: new Date(9999, 0, 1, 0, 0, 0),
      }
      this.showCreateModal = true
    },
    doAddEntry() {
      if (!this.checkEntry(this.createEntry)) { return }
      const reqObj = this.getReqObj(this.createEntry)
      masterApi.create(reqObj)
        .then(({ data }) => {
          const dataConv = this.convData(this.id, data)
          this.list.push(dataConv)
          this.reOrderList()
          this.showCreateModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    startEditEntry(entry) {
      this.clearErrors()
      this.info.props.forEach(propInfo => {
        const key = propInfo.key
        entry[`${key}_edit`] = entry[key]
      })
      entry.start_use_edit = entry.start_use
      entry.end_use_edit = entry.end_use
      entry.isEditMode = true
    },
    saveEntry(entry, i) {
      if (!this.checkEntry(entry)) { return }
      const reqObj = this.getReqObj(entry)
      masterApi.update(reqObj)
        .then(({ data }) => {
          const dataConv = this.convData(this.id, data)
          this.endEditEntry(dataConv)
          Vue.set(this.list, i, dataConv)
          this.reOrderList()
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    endEditEntry(entry) {
      entry.isEditMode = false
    },
    dismissAddEntryModal() {
      this.clearErrors()
      this.createEntry = {}
      this.showCreateModal = false
    },
    confirmDeleteEntry(entry) {
      this.clearErrors()
      this.deleteEntry = entry
      this.showDeleteConfirmModal = true
    },
    doDeleteEntry() {
      const reqObj = {
        lovName: this.id,
        lovKey: this.deleteEntry.key,
      }
      masterApi.delete(reqObj)
        .then(({ data }) => {
          let idx = -1
          this.list.forEach((ent, i) => {
            if (ent.key === this.deleteEntry.key) {
              idx = i
            }
          })
          if (idx !== -1) {
            Vue.delete(this.list, idx)
          }
          this.deleteEntry = {}
          this.showDeleteConfirmModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    dismissDeleteConfirmModal() {
      this.clearErrors()
      this.deleteEntry = {}
      this.showDeleteConfirmModal = false
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
