import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/constructions'), {
      params: obj || {}
    })
  },
  active(obj) {
    return axios.get(utils.getApiUrl('/constructions/active'), {
      params: obj || {}
    })
  },
  search(obj) {
    return axios.get(utils.getApiUrl('/constructions/search'), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl(`/constructions`), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/constructions/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/constructions/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/constructions/${id}`))
  },
}
