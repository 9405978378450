export default {
  methods: {
    getTextAreaRow(str) {
      if (!str) { return 3 }
      if (!str.match(/\r?\n/g)) { return 3 }
      return str.match(/\r?\n/g).length + 1
    },
    getOneLineTextAreaRow(str) {
      if (!str) { return 1 }
      if (!str.match(/\r?\n/g)) { return 1 }
      return str.match(/\r?\n/g).length + 1
    },
    formatterLineBreak(str) {
      if (!str) { return '' }
      return str.replace(/\r?\n/g, '<br/>')
    }
  }
}
