import EXIF from 'exif-js'

export default {
  methods: {
    loadImageWithExif(imageSrc) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          EXIF.getData(image, () => {
            resolve(image)
          })
        }
        image.src = imageSrc
      })
    },
    writeImageToCanvas(image, canvas, { maxHeight, maxWidth } = {}) {
      const exifOrientation =
        image.exifdata ? image.exifdata.Orientation : -1

      const imageWidth = image.width
      const imageHeight = image.height
      let scaledWidth
      let scaledHeight
      let canvasWidth
      let canvasHeight
      if ([5,6,7,8].indexOf(exifOrientation) !== -1) {
        // 90 rotated
        scaledWidth = maxHeight ?
          Math.min(imageWidth, maxHeight) : imageWidth
        scaledHeight = scaledWidth * imageHeight / imageWidth
        if (maxWidth && scaledHeight > maxWidth) {
          scaledHeight = maxWidth
          scaledWidth = scaledHeight * imageWidth / imageHeight
        }
        canvasWidth = scaledHeight
        canvasHeight = scaledWidth
      } else {
        scaledHeight = maxHeight ?
          Math.min(imageHeight, maxHeight): imageHeight
        scaledWidth = scaledHeight * imageWidth / imageHeight
        if (maxWidth && scaledWidth > maxWidth) {
          scaledWidth = maxWidth
          scaledHeight = scaledWidth * imageHeight / imageWidth
        }
        canvasWidth = scaledWidth
        canvasHeight = scaledHeight
      }

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      const ctx = canvas.getContext('2d')
      ctx.save()
      // http://jpegclub.org/exif_orientation.html
      switch(exifOrientation) {
        case 1:
          break
        case 2: // horizontal flip
          ctx.translate(scaledWidth, 0)
          ctx.scale(-1, 1)
          break
        case 3: // 180 rotate left
          ctx.translate(scaledWidth, scaledHeight)
          ctx.rotate(Math.PI)
          break
        case 4: // vertical flip
          ctx.translate(0, scaledHeight)
          ctx.scale(1, -1)
          break
        case 5: // vertical flip + 90 rotate right
          ctx.rotate(-Math.PI / 2)
          ctx.scale(-1, 1)
          break
        case 6: // 90 rotate right
          ctx.rotate(Math.PI / 2)
          ctx.translate(0, -scaledHeight)
          break
        case 7: // horizontal flip + 90 rotate right
          ctx.rotate(-Math.PI / 2)
          ctx.scale(1, -1)
          ctx.translate(-scaledWidth, -scaledHeight)
          break
        case 8: // 90 rotate left
          ctx.rotate(-Math.PI / 2)
          ctx.translate(-scaledWidth, 0)
          break
        default:
          break
      }

      ctx.drawImage(image,
        0, 0, image.width, image.height,
        0, 0, scaledWidth, scaledHeight)
      ctx.restore()
    },
    canvasToBlob(canvas) {
      // 'data:image/jpeg;base64,xxxxxxxxxxxxxxxxxxxx'
      const dataURI = canvas.toDataURL('image/jpeg')
      const tmpURIPart = dataURI.split(',')
      const meta = tmpURIPart[0]
      const data = tmpURIPart[1]

      let bs
      if (meta.indexOf('base64') >= 0) {
        bs = atob(data)
      } else {
        bs = decodeURI(data)
      }

      const mimeString = meta.split(':')[1].split(';')[0]

      const ia = new Uint8Array(bs.length)
      for (let i = 0, len = bs.length; i < len; i++) {
        ia[i] = bs.charCodeAt(i)
      }
      return new Blob([ia], { type: mimeString })
    },
  }
}
