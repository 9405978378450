<template src="./template.html"></template>

<script>
import masterMixin from '@/mixin/masterMixin'

export default {
  name: 'masterList',
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.waitForMasters().then(() => {
      const lovs = window.master.lovs
      const list = []
      Object.keys(lovs).forEach(k => {
        const obj = lovs[k]
        if (!obj.is_displayable) { return }
        list.push({
          id: k,
          name: obj.disp_name,
          order: obj.disp_order,
        })
      })
      list.sort((a, b) => {
        const v1 = a.order
        const v2 = b.order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
      this.list = list
    })
  },
  mixins: [masterMixin],
  methods: {
    doCSVDownload() {
      //TODO
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
