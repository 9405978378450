<template>
<div class="photo-editor" ref="photoEditor">
  <div class="photo-edit">
    <div class="mb-8">
      <div class="ib mr-8" v-if="isEditStateDraw">
        <button class="btn btn-default mr-2 undo" @click="undo"
            :disabled="layers.length === 0">
          <i class="fa fa-undo"></i>
          戻す
        </button>
        <button class="btn btn-default redo" @click="redo"
            :disabled="undoneLayers.length === 0">
          <i class="fa fa-repeat"></i>
          やり直す
        </button>
      </div>
      <div class="ib toolbox1" v-if="isEditStateDraw">
        <button class="btn" :class="{ 'btn-primary': isDrawModeFree }"
            @click="setDrawMode(DRAW_MODE.FREE)">
          手書き
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeLine }"
            @click="setDrawMode(DRAW_MODE.LINE)">
          直線
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeArrow }"
            @click="setDrawMode(DRAW_MODE.ARROW)">
          矢印
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeRect }"
            @click="setDrawMode(DRAW_MODE.RECT)">
          四角
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeCircle }"
            @click="setDrawMode(DRAW_MODE.CIRCLE)">
          正円
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeEllipse }"
            @click="setDrawMode(DRAW_MODE.ELLIPSE)">
          楕円
        </button>
        <button class="btn" :class="{ 'btn-primary': isDrawModeText }"
          @click="prepareTextDrawMode">
          文字
        </button>
        <div class="text-modal" v-if="showTextModal">
          <div class="text-box">
            <div class="input-group">
              <input type="text" class="ib form-control"
                v-model="text" ref="textInput">
              <span class="input-group-btn">
                <button class="ib btn btn-primary" :disabled="!text"
                    @click="startTextDrawMode">
                  決定
                </button>
                <button class="ib btn btn-default" @click="cancelTextDrawMode">
                  キャンセル
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="ib toolbox2" v-if="isEditStateDraw">
        <button class="btn color-btn"
            :style="{ 'background-color' : strokeColor }"
            @click="showColorPalette = !showColorPalette">
          {{selectedColorName}}
        </button>
        <div class="row color-palette" v-if="showColorPalette">
          <button class="color" style="background-color:#000"
            @click="setStrokeColor('#000')"></button>
          <button class="color" style="background-color:#fff"
            @click="setStrokeColor('#fff')"></button>
          <button class="color" style="background-color:#f00"
            @click="setStrokeColor('#f00')"></button>
          <button class="color" style="background-color:#ff0"
            @click="setStrokeColor('#ff0')"></button>
          <button class="color" style="background-color:#0f0"
            @click="setStrokeColor('#0f0')"></button>
          <button class="color" style="background-color:#00f"
            @click="setStrokeColor('#00f')"></button>
        </div>
        <div class="ib">
          <button class="btn line-btn" @click="lineType = 'dashed'"
              v-if="lineType === 'solid'">
            <div style="border-top:1px solid #000;"></div>
            実線
          </button>
          <button class="btn line-btn" @click="lineType = 'solid'"
              v-if="lineType === 'dashed'">
            <div style="border-top:1px dashed #000;"></div>
            点線
          </button>
        </div>
      </div>
      <div class="ib" v-if="isEditStateMove">
        <button class="btn btn-default mr-2 undo"
            @click="discardAndExitEditStateMove">
          戻る
        </button>
        <button class="btn btn-primary move-complete"
            @click="completeEditStateMove">
          確定
        </button>
        <span class="ib move-support-mongon ml-12">
          位置を調整し、「確定」を押してください
        </span>
      </div>
    </div>
    <div class="canvas-wrapper" ref="canvasWrapper">
      <img class="photo-img" :src="photoImage.src"
        :width="sizeInfo.width" :height="sizeInfo.height">
      <canvas class="base-canvas"
        :width="sizeInfo.width"
        :height="sizeInfo.height"
        @touchmove="handleMove" @touchstart="handleStart" @touchend="handleEnd"
        @mousemove="handleMove" @mousedown="handleStart" @mouseup="handleEnd"
        ref="photoCanvas">
      </canvas>
      <canvas v-show="false"
        :width="sizeInfo.realWidth"
        :height="sizeInfo.realHeight"
        ref="hiddenRealSizeCanvas">
      </canvas>
    </div>
    <div class="photo-edit-bottom-bar ta-center">
      <button class="btn btn-lg btn-primary"
          :disabled="isUploadingPhoto"
          @click="savePhoto">
        <i class="fa fa-spinner fa-spin"
          v-show="isUploadingPhoto"></i>
        保存
      </button>
      <button class="btn btn-lg btn-default" @click="onCancel">
        キャンセル
      </button>
    </div>
  </div>
</div>
</template>

<script>
import photoApi from '@/api/photo'
import imageMixin from '@/mixin/imageMixin'
import mediaMixin from '@/mixin/mediaMixin'

export default {
  name: 'photo-editor',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    photo: {
      type: Object,
      required: true,
    },
    photoType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isUploadingPhoto: false,
      photoImage: {src: ''},
      sizeInfo: {
        width: 0,
        height: 0,
        realWidth: 0,
        realHeight: 0,
      },
      showColorPalette: false,

      drawMode: 1,
      editState: 'draw',
      isDrawing: false,
      isDrawn: false,
      strokeColor: '#000',
      lineType: 'solid',
      text: '',
      showTextModal: false,
      isMovingObjectPosition: false,

      layers: [],
      undoneLayers: [],
      start: { x: 0, y: 0 },
      point: { x: 0, y: 0 },
      min: { x: 0, y: 0 },
      max: { x: 0, y: 0 },
      moveShapePosition: {
        start: { x: null, y: null },
        point: { x: null, y: null },
        max: { x: null, y: null },
        min: { x: null, y: null },
      },

      DRAW_MODE: {
        NONE: -1,
        FREE: 1,
        LINE: 11,
        ARROW: 12,
        RECT: 21,
        CIRCLE: 31,
        ELLIPSE: 32,
        TEXT: 41,
      },
      DRAW_SHAPE: {
        MIN: 1,
        MAX: 39,
      },
      EDIT_STATE: {
        DRAW: 'draw',
        MOVE: 'move',
      },
    }
  },
  computed: {
    selectedColorName() {
      let ret = '色'
      switch (this.strokeColor) {
        case '#000': ret = '黒'; break
        case '#fff': ret = '白'; break
        case '#f00': ret = '赤'; break
        case '#ff0': ret = '黄'; break
        case '#0f0': ret = '緑'; break
        case '#00f': ret = '青'; break
        default: break
      }
      return ret
    },
    isDrawModeShape() {
      return this.drawMode >= this.DRAW_SHAPE.MIN &&
        this.drawMode <= this.DRAW_SHAPE.MAX
    },
    isDrawModeFree() {
      return this.drawMode === this.DRAW_MODE.FREE
    },
    isDrawModeLine() {
      return this.drawMode === this.DRAW_MODE.LINE
    },
    isDrawModeArrow() {
      return this.drawMode === this.DRAW_MODE.ARROW
    },
    isDrawModeRect() {
      return this.drawMode === this.DRAW_MODE.RECT
    },
    isDrawModeCircle() {
      return this.drawMode === this.DRAW_MODE.CIRCLE
    },
    isDrawModeEllipse() {
      return this.drawMode === this.DRAW_MODE.ELLIPSE
    },
    isDrawModeText() {
      return this.drawMode === this.DRAW_MODE.TEXT
    },
    isEditStateDraw() {
      return this.editState === this.EDIT_STATE.DRAW
    },
    isEditStateMove() {
      return this.editState === this.EDIT_STATE.MOVE
    },
  },
  watch: {
    photo() {
      this.loadImage()
    },
  },
  mounted() {
    this.loadImage()
  },
  mixins: [imageMixin, mediaMixin],
  methods: {
    loadImage() {
      const image = new Image()
      image.onload = () => {
        this.photoImage = image
        this.sizeInfo.realHeight = image.height
        this.sizeInfo.realWidth = image.width

        const maxHeight = window.innerHeight - 280
        const maxWidth = this.$refs.photoEditor.offsetWidth
        let h = image.height
        let w = image.width
        if (h > maxHeight) {
          h = maxHeight
          w = h * image.width / image.height
        }
        if (w > maxWidth) {
          w = maxWidth
          h = w * image.height / image.width
        }
        this.sizeInfo.height = h
        this.sizeInfo.width = w
      }
      this.getBlobUrl(this.photo.photo_url_original)
        .then(({ url }) => {
          image.src = url
        })
    },
    setDrawMode(drawMode) {
      this.drawMode = drawMode
    },
    setStrokeColor(colorStr) {
      this.strokeColor = colorStr
      this.showColorPalette = false
    },
    getPoint: function(e) {
      const rect = e.target.getBoundingClientRect()
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      }
    },
    addNewLayer() {
      const layer = document.createElement('canvas')
      layer.width = this.sizeInfo.width
      layer.height = this.sizeInfo.height
      layer.style.position = 'absolute'
      layer.style.left = 0
      layer.style.top = 0
      layer.style.pointerEvents = 'none'

      const ctx = layer.getContext('2d')
      ctx.strokeStyle = this.strokeColor
      ctx.fillStyle = this.strokeColor
      if (this.lineType === 'dashed') {
        ctx.setLineDash([10, 5])
      }

      this.$refs.canvasWrapper.append(layer)
      this.purgeUndoneLayers()
      this.layers.push(layer)
    },
    prepareTextDrawMode() {
      this.setDrawMode(this.DRAW_MODE.TEXT)
      this.showTextModal = true
      window.setTimeout(() => {
        this.$refs.textInput.focus()
      }, 0)
    },
    cancelTextDrawMode() {
      this.showTextModal = false
      this.setDrawMode(this.DRAW_MODE.NONE)
    },
    startTextDrawMode() {
      this.showTextModal = false
      this.start = {
        x: 0,
        y: 0
      }
      this.point = {
        x: this.sizeInfo.width / 2,
        y: this.sizeInfo.height / 4
      }
      this.addNewLayer()
      this.onTextPositionMove_()
      this.editState = this.EDIT_STATE.MOVE
    },
    onDrawShapeStart_(e) {
      this.start = this.getPoint(e)
      this.point = this.getPoint(e)
      this.min = this.getPoint(e)
      this.max = this.getPoint(e)
      this.addNewLayer()
    },
    onTextPositionMoveStart_(e) {
      this.start = this.getPoint(e)
      this.point = this.getPoint(e)
      this.min = this.getPoint(e)
      this.max = this.getPoint(e)
      this.isMovingObjectPosition = true
    },
    onShapePositionMoveStart_(e) {
      // フリーの場合は動かせない
      if (this.drawMode === this.DRAW_MODE.FREE) { return }

      this.start = this.getPoint(e)
      this.point = this.getPoint(e)
      this.min = this.getPoint(e)
      this.max = this.getPoint(e)
      this.isMovingObjectPosition = true
    },
    handleStart(e) {
      if (this.drawMode < 0) { return }
      e.preventDefault()
      e = e.changedTouches ? e.changedTouches[0] : e
      if (this.isEditStateDraw) {
        this.isDrawing = true
        if (this.isDrawModeShape) {
          this.onDrawShapeStart_(e)
        }
      } else if (this.isEditStateMove) {
        if (this.isDrawModeShape) {
          this.onShapePositionMoveStart_(e)
        } else if (this.isDrawModeText) {
          this.onTextPositionMoveStart_(e)
        }
      }
    },
    onDrawShapeMove_(e) {
      const oldPoint = this.point
      this.point = this.getPoint(e)

      const ctx = this.layers.slice(-1)[0].getContext('2d')
      ctx.beginPath()
      ctx.moveTo(oldPoint.x, oldPoint.y)
      ctx.lineTo(this.point.x, this.point.y)
      ctx.lineWidth = 4
      ctx.stroke()

      this.max = {
        x: Math.max(this.max.x, this.point.x),
        y: Math.max(this.max.y, this.point.y)
      }
      this.min = {
        x: Math.min(this.min.x, this.point.x),
        y: Math.min(this.min.y, this.point.y)
      }
    },
    onTextPositionMove_(e) {
      if (e) { this.point = this.getPoint(e) }
      const ctx = this.layers.slice(-1)[0].getContext('2d')
      ctx.clearRect(0, 0, this.sizeInfo.width, this.sizeInfo.height)
      const length = this.text.length
      const max = {
        x: Math.max(this.start.x, this.point.x),
        y: Math.max(this.start.y, this.point.y)
      }
      const min = {
        x: Math.min(this.start.x, this.point.x),
        y: Math.min(this.start.y, this.point.y)
      }
      const tH = max.y - min.y
      const tW = max.x - min.x
      const size = Math.floor(Math.sqrt(tW * tH / length))
      ctx.font = size + 'px monospace'
      let col = 0
      let row = 1
      let i = 0
      while (i < length) {
        const c = this.text.charAt(i)
        ctx.fillText(c, min.x + size * col, min.y + size * row)
        col += 1
        if (size * (col + 1) > tW) {
          col = 0
          row++
        }
        i++
      }
    },
    onShapePositionMove_(e) {
      this.point = this.getPoint(e)
      const move = {
        x: this.point.x - this.start.x,
        y: this.point.y - this.start.y,
      }

      const start = {
        x: this.moveShapePosition.start.x + move.x,
        y: this.moveShapePosition.start.y + move.y,
      }
      const point = {
        x: this.moveShapePosition.point.x + move.x,
        y: this.moveShapePosition.point.y + move.y,
      }
      const min = {
        x: this.moveShapePosition.min.x + move.x,
        y: this.moveShapePosition.min.y + move.y,
      }
      const max = {
        x: this.moveShapePosition.max.x + move.x,
        y: this.moveShapePosition.max.y + move.y,
      }
      const ctx = this.layers.slice(-1)[0].getContext('2d')
      this.redrawShape_({ ctx, start, point, min, max })
    },
    handleMove(e) {
      if (this.drawMode < 0) { return }
      e.preventDefault()
      e = e.changedTouches ? e.changedTouches[0] : e
      if (this.isEditStateDraw && this.isDrawing) {
        this.isDrawn = true
        if (this.isDrawModeShape) {
          this.onDrawShapeMove_(e)
        }
      } else if (this.isEditStateMove && this.isMovingObjectPosition) {
        if (this.isDrawModeShape) {
          this.onShapePositionMove_(e)
        } else if (this.isDrawModeText) {
          this.onTextPositionMove_(e)
        }
      }
    },
    redrawShape_({ ctx, start, point, min, max }) {
      // フリーの場合は変形不要
      if (this.drawMode === this.DRAW_MODE.FREE) { return }

      // 形状をdraw modeに合わせて変形
      ctx.clearRect(0, 0, this.sizeInfo.width, this.sizeInfo.height)
      if (this.drawMode === this.DRAW_MODE.LINE) {
        ctx.beginPath()
        ctx.moveTo(start.x, start.y)
        ctx.lineTo(point.x, point.y)
        ctx.lineWidth = 4
        ctx.stroke()
      } else if (this.drawMode === this.DRAW_MODE.ARROW) {
        ctx.beginPath()
        ctx.moveTo(start.x, start.y)
        ctx.lineTo(point.x, point.y)
        const x = point.x - start.x
        const y = point.y - start.y
        const v = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
        ctx.lineTo(point.x + (-x - y) / v * 10, point.y + (x - y) / v * 10)
        ctx.lineTo(point.x + (-x + y) / v * 10, point.y + (-x - y) / v * 10)
        ctx.lineTo(point.x, point.y)
        ctx.stroke()
      } else if (this.drawMode === this.DRAW_MODE.RECT) {
        ctx.beginPath()
        ctx.lineWidth = 4
        ctx.rect(min.x, min.y, max.x - min.x, max.y - min.y)
        ctx.stroke()
      } else if (this.drawMode === this.DRAW_MODE.CIRCLE) {
        ctx.beginPath()
        ctx.lineWidth = 4
        ctx.arc(
          (min.x + max.x) / 2,
          (min.y + max.y) / 2,
          Math.sqrt(Math.pow(max.x - min.x, 2) + Math.pow(max.y - min.y, 2)) / 3,
          0,
          2 * Math.PI
        )
        ctx.stroke()
      } else if (this.drawMode === this.DRAW_MODE.ELLIPSE) {
        ctx.beginPath()
        ctx.lineWidth = 4
        ctx.ellipse(
          (min.x + max.x) / 2,
          (min.y + max.y) / 2,
          (max.x - min.x) / 2,
          (max.y - min.y) / 2,
          0,
          0,
          2 * Math.PI
        )
        ctx.stroke()
      }
    },
    onDrawShapeEnd_() {
      const ctx = this.layers.slice(-1)[0].getContext('2d')
      this.redrawShape_({
        ctx: ctx,
        start: this.start,
        point: this.point,
        min: this.min,
        max: this.max,
      })

      if (this.isDrawModeFree) {
        // 手書きは動かせない
      } else {
        this.moveShapePosition = {
          start: this.start,
          point: this.point,
          min: this.min,
          max: this.max,
        }
        this.editState = this.EDIT_STATE.MOVE
      }
    },
    onTextPositionMoveEnd_() {
      this.isMovingObjectPosition = false
    },
    onShapePositionMoveEnd_() {
      const move = {
        x: this.point.x - this.start.x,
        y: this.point.y - this.start.y,
      }
      this.moveShapePosition.start.x += move.x
      this.moveShapePosition.start.y += move.y
      this.moveShapePosition.point.x += move.x
      this.moveShapePosition.point.y += move.y
      this.moveShapePosition.min.x += move.x
      this.moveShapePosition.min.y += move.y
      this.moveShapePosition.max.x += move.x
      this.moveShapePosition.max.y += move.y

      this.isMovingObjectPosition = false
    },
    handleEnd(e) {
      if (this.drawMode < 0) { return }
      if (this.isEditStateDraw && this.isDrawing) {
        const wasDrawn = this.isDrawn
        this.isDrawing = false
        this.isDrawn = false
        if (this.isDrawModeShape) {
          if (!wasDrawn) {
            this.layers.slice(-1)[0].remove()
            this.layers.pop()
          } else {
            this.onDrawShapeEnd_()
          }
        }
      } else if (this.isEditStateMove && this.isMovingObjectPosition) {
        if (this.isDrawModeShape) {
          this.onShapePositionMoveEnd_()
        } else if (this.isDrawModeText) {
          this.onTextPositionMoveEnd_()
        }
      }
    },
    discardAndExitEditStateMove() {
      const layer = this.layers.slice(-1)[0]
      if (layer) {
        layer.remove()
        this.layers.pop()
      }
      this.editState = this.EDIT_STATE.DRAW
    },
    completeEditStateMove() {
      this.editState = this.EDIT_STATE.DRAW
    },
    undo() {
      const layer = this.layers.slice(-1)[0]
      if (layer) {
        layer.style.display = 'none'
        this.undoneLayers.push(layer)
        this.layers.pop()
      }
    },
    purgeUndoneLayers() {
      for (const layer of this.undoneLayers) {
        layer.remove()
      }
      this.undoneLayers = []
    },
    redo() {
      const layer = this.undoneLayers.slice(-1)[0]
      if (layer) {
        layer.style.display = 'block'
        this.layers.push(layer)
        this.undoneLayers.pop()
      }
    },
    savePhoto() {
      // write to real size canvas
      this.writeImageToCanvas(
        this.photoImage,
        this.$refs.hiddenRealSizeCanvas,
        {
          maxWidth: this.sizeInfo.realWidth,
          maxHeight: this.sizeInfo.realHeight,
        }
      )
      const photoCtx = this.$refs.hiddenRealSizeCanvas.getContext('2d')
      this.layers.forEach(layer => {
        photoCtx.drawImage(
          layer, 0, 0, this.sizeInfo.realWidth, this.sizeInfo.realHeight)
      })

      const formData = new FormData()
      const ts = new Date().valueOf()
      formData.append('photo_date', this.photo.photo_date)
      formData.append(
        'photos[]',
        this.canvasToBlob(this.$refs.hiddenRealSizeCanvas),
        `${this.photo.id}_edit_${ts}.jpeg`
      )

      this.isUploadingPhoto = true
      const obj = {
        formData: formData
      }
      photoApi.create(obj).then(({ data }) => {
        this.isUploadingPhoto = false
        this.layers.forEach(layer => {
          layer.remove()
        })
        this.$emit('done', data)
      }).catch(() => {
        this.isUploadingPhoto = false
      })
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.undo, .redo {
  height: 42px;
  border-color: #ddd;
}
.btn.move-complete {
  height: 42px;
}
.move-support-mongon {
  font-size: 0.8em;
}
.toolbox1, .toolbox2 {
  position: relative;
  padding: 4px 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.color-btn {
  border: 1px solid #ddd;
}
.color-palette {
  background-color: #fff;
  border: solid 1px #ddd;
  position: absolute;
  top: 110%;
  left: 14px;
  padding: 6px 10px;
  width: 96px;
  z-index: 101;

  .color {
    border: solid 1px #ddd;
    display: inline-block;
    height: 30px;
    width: 30px;
    margin: 2px;
    border-radius: 4px;
  }
}
.canvas-wrapper {
  position: relative;
  width: 100%;

  .base-canvas {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    font-family: monospace;
  }
}
.text-modal {
  background-color: rgba(0, 0, 0, 0.666);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .text-box {
    background-color: white;
    border-top: solid 1px #999;
    bottom: 0;
    left: 0;
    padding: 2rem 1rem;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
}

.photo-img {
  position: relative;
  pointer-events: none;
}
.photo-edit-bottom-bar {
  margin-top: 8px;
}
</style>
