<template>
<div class="detail-header-bar ta-center"
    :class="{
      'top-bar': isHeaderMode,
      'bottom-bar': isFooterMode,
    }">
  <div v-if="isFooterMode" v-show="hasError"
      class="err-msg err-msg-bottom ta-left">
    <div v-for="(msg, idx) in errorMessages" :key="idx">・{{ msg }}</div>
  </div>
  <div class="bar-row">
    <div class="mode-draft" v-if="isDraft">下書き</div>
    <div class="row">
      <template v-if="isNew">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
          <button class="btn btn-lg btn-default mr-8"
              @click="$router.go(-1)">
            戻る
          </button>
          <button class="btn btn-lg btn-info mr-8"
              v-if="isNew" @click="onClickCreateAsDraft">
            下書き保存
          </button>
          <button class="btn btn-lg btn-primary"
              v-if="isNew" @click="onClickCreate">
            作成
          </button>
        </div>
        <div class="memo-about-photo1" v-if="isNew" >
          写真は一度報告書を保存した後に追加できます
        </div>
      </template>
      <template v-else-if="isDraft">
        <div class="col-md-3">
          <router-link :to="{
            name: previousRouteName,
            params: {id: inspection_report_id},
          }" class="btn btn-lg btn-default pull-left">
            立会検査報告書
          </router-link>
        </div>
        <div class="col-md-6">
          <button class="btn btn-lg btn-default mr-8"
              @click="$router.go(-1)">
            戻る
          </button>
          <button class="btn btn-lg btn-info mr-8"
              v-if="isDraft" @click="onClickSaveAsDraft">
            下書き保存
          </button>
          <button class="btn btn-lg btn-primary"
              @click="onClickSave">
            保存
          </button>
        </div>
      </template>
      <template v-else>
        <div class="col-md-3">
          <router-link :to="{
            name: previousRouteName,
            params: {id: inspection_report_id},
          }" class="btn btn-lg btn-default pull-left">
            立会検査報告書
          </router-link>
        </div>
        <div class="col-md-6">
          <button class="btn btn-lg btn-default mr-8"
              @click="$router.go(-1)">
            戻る
          </button>
          <router-link v-if="printPageRouteName" :to="{
                name: printPageRouteName,
                query: { id: id },
              }"
              class="btn btn-lg btn-default mr-8"
              :class="{ disabled: isEdited }"
              target="_blank">
            <i class="fa fa-print"></i>
            プレビュー
          </router-link>
          <button class="btn btn-lg btn-primary"
              @click="onClickSave">
            保存
          </button>
        </div>
      </template>
    </div>
    <div class="row" v-if="isNew || isDraft">
      <div class="col-sm-12">
        <div class="memo-about-draft">
          「下書き保存」された報告書は、作成から7日以上経過すると自動的に削除されます。
        </div>
      </div>
    </div>
  </div>
  <div v-if="isHeaderMode" v-show="hasError"
      class="err-msg err-msg-top ta-left">
    <div v-for="(msg, idx) in errorMessages" :key="idx">・{{ msg }}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'detail-header-bar',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    mode: {
      type: String,
      default: 'header',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default() { return {} }
    },
    printPageRouteName: {
      type: String,
      default: '',
    },
    previousRouteName: {
      type: String,
      default: '',
    },
    inspection_report_id: {
      type: [String, Number],
      default: -1,
    },
  },
  computed: {
    isHeaderMode() {
      return this.mode === 'header'
    },
    isFooterMode() {
      return this.mode === 'footer'
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
  },
  data() {
    return {}
  },
  methods: {
    onClickShowReportHeader() {
      this.$emit('click-show-report-header')
    },
    onClickCreate() {
      this.$emit('click-create')
    },
    onClickSave() {
      this.$emit('click-save')
    },
    onClickCreateAsDraft() {
      this.$emit('click-create', { isSaveModeDraft: true })
    },
    onClickSaveAsDraft() {
      this.$emit('click-save', { isSaveModeDraft: true })
    },
  },
}
</script>

<style lang="scss" scoped>
.bar-row {
  position: relative;
}
.top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.err-msg-top {
  padding: 12px 10mm;
  margin: 0 auto;
}
.err-msg-bottom {
  padding: 0 10mm 12px;
  margin: 0 auto;
}
.memo-about-photo1 {
  &:before {
    content: '※';
    position: absolute;
    top: -3px;
    left: -12px;
    font-size: 1.1em;
  }
  position: absolute;
  top: 2px;
  right: -6px;
  text-align: left;
  width: 200px;
  font-size: 1.1em;
}
.memo-about-draft {
  &:before {
    content: '※';
    position: relative;
    top: -1px;
    left: 5px;
    font-size: 1.1em;
  }
  margin-top: 15px;
  text-align: center;
  font-size: 1.1em;
}
.mode-draft {
  text-align: center;
  padding: 0.5em 1em;
  margin: 2em 0;
  font-weight: bold;
  color: #c05d62;
  background: #FFF;
  border: solid 3px #c05d62;
  border-radius: 10px;
}
</style>
