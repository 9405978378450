<template src="./template.html"></template>

<script>
import Vue from 'vue'
import insideWorkContentApi from '@/api/insideWorkContent'
import masterMixin from '@/mixin/masterMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'masterDetailInsideWorkContent',
  data() {
    return {
      offices: [],
      officeMap: {},

      search: {
        officeId: null,
      },

      list: [],

      showCreateModal: false,
      createEntry: {},

      showDeleteConfirmModal: false,
      deleteEntry: {},

      errors: {},

      masterListMaxHeight: 2000, // 適当
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const acc = []
      const tmpMap = {}
      const ret = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      acc.forEach(e => {
        if (tmpMap[e]) { return }
        tmpMap[e] = true
        ret.push(e)
      })
      return ret
    },
  },
  async mounted() {
    await this.waitForMasters()
    this.prepareMasters()
    this.prepareSearchParams()
    await this.doSearch()
    this.setMasterListMaxHeight()
    window.addEventListener('resize', this.setMasterListMaxHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setMasterListMaxHeight)
  },
  mixins: [masterMixin, errorHandleMixin],
  methods: {
    setMasterListMaxHeight() {
      const windowH = window.innerHeight
      const masterListOffsetTop = this.$refs.masterList.offsetTop
      const masterListOffsetBottom = 20
      this.masterListMaxHeight = windowH - masterListOffsetTop - masterListOffsetBottom
    },
    prepareMasters() {
      this.offices = window.master.offices
      this.officeMap = this.offices.reduce((acc, e) => {
        acc[e.id] = e; return acc
      }, {})
    },
    prepareSearchParams() {
      let officeId = null

      const storage = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        officeId: officeId,
      }

      if (storage.officeId) {
        officeId = storage.officeId
      }

      this.search = {
        officeId: officeId,
      }
    },
    async doSearch() {
      const storage = {
        officeId: this.search.officeId,
      }
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))

      this.list = []
      try {
        const reqObj = {
          office_id: this.search.officeId
        }
        const { data } = await insideWorkContentApi.index(reqObj)

        this.list = data.map(e => this.convData(e))
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    convData(dataOrig) {
      const dataConv = { ...dataOrig }

      const office = this.officeMap[dataOrig.office_id]
      dataConv.office_name = office ? office.name : ''

      dataConv.isEditMode = false

      return dataConv
    },
    clearErrors() {
      this.errors = {}
    },
    checkEntry(entry) {
      this.clearErrors()
      let ret = true

      if (isNaN(entry.disp_order_edit)) {
        this.errors['disp_order'] = ['表示順には数値を入力してください']
        ret = false
      }

      if (!entry.name_edit) {
        this.errors['name'] = ['作業内容は必須項目です']
        ret = false
      }
      if (!entry.office_id_edit) {
        this.errors['office_id'] = ['事務所名は必須項目です']
        ret = false
      }

      return ret
    },
    reOrderList() {
      this.list = this.list.sort((a, b) => {
        const v1 = a.disp_order
        const v2 = b.disp_order
        return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0)
      })
    },
    showAddEntryModal() {
      this.clearErrors()
      this.createEntry = {
        id: null,
        name: '',
        office_id: null,
        disp_order: '',
      }
      this.showCreateModal = true
    },
    getReqObj(entry) {
      const reqObj = {}
      reqObj.id = entry.id
      reqObj.name = entry.name_edit
      reqObj.office_id = entry.office_id_edit
      reqObj.disp_order = parseInt(entry.disp_order_edit)

      return reqObj
    },
    async doAddEntry() {
      if (!this.checkEntry(this.createEntry)) { return }

      try {
        const reqObj = this.getReqObj(this.createEntry)
        const { data } = await insideWorkContentApi.create(reqObj)
        const dataConv = this.convData(data)
        this.list.push(dataConv)
        this.reOrderList()
        this.showCreateModal = false
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    startEditEntry(entry) {
      this.clearErrors()
      entry.name_edit = entry.name
      entry.office_id_edit = entry.office_id
      entry.disp_order_edit = entry.disp_order
      entry.isEditMode = true
    },
    async saveEntry(entry, i) {
      if (!this.checkEntry(entry)) { return }

      try {
        const reqObj = this.getReqObj(entry)
        const { data } = await insideWorkContentApi.update(reqObj.id, reqObj)
        const dataConv = this.convData(data)
        this.endEditEntry(dataConv)
        Vue.set(this.list, i, dataConv)
        this.reOrderList()
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    endEditEntry(entry) {
      entry.isEditMode = false
    },
    dismissAddEntryModal() {
      this.clearErrors()
      this.createEntry = {}
      this.showCreateModal = false
    },
    confirmDeleteEntry(entry) {
      this.clearErrors()
      this.deleteEntry = entry
      this.showDeleteConfirmModal = true
    },
    async doDeleteEntry() {
      try {
        await insideWorkContentApi.delete(this.deleteEntry.id)
        const idx = this.list.findIndex(e => e.id === this.deleteEntry.id)
        if (idx !== -1) {
          Vue.delete(this.list, idx)
        }
        this.deleteEntry = {}
        this.showDeleteConfirmModal = false
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    dismissDeleteConfirmModal() {
      this.clearErrors()
      this.deleteEntry = {}
      this.showDeleteConfirmModal = false
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
