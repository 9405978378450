var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-header-bar ta-center",class:{
      'top-bar': _vm.isHeaderMode,
      'bottom-bar': _vm.isFooterMode,
    }},[(_vm.isFooterMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-bottom ta-left"},_vm._l((_vm.errorMessages),function(msg,idx){return _c('div',{key:idx},[_vm._v("・"+_vm._s(msg))])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bar-row"},[(_vm.isDraft)?_c('div',{staticClass:"mode-draft"},[_vm._v("下書き")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[(_vm.isNew)?[_c('div',{staticClass:"col-md-3"}),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n            戻る\n          ")]),_vm._v(" "),(_vm.isNew)?_c('button',{staticClass:"btn btn-lg btn-info mr-8",on:{"click":_vm.onClickCreateAsDraft}},[_vm._v("\n            下書き保存\n          ")]):_vm._e(),_vm._v(" "),(_vm.isNew)?_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickCreate}},[_vm._v("\n            作成\n          ")]):_vm._e()]),_vm._v(" "),(_vm.isNew)?_c('div',{staticClass:"memo-about-photo1"},[_vm._v("\n          写真は一度報告書を保存した後に追加できます\n        ")]):_vm._e()]:(_vm.isDraft)?[_c('div',{staticClass:"col-md-3"},[_c('router-link',{staticClass:"btn btn-lg btn-default pull-left",attrs:{"to":{
            name: _vm.previousRouteName,
            params: {id: _vm.inspection_report_id},
          }}},[_vm._v("\n            立会検査報告書\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n            戻る\n          ")]),_vm._v(" "),(_vm.isDraft)?_c('button',{staticClass:"btn btn-lg btn-info mr-8",on:{"click":_vm.onClickSaveAsDraft}},[_vm._v("\n            下書き保存\n          ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickSave}},[_vm._v("\n            保存\n          ")])])]:[_c('div',{staticClass:"col-md-3"},[_c('router-link',{staticClass:"btn btn-lg btn-default pull-left",attrs:{"to":{
            name: _vm.previousRouteName,
            params: {id: _vm.inspection_report_id},
          }}},[_vm._v("\n            立会検査報告書\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n            戻る\n          ")]),_vm._v(" "),(_vm.printPageRouteName)?_c('router-link',{staticClass:"btn btn-lg btn-default mr-8",class:{ disabled: _vm.isEdited },attrs:{"to":{
                name: _vm.printPageRouteName,
                query: { id: _vm.id },
              },"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v("\n            プレビュー\n          ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickSave}},[_vm._v("\n            保存\n          ")])],1)]],2),_vm._v(" "),(_vm.isNew || _vm.isDraft)?_c('div',{staticClass:"row"},[_vm._m(0)]):_vm._e()]),_vm._v(" "),(_vm.isHeaderMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-top ta-left"},_vm._l((_vm.errorMessages),function(msg,idx){return _c('div',{key:idx},[_vm._v("・"+_vm._s(msg))])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"memo-about-draft"},[_vm._v("\n          「下書き保存」された報告書は、作成から7日以上経過すると自動的に削除されます。\n        ")])])}]

export { render, staticRenderFns }