// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import * as filters from '@/filter'
import App from '@/components/App/App'

import {
  calendarDefaults,
  calendarLangFunc
} from '@/consts/calendar'
import FullCalendar from 'vue-full-calendar'

Vue.use(FullCalendar)

Vue.config.productionTip = false

// consts
window.VueCalendarDefaults = calendarDefaults
window.VueCalendarLang = calendarLangFunc

// register filters
Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

// register global components
Vue.component('my-calendar', require('@/components/lib/MyCalendar').default)
Vue.component('pager', require('@/components/lib/Pager').default)
Vue.component('modal', require('@/components/lib/Modal').default)
Vue.component('date-time-input', require('@/components/lib/DateTimeInput').default)
Vue.component('time-input', require('@/components/lib/TimeInput').default)
Vue.component('text-area', require('@/components/lib/TextArea').default)
Vue.component('limited-textarea', require('@/components/lib/LimitedTextArea').default)
Vue.component('auto-complete', require('@/components/lib/AutoComplete').default)
Vue.component('photo-selector', require('@/components/lib/PhotoSelector').default)
Vue.component('photo-editor', require('@/components/lib/PhotoEditor').default)
Vue.component('detail-photos', require('@/components/lib/detailPhotos/DetailPhotos').default)
// Vue.component('construction', require('@/components/lib/Construction'))
// Vue.component('office', require('@/components/lib/Office'))

Vue.component('quill-editor', require('@/components/lib/QuillEditor').default)
Vue.component('http-img', require('@/components/lib/HttpImg').default)
Vue.component('http-link', require('@/components/lib/HttpLink').default)
Vue.component('advanced-search-form', require('@/components/lib/AdvancedSearchForm').default)
Vue.component('enlargeable-image', require('@/components/lib/EnlargeableImage').default)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  components: { App },
  render: h => h(App),
}).$mount('#app')
