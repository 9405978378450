import Vue from 'vue'

export function validateMonthRange(dtStrFrom, dtStrTo, months) {
  const dtFormat = Vue.filter('dtFormat')
  const dtFrom = new Date(dtFormat(dtStrFrom, 'yyyy/mm/dd'))
  const dtTo = new Date(dtFormat(dtStrTo, 'yyyy/mm/dd'))
  if (dtTo - dtFrom <= 86400 * months * 30 * 1000) {
    return true
  }
  return false
}
