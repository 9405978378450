import axios from 'axios'
import * as utils from './apiUtils'

export default {
  show({ officeId, data }) {
    return axios.get(utils.getApiUrl(`/offices/${officeId}/work_summary_report_entries`), {
      params: data || {}
    })
  },
  suggestion({ data }) {
    return axios.get(utils.getApiUrl('/work_summary_report_entries/suggestion'), {
      params: data || {}
    })
  },
  update({ officeId, data }) {
    return axios.put(utils.getApiUrl(`/offices/${officeId}/work_summary_report_entries`), data)
  },
  delete({ officeId, data }) {
    return axios.delete(utils.getApiUrl(`/offices/${officeId}/work_summary_report_entries`), {
      params: data || {}
    })
  },
}
