import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/complaint_handling_reports'), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl(`/complaint_handling_reports`), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/complaint_handling_reports/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/complaint_handling_reports/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/complaint_handling_reports/${id}`))
  },
  addPhoto({ id, formData }) {
    return axios.post(utils.getApiUrl(`/complaint_handling_reports/${id}/photos`), formData)
  },
  updatePhoto({ id, photo }) {
    return axios.put(utils.getApiUrl(`/complaint_handling_reports/${id}/photos/${photo.id}`), photo)
  },
  deletePhoto({ id, photo }) {
    return axios.delete(utils.getApiUrl(`/complaint_handling_reports/${id}/photos/${photo.id}`))
  },
}
