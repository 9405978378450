<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import inspectionReportApi from '@/api/inspectionReport'
import correctionReportApi from '@/api/correctionReport'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import dateMixin from '@/mixin/dateMixin'
import textAreaMixin from '@/mixin/textAreaMixin'
import userMixin from '@/mixin/userMixin'
import HeaderBar from '@/components/correctionReport/CorrectionReportDetail/HeaderBar'

export default {
  name: 'correctionReportDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      isNew: true,
      isEdited: false,
      nextPath: '',
      inspection_report_id: this.$route.params.inspection_report_id,

      report: null,
      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      showConfirmBeforeLeaveModal: false,
      errors: {},

      isSaveModeDraft: false,
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    hasBeforeCorrectionPhotos() {
      return this.report.correction_content_before_photos.length > 0
    },
    hasAfterCorrectionPhotos() {
      return this.report.correction_content_after_photos.length > 0
    },
    canEditSerialNumber() {
      if (this.isNew) { return false }
      // もしかしたら、権限がないと編集できないようにするかも??
      return true
    },
  },
  mounted() {
    this.waitForUser().then(() => {
      if (this.$route.name === 'correctionReportNew') {
        this.isNew = true
        this.prepareNewReport_()
      } else {
        this.isNew = false
        this.getReport_()
      }
    })
    window.addEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next()
      return
    }
    this.nextPath = to.path
    this.showConfirmBeforeLeaveModal = true
    next(false)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  mixins: [dateMixin, errorHandleMixin, validatorMixin, textAreaMixin, userMixin],
  methods: {
    prepareNewReport_() {
      const today = new Date()
      this.report = {
        inspection_report: {construction: {}},
        correction_point: '',
        construction_summary: '',
        correction_content: '',
        before_correction_content: '',
        after_correction_content: '',
        occurrence_cause: '',
        repair_plan: '',
        improvement_plan: '',
        correction_content_before_photos: [],
        correction_content_after_photos: [],
        nendo: '',
        created_at: today,
        is_draft: false,
      }

      const obj = {
        id: this.inspection_report_id,
      }
      inspectionReportApi.show(obj)
        .then(({ data }) => {
          this.report.inspection_report = data
          const inspectionDate = new Date(data.inspection_date)
          let nendoYear = inspectionDate.getFullYear()
          if (inspectionDate.getMonth() + 1 < 4) {
            nendoYear -= 1
          }
          this.report.nendo = nendoYear
          this.report.construction_summary = data.construction.construction_summary
        })
    },
    convReport_(data) {
      // nothing to do
      return data
    },
    getReport_() {
      const obj = {
        inspection_report_id: this.inspection_report_id,
        id: this.id
      }
      correctionReportApi.show(obj)
        .then(({ data }) => {
          if (!this.isEditable(data)) { return }
          this.report = this.convReport_(data)
        })
    },
    isEditable(report) {
      if (this.user.is_sagyou) {
        return !!report.inspection_report.inspectors.find(inspector => inspector.id === this.user.id)
      }
      if (this.user.is_shunin || this.user.is_soukatsu) {
        return report.inspection_report.inspector_office_id === this.user.office_id
      }
      if (this.user.is_admin) {
        return true
      }
      return false
    },
    textAreaMinHeight(photos) {
      let rows = photos.length
      rows = parseInt(Math.max(1, rows))
      return (42 * rows).toString() + 'mm'
    },
    onEdit() {
      if (this.isEdited) { return }
      this.isEdited = true
    },
    onSerialNumberInput() {
      let serialNumber = parseInt(this.report.serial_number)
      if (isNaN(serialNumber)) { serialNumber = null }
      this.report.serial_number = serialNumber
    },
    setReportSaveMode(isSaveModeDraft) {
      this.isSaveModeDraft = isSaveModeDraft
    },
    checkInputs({ isNew }) {
      this.errors = {}

      let validateArr = {}
      if (isNew) {
        validateArr = {
          correction_point: '指摘事項',
          construction_summary: '工事概要',
          correction_content: '是正内容',
          before_correction_content: '是正前',
        }
      } else {
        validateArr = {
          serial_number: '報告書No.',
          correction_point: '指摘事項',
          construction_summary: '工事概要',
          correction_content: '是正内容',
          before_correction_content: '是正前',
        }
      }

      if (this.isSaveModeDraft) {
        // 下書き保存時の必須項目
        const required = [
          'serial_number',
        ]
        // それ以外は除外する
        for (const k in validateArr) {
          if (required.includes(k)) { continue }
          delete validateArr[k]
        }
      }

      for (const column in validateArr) {
        const val = this.report[column]
        if (!val) {
          this.errors[column] = [validateArr[column] + 'は必須項目です']
        }
      }
      return Object.keys(this.errors).length === 0
    },
    getReportForReq() {
      const report = JSON.parse(JSON.stringify(this.report))
      // 下書き保存
      report.is_draft = this.isSaveModeDraft

      return report
    },
    async createReport({ isSaveModeDraft } = { isSaveModeDraft: false }) {
      this.setReportSaveMode(isSaveModeDraft)

      if (!this.checkInputs({isNew: true})) { return }

      const reportForReq = this.getReportForReq()
      const obj = {
        inspection_report_id: this.inspection_report_id,
        data: reportForReq,
      }
      try {
        const { data } = await correctionReportApi.create(obj)
        const inspectionReport = this.report.inspection_report
        this.report = this.convReport_(data)
        this.report.inspection_report = inspectionReport

        this.isEdited = false
        this.nextPath = ''
        this.showCreateCompleteModal = true

      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    async saveReport({ isSaveModeDraft } = { isSaveModeDraft: false }) {
      this.setReportSaveMode(isSaveModeDraft)

      if (!this.checkInputs({isNew: false})) {
        return
      }

      const reportForReq = this.getReportForReq()
      const obj = {
        inspection_report_id: this.inspection_report_id,
        id: this.report.id,
        data: reportForReq,
      }
      try {
        await correctionReportApi.update(obj)
        await this.getReport_()
        this.showUpdateCompleteModal = true
        this.isEdited = false
        this.nextPath = ''
      } catch (err) {
        this.handleErrorResponse(err)
      }
    },
    closeCreateCompleteModal() {
      this.$router.replace({
        name: 'correctionReportDetail',
        params: {
          inspection_report_id: this.inspection_report_id,
          id: this.report.id,
        },
      })
      this.showCreateCompleteModal = false
      window.setTimeout(() => {
        this.isNew = false
        this.getReport_()
      }, 0)
    },
    confirmBeforeUnload(e) {
      if (this.isEdited) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    closeConfirmBeforeLeaveModal() {
      this.isEdited = false
      this.$router.push(this.nextPath)
      this.nextPath = ''
      this.showConfirmBeforeLeaveModal = false
    },
  },
  components: { HeaderBar }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
