export default {
  methods: {
    isDateString(str) {
      return !isNaN(new Date(str))
    },
    setDateProp(obj, prop, dt) {
      if (!dt) { return }
      dt = new Date(dt)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      const dtD = dt.getDate()
      obj[prop] = new Date(dtY, dtM, dtD, 0, 0, 0)
    },
    setDateTimeProp(obj, prop, dt) {
      if (!dt) { return }
      dt = new Date(dt)
      const dtY = dt.getFullYear()
      const dtM = dt.getMonth()
      const dtD = dt.getDate()
      const dtHour = dt.getHours()
      const dtMinute = dt.getMinutes()
      obj[prop] = new Date(dtY, dtM, dtD, dtHour, dtMinute, 0)
    },
  }
}
