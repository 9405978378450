<template src="./template.html"></template>

<script>
import workSummaryReportApi from '@/api/workSummaryReport'
import officeApi from '@/api/office'
import textAreaMixin from '@/mixin/textAreaMixin'

export default {
  name: 'printWorkSummaryReportEntry',
  props: {
    id: {
      type: [String, Array],
      required: true,
    },
    office_id: {
      type: [String, Number],
      required: true,
    },
    pattern_id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      reports: [],
      max_columns_number: 4,
      outside_work_max_lines: 8,
      inside_work_max_lines: 4,
      other_work_max_lines: 4,
      office_name: '',
      print_pattern: null,
    }
  },
  mounted() {
    this.addGlobalPageStyle('A4 landscape')
    const promises = [
      this.getOffice_(),
      this.getReport_(),
    ]
    Promise.all(promises).then(() => {
      this.filterReports_()
      this.reports.forEach(report => {
        report.pages = this.convReport_(report.work_summary_report_entries)
      })
    })
  },
  mixins: [textAreaMixin],
  methods: {
    addGlobalPageStyle(sizeStr) {
      const styleNode = document.createElement('style')
      styleNode.appendChild(document.createTextNode(`@page { size: ${sizeStr}; }`))
      document.querySelector('head').appendChild(styleNode)
    },
    getOffice_() {
      const obj = { id: this.office_id }
      return officeApi.show(obj)
        .then(({ data }) => {
          data = this.parseJson_(data)
          this.outside_work_max_lines = data.work_summary_format[0]
          this.other_work_max_lines = data.work_summary_format[2]
          this.office_name = data.name
          this.print_pattern = data.office_work_summary_patterns.find(pattern => {
            return pattern.id === parseInt(this.pattern_id)
          })
        })
    },
    getReport_() {
      const obj = {
        ids: [this.id].flat(),
      }
      return workSummaryReportApi.get_by_ids(obj)
        .then(({ data }) => {
          this.reports = data
        })
    },
    filterReports_() {
      this.reports = this.reports.map(report => {
        if (this.print_pattern) {
          report.chief_supervisor = this.print_pattern.chief_supervisor
          report.management_engineer = this.print_pattern.management_engineer

          const tmpMap =
            this.print_pattern.inspector_ids.reduce((acc, id, i) => {
              acc[id] = i; return acc
            }, {})
          report.work_summary_report_entries =
            report.work_summary_report_entries.filter(entry => {
              return tmpMap.hasOwnProperty(entry.inspector_id)
            }).sort((a, b) => {
              return tmpMap[a.inspector_id] < tmpMap[b.inspector_id] ? -1 : 1
            })
        }
        return report
      })
    },
    convReport_(data) {
      var ret = []
      var arr = []
      Object.keys(data).forEach(i => {
        let key = parseInt(i) % this.max_columns_number
        if (key === 0) {
          arr = []
        }
        data[i].index = key
        arr.push(data[i])
        if (parseInt(i) === data.length - 1) {
          for (let n = 0; n < this.max_columns_number - key - 1; n++) {
            arr.push({
              is_append: true,
              outside_work_content_am: '',
              outside_work_content_pm: '',
              inside_work_content_am: '',
              inside_work_content_pm: '',
              other_work_content: ''
            })
          }
        }
        if (key === this.max_columns_number - 1 ||
          parseInt(i) === data.length - 1
        ) {
          ret.push(arr)
        }
      })
      if (ret.length > 2) {
        this.has_next = true
      }
      return ret
    },
    parseJson_(data) {
      try {
        // 配列に変換
        data.work_summary_format =
          JSON.parse(data.work_summary_format) || [8, 4, 4]
      } catch (e) {
        // デフォルト値をセット
        data.work_summary_format = [8, 4, 4]
      }

      data.office_work_summary_patterns =
        data.office_work_summary_patterns.map(pattern => {
          try {
            // 配列に変換
            pattern.inspector_ids = JSON.parse(pattern.inspector_ids) || []
          } catch (e) {
            // 仕方ないので初期化
            pattern.inspector_ids = []
          }
          return pattern
        })

      return data
    },
    sortOrderAsc() {
      this.reports.sort((a, b) => {
        const m1 = a.work_summary_date.match(/(\d{4})-(\d{2})-(\d{2})/)
        const v1 = m1 ? new Date(parseInt(m1[1]), parseInt(m1[2]) - 1, parseInt(m1[3])) : new Date(0, 0, 0)
        const m2 = b.work_summary_date.match(/(\d{4})-(\d{2})-(\d{2})/)
        const v2 = m2 ? new Date(parseInt(m2[1]), parseInt(m2[2]) - 1, parseInt(m2[3])) : new Date(0, 0, 0)
        return v1 < v2 ? -1 : 1
      })
    },
    sortOrderDesc() {
      this.sortOrderAsc()
      this.reports.reverse()
    },
    showPrintDialog() {
      window.print()
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
