<template>
<div class="detail-header-bar"
    :class="{
      'top-bar': isHeaderMode,
      'bottom-bar': isFooterMode,
    }">
  <div v-if="isFooterMode" v-show="hasError"
      class="err-msg err-msg-bottom ta-left">
    <div v-for="(msg, idx) in errorMessages" :key="idx">・{{ msg }}</div>
  </div>
  <div class="bar-row">
    <div class="mode-draft" v-if="isDraft">下書き</div>
    <div class="row">
      <template v-if="isNew">
        <div class="col-sm-12">
          <div class="ta-center">
            <button class="btn btn-lg btn-default mr-8"
                @click="$router.go(-1)">
              戻る
            </button>
            <button
                class="btn btn-lg btn-info mr-8"
                @click="onClickCreateAsDraft">
              下書き保存
            </button>
            <button class="btn btn-lg btn-primary" @click="onClickCreate">
              保存
            </button>
            <div class="memo-about-photo1">
              写真は一度報告書を保存した後に追加できます
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="isDraft">
        <div class="col-sm-3">
        </div>
        <div class="col-sm-5">
          <div class="ta-center">
            <button class="btn btn-lg btn-default mr-8"
                @click="$router.go(-1)">
              戻る
            </button>
            <button class="btn btn-lg btn-info mr-8"
                @click="onClickSaveAsDraft">
              下書き保存
            </button>
            <button class="btn btn-lg btn-primary" @click="onClickSave">
              保存
            </button>
          </div>
        </div>
        <div class="col-sm-4">
          <router-link :to="{
            name: photoPageRouteName,
            params: {id: id},
          }" class="btn btn-lg btn-default pull-right">
            <i class="fa fa-photo"></i>
            写真
            <span class="badge badge-num" v-if="photoNum > 0">
              {{photoNum}}
            </span>
          </router-link>
          <router-link :to="{
            name: DocumentPageRouteName,
            params: {id: id},
          }" class="btn btn-lg btn-default pull-right" style="margin-right:8px;">
            <i class="fa fa-file"></i>
            書類
            <span class="badge badge-num" v-if="documentNum > 0">
              {{documentNum}}
            </span>
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="col-sm-3">
            <router-link :to="{
              name:'inspectionReportCorrectionReportList',
              params: {inspection_report_id: id},
            }" class="btn btn-lg btn-default">
              指摘事項報告書
            </router-link>
        </div>
        <div class="col-sm-5">
          <div class="ta-center">
            <button class="btn btn-lg btn-default mr-8"
                @click="$router.go(-1)">
              戻る
            </button>
            <router-link :to="{
                name: printPageRouteName,
                query: { id: id },
                }"
                class="btn btn-lg btn-default mr-8"
                :class="{ disabled: isEdited }"
                target="_blank" >
              <i class="fa fa-print"></i>
              プレビュー
            </router-link>
            <button class="btn btn-lg btn-primary" @click="onClickSave">
              保存
            </button>
          </div>
        </div>
        <div class="col-sm-4">
          <router-link :to="{
            name: photoPageRouteName,
            params: {id: id},
          }" class="btn btn-lg btn-default pull-right">
            <i class="fa fa-photo"></i>
            写真
            <span class="badge badge-num" v-if="photoNum > 0">
              {{photoNum}}
            </span>
          </router-link>
          <router-link :to="{
            name: DocumentPageRouteName,
            params: {id: id},
          }" class="btn btn-lg btn-default pull-right" style="margin-right:8px;">
            <i class="fa fa-file"></i>
            書類
            <span class="badge badge-num" v-if="documentNum > 0">
              {{documentNum}}
            </span>
          </router-link>
        </div>
      </template>
    </div>
    <div class="row" v-if="isNew || isDraft">
      <div class="col-sm-12">
        <div class="memo-about-draft">
          「下書き保存」された報告書は、作成から7日以上経過すると自動的に削除されます。
        </div>
      </div>
    </div>
  </div>
  <div v-if="isHeaderMode" v-show="hasError"
      class="err-msg err-msg-top ta-left">
    <div v-for="(msg, idx) in errorMessages" :key="idx">・{{ msg }}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'header-bar',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    mode: {
      type: String,
      default: 'header',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default() { return {} }
    },
    printPageRouteName: {
      type: String,
      required: true,
    },
    photoPageRouteName: {
      type: String,
      required: true,
    },
    DocumentPageRouteName: {
      type: String,
      default() { return null }
    },
    photoNum: {
      type: Number,
      required: true,
    },
    documentNum: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isHeaderMode() {
      return this.mode === 'header'
    },
    isFooterMode() {
      return this.mode === 'footer'
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const messages = Object.values(this.errors).reduce((acc, e) => {
        if (Array.isArray(e)) {
          acc = acc.concat(e)
        } else {
          acc.push(e)
        }
        return acc
      }, [])
      return Array.from(new Set(messages))
    },
  },
  data() {
    return {}
  },
  methods: {
    onClickCreate() {
      this.$emit('click-create')
    },
    onClickSave() {
      this.$emit('click-save')
    },
    onClickCreateAsDraft() {
      this.$emit('click-create', { isSaveModeDraft: true })
    },
    onClickSaveAsDraft() {
      this.$emit('click-save', { isSaveModeDraft: true })
    },
  },
}
</script>

<style lang="scss" scoped>
.bar-row {
  position: relative;
}
.top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.err-msg-top {
  padding: 12px 10mm;
  margin: 0 auto;
}
.err-msg-bottom {
  padding: 0 10mm 12px;
  margin: 0 auto;
}
.memo-about-photo1 {
  &:before {
    content: '※';
    position: absolute;
    top: -3px;
    left: -12px;
    font-size: 1.1em;
  }
  position: absolute;
  top: 2px;
  right: 6px;
  text-align: left;
  width: 200px;
  font-size: 1.1em;
}
.memo-about-draft {
  &:before {
    content: '※';
    position: relative;
    top: -1px;
    left: 5px;
    font-size: 1.1em;
  }
  margin-top: 15px;
  text-align: center;
  font-size: 1.1em;
}
.mode-draft {
  text-align: center;
  padding: 0.5em 1em;
  margin: 2em 0;
  font-weight: bold;
  color: #c05d62;
  background: #FFF;
  border: solid 3px #c05d62;
  border-radius: 10px;
}

.badge-num {
  background-color: #ed5565;
  margin-bottom: 2px;
}
</style>
