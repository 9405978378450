import Vue from 'vue'
import userApi from '@/api/user'

export default {
  methods: {
    loadUsers() {
      window.users = {
        $promise: userApi.getAll()
      };
      window.users.$promise.then(({ data }) => {
        window.users = data;
        window.users.$promise = prms
      })
    },
    async waitForUsers(arg) {
      await window.users.$promise
      return arg
    },
    async waitForUser(arg) {
      await this.$store.dispatch('user/getMe')
      return arg
    },
  }
}
