<template src="./template.html"></template>

<script>
import Vue from 'vue'
import correctionReportApi from '@/api/correctionReport'
import textAreaMixin from '@/mixin/textAreaMixin'

export default {
  name: 'printInspectionReport',
  props: [ 'id' ],
  mixins: [textAreaMixin],
  data() {
    return {
      reports: [],
    }
  },
  mounted() {
    this.addGlobalPageStyle('A4 portrait')
    this.getReport_()
  },
  methods: {
    addGlobalPageStyle(sizeStr) {
      const styleNode = document.createElement('style')
      styleNode.appendChild(document.createTextNode(`@page { size: ${sizeStr}; }`))
      document.querySelector('head').appendChild(styleNode)
    },
    getReport_() {
      return correctionReportApi.get_by_ids({ ids: [this.id].flat() })
        .then(({ data }) => {
          this.reports = data.map(report => this.convData(report)).flat()
        })
    },
    convData(report) {
      const htmlSplitter1 = '<br/>'
      let rows
      // 指示事項
      rows = []
      rows.push(Vue.filter('dtFormatYMDA1')(report.inspection_report.inspection_date))
      rows.push(...(this.replaceReturn(report.correction_point) || '').split(htmlSplitter1))
      report.correctionPointHtmlRows = rows
      // 工事概要
      rows = []
      rows.push(`工事件名：${report.inspection_report.construction.name}`)
      rows.push(`受注者名：${report.inspection_report.construction.orderer_name}`)
      rows.push(...(this.replaceReturn(report.construction_summary) || '').split(htmlSplitter1))
      report.constructionSummaryHtmlRows = rows
      // 是正内容
      report.correctionContentHtmlRows =
        (this.replaceReturn(report.correction_content) || '').split(htmlSplitter1)
      // 是正前
      report.beforeCorrectionContentHtmlRows =
        (this.replaceReturn(report.before_correction_content) || '').split(htmlSplitter1)
      // 是正前 (写真)
      report.beforeCorrectionContentPhotos =
        report.correction_content_before_photos
      // 是正後
      report.afterCorrectionContentHtmlRows =
        (this.replaceReturn(report.after_correction_content) || '').split(htmlSplitter1)
      // 是正後 (写真)
      report.afterCorrectionContentPhotos =
        report.correction_content_after_photos
      // 発生要因
      report.occurrenceCauseHtmlRows =
        (this.replaceReturn(report.occurrence_cause) || '').split(htmlSplitter1)
      // 補修方法
      report.repairPlanHtmlRows =
        (this.replaceReturn(report.repair_plan) || '').split(htmlSplitter1)
      // 改善方法
      report.improvementPlanHtmlRows =
        (this.replaceReturn(report.improvement_plan) || '').split(htmlSplitter1)

      // 改ページ
      const pages = this.doPageBreak(report)

      return pages
    },
    getPhotoProp(propInfo) {
      return propInfo.prop.replace(/HtmlRows$/, 'Photos')
    },
    getEstimatedFullWidthLetterCount(row) {
      // タグがあったら取る
      row = row
        .replace(/<("[^"]*"|'[^']*'|[^'">])+>/g, '')
        .replace(/<\/[^'">]>/g, '')
      let ret = 0
      for (let i = 0, len = row.length; i < len; i++) {
        // 半角は0.5文字、全角は1.0文字として計算
        const asciiCode = row.charCodeAt(i)
        ret += asciiCode < 128 ? 0.5 : 1.0
      }
      return ret
    },
    getEstimatedDispRows(row, maxLetterLengthInRow) {
      // 全角文字換算の文字数をざっくり算出する
      const letterCnt = this.getEstimatedFullWidthLetterCount(row)
      // 表示上の行数をざっくり算出する (ここでページまたぎしてしまうと
      // 改ページがうまくいかないのだが、そこはユーザーに調整してもらおう)
      return Math.ceil(letterCnt / maxLetterLengthInRow) || 1
    },
    doPageBreak(report) {
      // シート全体の高さ
      const sheetHeight = 1122.5 // 297mm
      // 上側の隙間
      const offsetTop = 94 // 30 + 立会検査報告書のヘッダ
      // 下側の隙間
      const offsetBottom = 30
      // ハコの縦padding
      const cellPadding = 4
      // 行のborder-bottom
      const borderBottom = 1
      // 指摘事項のハコの上端のシート上端からのoffset.
      // 可変行もあるので正確ではないが、まぁ余裕を持って改行すれば
      // ある程度適当でもよいだろう.
      let firstPageEstimateOffsetTop = 154
      // 文章1行分の高さ
      const htmlRowHeight = 20 // px
      // 1行の最大文字数(全て全角文字前提)
      const numLettersInRow = 38
      // 1行の最大文字数(全て全角文字前提) 写真付きの場合
      const numLettersInRowWithPhoto = 27
      // 写真1行分の高さ
      const photoRowHeight = 240 // px

      const rowPropsArr = this.getRowPropsArr(report)
      // 可変データを引っこ抜く
      const dataMap = {}

      for (const propInfo of rowPropsArr) {
        let arr = report[propInfo.prop] || []
        if (arr.length < propInfo.min) {
          arr = Array.from({length: propInfo.min}, () => '')
        }
        dataMap[propInfo.prop] = arr
        delete report[propInfo.prop]
      }
      const baseReport = JSON.parse(JSON.stringify(report))

      // 残りの余白. 1ページ目は、 指摘事項以下の項目に残されたheight
      let currentUsableHeight = sheetHeight - firstPageEstimateOffsetTop - offsetBottom
      let currentReport = JSON.parse(JSON.stringify(baseReport))
      // 1枚目のみ
      currentReport.isFirstPageOfReport = true
      let pages = []
      for (const propInfo of rowPropsArr) {
        const rows = dataMap[propInfo.prop]
        if (!rows.length && propInfo.type !== 'html') { continue }

        let startIdx = 0
        if (propInfo.withPhoto && rows.length > 0) {
          // 写真付き項目(是正前・是正後)の場合
          const photoProp = this.getPhotoProp(propInfo)
          if (dataMap[photoProp].length > 0) {
            // 写真がある場合は、ページを遡って写真横の余っている行を埋めていく
            const convertedPages = [...pages, currentReport]
            for (const page of convertedPages) {
              if (!page[photoProp]) { continue }

              // 写真の高さから行数を算出
              const maxUsableRows = Math.floor((page[photoProp].length * photoRowHeight) / htmlRowHeight)
              let usableRows = maxUsableRows
              for (; startIdx < maxUsableRows; startIdx++) {
                if (startIdx > rows.length - 1) { break }

                const row = rows[startIdx]
                const estimatedDispRows = this.getEstimatedDispRows(row, numLettersInRowWithPhoto)

                if (usableRows - estimatedDispRows < 0) {
                  currentUsableHeight += htmlRowHeight * usableRows
                  break
                }

                if (!page[propInfo.prop]) { page[propInfo.prop] = [] }
                page[propInfo.prop].push(row)
                usableRows -= estimatedDispRows
              }
            }
          }
        }

        if (propInfo.type === 'html') {
          // 行の上側padding
          currentUsableHeight -= cellPadding
        }
        for (const [idx, row] of rows.entries()) {
          if (idx < startIdx) { continue }

          const rowHeight = propInfo.type === 'html' ? htmlRowHeight : photoRowHeight
          const marginRowHeight = htmlRowHeight

          if (currentUsableHeight - (rowHeight + marginRowHeight) < 0) {
            // 残りスペースが2行分以下になったら改ページ
            if (idx > 0) {
              // 項目の途中で改ページになった場合のみ、なんか表示する
              const showNextPageProp = propInfo.prop.replace(/Rows$/, '') + 'ShowNextPage'
              currentReport[showNextPageProp] = true
            }
            pages.push(currentReport)
            currentReport = JSON.parse(JSON.stringify(baseReport))
            currentUsableHeight = sheetHeight - offsetTop - offsetBottom
          }

          if (!currentReport[propInfo.prop]) { currentReport[propInfo.prop] = [] }
          if (propInfo.type === 'html') {
            let maxLetterLengthInRow = numLettersInRow
            if (propInfo.withPhoto && dataMap[this.getPhotoProp(propInfo)].length > 0) {
              maxLetterLengthInRow = numLettersInRowWithPhoto
            }
            const estimatedDispRows = this.getEstimatedDispRows(row, maxLetterLengthInRow)
            currentReport[propInfo.prop].push(row)
            currentUsableHeight -= rowHeight * estimatedDispRows
          } else {
            currentReport[propInfo.prop].push(row)
            currentUsableHeight -= rowHeight
          }
        }
        if (propInfo.type === 'html') {
          // 行の下側padding
          currentUsableHeight -= cellPadding
          // 行の下側罫線
          currentUsableHeight -= borderBottom
        }
      }
      if (currentReport) {
        pages.push(currentReport)
      }

      const numPages = pages.length
      for (const [idx, page] of pages.entries()) {
        page.pageInfo = {
          pageNum: idx + 1,
          numPages: numPages,
        }
        for (const propInfo of rowPropsArr) {
          const srcProp = propInfo.prop
          if (!page[srcProp]) { continue }
          if (propInfo.type === 'html') {
            const dstProp = srcProp.replace(/Rows$/, '')
            let glue = '<br/>'
            page[dstProp] = page[srcProp].join(glue)
            // 何もないと行ごと消えてしまうのでなんか入れる
            if (!page[dstProp]) { page[dstProp] = ' ' }
          }
        }
      }

      return pages
    },
    getRowPropsArr(report) {
      const ret = []
      const push = (obj) => {
        ret.push(obj)
      }
      // タイプ
      const typeHtml = 'html'
      const typePhoto = 'photo'

      // 指示事項
      push({ prop: 'correctionPointHtmlRows', type: typeHtml, min: 2 })
      // 工事概要
      push({ prop: 'constructionSummaryHtmlRows', type: typeHtml, min: 3 })
      // 是正内容
      push({ prop: 'correctionContentHtmlRows', type: typeHtml, min: 1 })
      // 是正前 (写真)
      push({ prop: 'beforeCorrectionContentPhotos', type: typePhoto, min: 0 })
      // 是正前
      push({ prop: 'beforeCorrectionContentHtmlRows', type: typeHtml, min: 1, withPhoto: true })
      // 是正後 (写真)
      push({ prop: 'afterCorrectionContentPhotos', type: typePhoto, min: 0 })
      // 是正後
      push({ prop: 'afterCorrectionContentHtmlRows', type: typeHtml, min: 1, withPhoto: true })
      // 発生要因
      push({ prop: 'occurrenceCauseHtmlRows', type: typeHtml, min: 1 })
      // 補修方法
      push({ prop: 'repairPlanHtmlRows', type: typeHtml, min: 1 })
      // 改善方法
      push({ prop: 'improvementPlanHtmlRows', type: typeHtml, min: 1 })

      return ret
    },
    existsBeforePhoto(report) {
      return report.correction_content_before_photos.length > 0
    },
    existsAfterPhoto(report) {
      return report.correction_content_after_photos.length > 0
    },
    replaceReturn(str) {
      if (!str) { return }
      return str.replace(/\r?\n/g, '<br/>')
    },
    showPrintDialog() {
      window.print()
    },
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
