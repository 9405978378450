export const yesNo1 = (val) => {
  return val ? 'あり' : 'なし'
}

export const ident = (val) => {
  return val
}

export const lineBreakToBR = (str) => {
  if (!str) { return '' }

  return str
    .replace(/\r\n|\\r\\n/g, '<br>')
    .replace(/\r|\\r/g, '<br>')
    .replace(/\n|\\n/g, '<br>')
}

export const splitByLineBreak = (str) => {
  if (!str) { return [] }
  const token = '#@%;#'
  return str
    .replace(/\r\n|\\r\\n/g, token)
    .replace(/\r|\\r/g, token)
    .replace(/\n|\\n/g, token)
    .split(token)
}
