const dayDispArr = [
  '日', '月', '火', '水', '木', '金', '土'
]

// TODO 「未定」を直す

export function warekiToSeireki(wa, year) {
  year = parseInt(year)
  let baseYear = 1989
  if (wa === '明治') {
    baseYear = 1868
  } else if (wa === '大正') {
    baseYear = 1912
  } else if (wa === '昭和') {
    baseYear = 1926
  } else if (wa === '平成') {
    baseYear = 1989
  } else if (wa === '未定') {
    baseYear = 2019
  }
  return baseYear - 1 + year
}

export function seirekiToWareki(dt, { isKanji }) {
  const year = dt.getFullYear()
  let base = 1
  let wa = ''

  if (dt < new Date(1868, 1 - 1, 25)) {
    // arienai
  } else if (dt < new Date(1912, 7 - 1, 30)) {
    base = 1868
    wa = isKanji ? '明治' : 'M'
  } else if (dt < new Date(1926, 12 - 1, 25)) {
    base = 1912
    wa = isKanji ? '大正' : 'T'
  } else if (dt < new Date(1989, 1 - 1, 8)) {
    base = 1926
    wa = isKanji ? '昭和' : 'S'
  } else if (dt < new Date(2019, 5 - 1, 1)) {
    base = 1989
    wa = isKanji ? '平成' : 'H'
  } else {
    base = 2019
    wa = isKanji ? '未定' : 'Z'
  }

  return {
    gengou: wa,
    year: (year - base + 1)
  }
}

function dateStrToDate(str) {
  if (!str) { return null }
  if (str.match(/^\d{4}[^0-9]\d{2}[^0-9]\d{2}$/)) {
    str = `${str} 00:00:00`
  }
  /*
   * 2018-12-10 12:13:14
   * 2018-12-10 12:13:14Z
   * 2018-12-10 12:13:14+0900
   * 2018-12-10T12:13:14+0900
   * 2018-12-10 12:13:14.000Z
   * 2018-12-10 12:13:14.000+0900
   * 2018-12-10T12:13:14.000+0900
   */
  let m = str.match(/^(\d{4})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})(\.\d{3})?(Z)?([+-]\d{4})?$/)
  if (m) {
    const year = m[1]
    const month = parseInt(m[2]) - 1
    const date = m[3]
    let hours = m[4]
    let minutes = m[5]
    const seconds = m[6]
    const mseconds = m[7] ? m[7].slice(1) : 0
    if (m[8]) {
      return new Date(Date.UTC(year, month, date, hours, minutes, seconds, mseconds))
    } else {
      return new Date(year, month, date, hours, minutes, seconds, mseconds)
    }
  }

  m = str.match(/^([^\d]{2})(\d+)年(\d+)月(\d+)日$/)
  if (m) {
    const year = warekiToSeireki(m[1], parseInt(m[2]))
    return new Date(year, m[3] - 1, m[4])
  }

  return new Date(str.replace(/ /, 'T'))
}

export const ensureDate = (dt) => {
  if (!dt) { return null }

  let ret = dt
  if (typeof dt === 'string') {
    ret = dateStrToDate(dt)
  }
  return ret
}

export const dtFormatY1 = (dt, {isWareki} = false) => {
  dt = ensureDate(dt)
  if (!dt || isNaN(dt.valueOf())) { return '' }

  if (isWareki) {
    const wareki = seirekiToWareki(dt, {isKanji: true})
    return `${wareki.gengou}${wareki.year}年`
  }
  return `${dt.getFullYear()}年`
}

export const dtFormatYMDA1 = (dt, {isWareki} = false) => {
  dt = ensureDate(dt)
  if (!dt || isNaN(dt.valueOf())) { return '' }

  const m = dt.getMonth() + 1
  const d = dt.getDate()
  const youbi = dayDispArr[dt.getDay()]

  if (isWareki) {
    const wareki = seirekiToWareki(dt, {isKanji: true})
    return `${wareki.gengou}${wareki.year}年${m}月${d}日(${youbi})`
  }

  const y = dt.getFullYear()
  return `${y}年${m}月${d}日(${youbi})`
}

export const dtFormatYMDA2 = (dt, {isWareki} = false) => {
  dt = ensureDate(dt)
  if (!dt || isNaN(dt.valueOf())) { return '' }

  const m = dt.getMonth() + 1
  const d = dt.getDate()
  const youbi = dayDispArr[dt.getDay()]
  const hour = dt.getHours()
  const min = ('0' + dt.getMinutes()).slice(-2)

  if (isWareki) {
    const wareki = seirekiToWareki(dt, {isKanji: true})
    return `${wareki.gengou}${wareki.year}年${m}月${d}日(${youbi}) ${hour}:${min}`
  }
  const y = dt.getFullYear()
  return `${y}年${m}月${d}日(${youbi}) ${hour}:${min}`
}

export const dtFormat = (date, format) => {
  date = ensureDate(date)
  if (!date || isNaN(date.valueOf())) { return '' }
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()
  const h = date.getHours()
  const min = date.getMinutes()
  const s = date.getSeconds()
  const wareki = seirekiToWareki(date, {isKanji: true})
  const youbi = dayDispArr[date.getDay()]
  return format
    .replace(/yyyy/g, y)
    .replace(/mm/g, ('0' + m).slice(-2))
    .replace(/m/g, m)
    .replace(/dd/g, ('0' + d).slice(-2))
    .replace(/d/g, d)
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h)
    .replace(/MM/g, ('0' + min).slice(-2))
    .replace(/M/g, min)
    .replace(/SS/g, ('0' + s).slice(-2))
    .replace(/S/g, s)
    .replace(/g/g, `${wareki.gengou}${wareki.year}`)
    .replace(/a/g, youbi)
}

export const tmFormat = (time) => {
  if (time === null || time === undefined) { return '' }
  if (isNaN(time)) { return '' }

  const strTime = '000' + time.toString()
  const hr = strTime.slice(-4, -2)
  const min = strTime.slice(-2)
  return `${hr}:${min}`
}
