<template src="./template.html"></template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import TimeInput from '@/components/lib/TimeInput'
import workSummaryReportEntryApi from '@/api/workSummaryReportEntry'
import constructionApi from '@/api/construction'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import dateMixin from '@/mixin/dateMixin'
import textAreaMixin from '@/mixin/textAreaMixin'
import userMixin from '@/mixin/userMixin'
import workSummaryReportApi from '@/api/workSummaryReport'
export default {
  name: 'WorkSummaryReportDetail',
  data() {
    const now = new Date()
    this.work_summary_date = this.$route.params.date
    if (!this.work_summary_date) {
      this.work_summary_date = Vue.filter('dtFormat')(now, 'yyyy/mm/dd')
    }
    return {
      forceLeavePage: false,
      nextPath: '',
      max_columns_number: 4,
      outside_work_max_lines: 8,
      inside_work_max_lines: 4,
      other_work_max_lines: 4,
      chief_supervisor: '',
      dup_chief_supervisor: '',
      management_engineer: '',
      dup_management_engineer: '',
      print_patterns: [],
      print_pattern: null,
      print_pattern_id: 0,
      disabled: true,
      dt: now,
      work_summary_date: this.work_summary_date,
      errors: {},
      current_page: 0,
      users_suggestion_report: {},
      orig_report: [],
      report: [],
      report_id: '',
      reportHeaderToDelete: null,
      showUsersSuggestionReport: false,
      showUpdateCompleteModal: false,
      showConfirmBeforeLeaveModal: false,
      office_id: this.$route.params.office_id,
      office_name: '',
      constructions: [],
      constructionToCopy: '',
      insideWorkContentToCopy: '',
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const messages = Object.values(this.errors).reduce((acc, e) => {
        if (Array.isArray(e)) {
          acc = acc.concat(e)
        } else {
          acc.push(e)
        }
        return acc
      }, [])
      return Array.from(new Set(messages))
    },
    hasChangedReportHeader() {
      return this.chief_supervisor !== this.dup_chief_supervisor ||
        this.management_engineer !== this.dup_management_engineer
    },
    hasChangedWorkSummaryReport() {
      if (this.hasChangedReportHeader) { return true }
      for (const [pageNum, page] of this.report.entries()) {
        const hasModifiedEntry = page.some((entry, index) => {
          // レイアウト調整のための列(is_append)は見ない
          return !entry.is_append && this.hasModifiedEntry(pageNum, index)
        })
        if (hasModifiedEntry) { return true }
      }
      return false
    },
    hasNext() {
      return this.current_page < this.report.length - 1
    },
    hasPrev() {
      return this.report.length > 0 && this.current_page !== 0
    },
    hasNextWorkSummaryReport() {
      const dtFormat = Vue.filter('dtFormat')
      const today = parseInt(dtFormat(new Date(), 'yyyymmdd'))
      const workSummaryDate = new Date(this.work_summary_date)
      const nextWorkSummaryDate = new Date(workSummaryDate.setDate(workSummaryDate.getDate() + 1))
      const nextDay = parseInt(dtFormat(nextWorkSummaryDate, 'yyyymmdd'))
      return nextDay <= today
    },
    isPreviousWorkSummaryReport() {
      const dtFormat = Vue.filter('dtFormat')
      const today = parseInt(dtFormat(new Date(), 'yyyymmdd'))
      const workSummaryDate = parseInt(dtFormat(this.work_summary_date, 'yyyymmdd'))
      return workSummaryDate < today
    },
    showConfirmDeleteModal() {
      return this.reportHeaderToDelete !== null
    },
    dispChiefSupervisor() {
      return this.print_pattern ? this.print_pattern.chief_supervisor : this.chief_supervisor
    },
    dispManagementEngineer() {
      return this.print_pattern ? this.print_pattern.management_engineer : this.management_engineer
    },
    isAfterUserTransferDate() {
      return !this.user.latest_transfer_date ||
        this.work_summary_date >= this.user.latest_transfer_date
    },
    isMyOffice() {
      return this.user.office_id === parseInt(this.office_id)
    },
    isReportHeaderInputEditable() {
      if (this.print_pattern) { return false }
      return this.has_role_admin ||
        (this.has_role_shunin && this.isMyOffice && this.isAfterUserTransferDate)
    },
  },
  watch: {
    work_summary_date() {
      this.getWorkSummaryReport()
    },
  },
  async mounted() {
    await this.waitForUser()
    if (!this.isReadable()) { return }

    await Promise.all([
      this.getWorkSummaryReport(),
      this.getUserWorkSummaryReport(),
      this.getConstructions(),
    ])
    this.preparePrintPattern()

    window.addEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  beforeRouteLeave(to, from, next) {
    if (this.forceLeavePage || !this.hasChangedWorkSummaryReport) {
      next()
      return
    }
    this.nextPath = to.path
    this.showConfirmBeforeLeaveModal = true
    next(false)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmBeforeUnload, false)
  },
  mixins: [dateMixin, errorHandleMixin, textAreaMixin, userMixin],
  methods: {
    isReadable() {
      const dtFormat = Vue.filter('dtFormat')
      const workSummaryDate = parseInt(dtFormat(this.work_summary_date, 'yyyymmdd'))

      // 年度内は先2週間まで編集可能
      let limitDate = new Date(this.dt.valueOf() + 60 * 60 * 24 * 14 * 1000)
      if (this.dt.getMonth() + 1 === 3 && limitDate.getMonth() + 1 > 3) {
        // 年度をまたぐ場合は年度末日まで
        limitDate = new Date(this.dt.getFullYear(), 3, 0)
      }
      limitDate = parseInt(dtFormat(limitDate, 'yyyymmdd'))

      if (workSummaryDate > limitDate) { return false }

      if (this.user.is_sagyou) {
        return this.isMyOffice
      }
      if (this.user.is_shunin || this.user.is_soukatsu || this.user.is_admin) {
        return true
      }
      return false
    },
    async getWorkSummaryReport() {
      let { data } = await workSummaryReportApi.show({
        officeId: this.office_id,
        data: { work_summary_date: this.work_summary_date }
      })
      if (!data) { return }
      data = this.parseJson_(data)
      this.report_id = data.id
      const office = data.inspector_office
      this.office_name = office.name
      this.outside_work_max_lines = office.work_summary_format[0]
      this.inside_work_max_lines = office.work_summary_format[1]
      this.other_work_max_lines = office.work_summary_format[2]
      this.print_patterns = office.office_work_summary_patterns
      this.dup_management_engineer = data.management_engineer
      this.dup_chief_supervisor = data.chief_supervisor
      this.management_engineer = data.management_engineer
      this.chief_supervisor = data.chief_supervisor
      this.orig_report = data.work_summary_report_entries
      this.report = this.convReport_(data.work_summary_report_entries)
      this.current_page = 0
    },
    getReportEntries() {
      // 基本的に当日のものしか必要ないはずだが、
      // (そんなに戻れないし)まぁなんか取得しておく.
      const obj = {
        data: {
          work_summary_date: this.work_summary_date,
        },
        officeId: this.office_id,
        inspection_report_id: this.inspection_report_id,
      }
      workSummaryReportEntryApi.show(obj).then(({ data }) => {
        if (!data) { return }
        this.orig_report = data
        this.report = this.convReport_(data)
        this.current_page = 0
      })
    },
    async getUserWorkSummaryReport() {
      const { data } = await workSummaryReportEntryApi.suggestion({
        data: { work_summary_date: this.work_summary_date }
      })
      this.users_suggestion_report = data
      this.showUsersSuggestionReport = data.is_user
    },
    nextDay() {
      const dtFormat = Vue.filter('dtFormat')
      const today = parseInt(dtFormat(new Date(), 'yyyymmdd'))
      const workSummaryDate = new Date(this.work_summary_date)
      const nextWorkSummaryDate = new Date(workSummaryDate.setDate(workSummaryDate.getDate() + 1))
      const nextDay = parseInt(dtFormat(nextWorkSummaryDate, 'yyyymmdd'))
      if (nextDay <= today) {
        window.location.href =
          '/offices/' + this.office_id + '/work_summary_report_detail/' + dtFormat(nextWorkSummaryDate, 'yyyy-mm-dd')
      }
    },
    prevDay() {
      const dtFormat = Vue.filter('dtFormat')
      const workSummaryDate = new Date(this.work_summary_date)
      const prevDay = new Date(workSummaryDate.setDate(workSummaryDate.getDate() - 1))
      window.location.href =
        '/offices/' + this.office_id + '/work_summary_report_detail/' + dtFormat(prevDay, 'yyyy-mm-dd')
    },
    async getConstructions() {
      const obj = {
        date: this.work_summary_date,
      }
      const { data } = await constructionApi.search(obj)
      this.constructions = data.filter(e => {
        return e.office_id === parseInt(this.office_id)
      })
    },
    copyToClipboard(text) {
      const textarea = document.createElement('textarea')
      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.inspection_date)) {
        this.hasInputError = true
      }
      this.getReportEntries()
    },
    preparePrintPattern() {
      let { patternId } = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        patternId: this.print_pattern_id,
      }
      this.print_pattern_id = patternId
      this.onPrintPatternChange()
    },
    onPrintPatternChange() {
      sessionStorage.setItem(this.$route.name, JSON.stringify({
        patternId: this.print_pattern_id,
      }))

      this.print_pattern = null
      // 未選択の場合は全て表示
      if (!this.print_pattern_id) {
        this.report = this.convReport_(this.orig_report)
        return
      }
      // 表示作業者の絞り込み
      this.print_pattern = this.print_patterns.find(pattern => {
        return pattern.id === this.print_pattern_id
      })
      const tmpMap =
        this.print_pattern.inspector_ids.reduce((acc, id, i) => {
          acc[id] = i; return acc
        }, {})
      const filteredReport = this.orig_report.filter(e => {
        return Object.hasOwn(tmpMap, e.inspector_id)
      }).sort((a, b) => {
        return tmpMap[a.inspector_id] < tmpMap[b.inspector_id] ? -1 : 1
      })
      this.report = this.convReport_(filteredReport)
      this.current_page = 0
    },
    convReport_(data) {
      var ret = []
      var arr = []

      const currentUserEntryIdx = data.findIndex(e => {
        return e.inspector_id === this.user.id
      })
      if (currentUserEntryIdx !== -1) {
        // ログインユーザーの日報を先頭に表示する
        const currentUserEntry = data[currentUserEntryIdx]
        const entries1 = data.slice(0, currentUserEntryIdx)
        const entries2 = data.slice(currentUserEntryIdx + 1)
        const entries = [currentUserEntry, ...entries1, ...entries2]
        data = entries
      }
      Object.keys(data).forEach(i => {
        let key = parseInt(i) % this.max_columns_number
        if (key === 0) {
          arr = []
        }

        data[i].is_current_user = data[i].inspector_id === this.user.id
        data[i].is_editable = this.has_role_admin ||
          (this.has_role_shunin && this.isMyOffice && this.isAfterUserTransferDate) ||
          (data[i].is_current_user && this.isAfterUserTransferDate)
        data[i].dup_outside_work_content_am = data[i].outside_work_content_am
        data[i].dup_outside_work_content_pm = data[i].outside_work_content_pm
        data[i].dup_inside_work_content_am = data[i].inside_work_content_am
        data[i].dup_inside_work_content_pm = data[i].inside_work_content_pm
        data[i].dup_other_work_content = data[i].other_work_content
        data[i].index = key
        arr.push(data[i])

        if (parseInt(i) === data.length - 1) {
          for (let n = 0; n < this.max_columns_number - key - 1; n++) {
            arr.push({
              is_append: true,
              outside_work_content_am: '',
              outside_work_content_pm: '',
              inside_work_content_am: '',
              inside_work_content_pm: '',
              other_work_content: ''
            })
          }
        }
        if (key === this.max_columns_number - 1 || parseInt(i) === data.length - 1) {
          ret.push(arr)
        }
      })
      return ret
    },
    parseJson_(data) {
      try {
        // 配列に変換
        data.inspector_office.work_summary_format =
          JSON.parse(data.inspector_office.work_summary_format) || [8, 4, 4]
      } catch (e) {
        // デフォルト値をセット
        data.inspector_office.work_summary_format = [8, 4, 4]
      }

      data.inspector_office.office_work_summary_patterns =
        data.inspector_office.office_work_summary_patterns.map(pattern => {
          try {
            // 配列に変換
            pattern.inspector_ids = JSON.parse(pattern.inspector_ids) || []
          } catch (e) {
            // 仕方ないので初期化
            pattern.inspector_ids = []
          }
          return pattern
        })

      return data
    },
    hasModifiedEntry(currentPage, index) {
      const entry = this.report[currentPage][index]
      return entry.dup_outside_work_content_am !== entry.outside_work_content_am ||
         entry.dup_outside_work_content_pm !== entry.outside_work_content_pm ||
         entry.dup_inside_work_content_am !== entry.inside_work_content_am ||
         entry.dup_inside_work_content_pm !== entry.inside_work_content_pm ||
         entry.dup_other_work_content !== entry.other_work_content
    },
    updateReportHeader() {
      this.errors = {}
      if (this.chief_supervisor.length > 255) {
        this.errors['chief_supervisor'] = ['主任監督員は255文字以内で入力して下さい']
        return
      }
      if (this.management_engineer.length > 255) {
        this.errors['management_engineer'] = ['管理技術者は255文字以内で入力して下さい']
        return
      }

      workSummaryReportApi.update({
        officeId: this.office_id,
        data: {
          work_summary_date: this.work_summary_date,
          management_engineer: this.management_engineer,
          chief_supervisor: this.chief_supervisor,
        }
      }).then(() => {
        this.showUpdateCompleteModal = true
        this.nextPath = ''
        this.dup_management_engineer = this.management_engineer
        this.dup_chief_supervisor = this.chief_supervisor
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    updateWorkSummaryReportEntry(currentPage, index) {
      this.errors = {}
      const entry = this.report[currentPage][index]
      const data = {
        work_summary_report: {
          work_summary_date: this.work_summary_date,
          outside_work_content_am: entry.outside_work_content_am,
          outside_work_content_pm: entry.outside_work_content_pm,
          inside_work_content_am: entry.inside_work_content_am,
          inside_work_content_pm: entry.inside_work_content_pm,
          other_work_content: entry.other_work_content,
          inspector_id: entry.inspector_id
        }
      }

      workSummaryReportEntryApi.update({
        officeId: this.office_id,
        data: data,
      }).then(() => {
        this.showUpdateCompleteModal = true
        this.nextPath = ''
        entry.dup_outside_work_content_am = entry.outside_work_content_am
        entry.dup_outside_work_content_pm = entry.outside_work_content_pm
        entry.dup_inside_work_content_am = entry.inside_work_content_am
        entry.dup_inside_work_content_pm = entry.inside_work_content_pm
        entry.dup_other_work_content = entry.other_work_content
        Vue.set(this.report[currentPage], index, entry)
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    closeUpdateCompleteModal() {
      this.$router.push({ path: '/offices/' + this.office_id + '/work_summary_report_detail/' + this.work_summary_date.replace(/\//g, '-') })
      this.showUpdateCompleteModal = false
    },
    deleteReportHeader() {
      const data = {
        work_summary_date: this.work_summary_date,
        inspector_id: this.reportHeaderToDelete.inspector_id
      }
      workSummaryReportEntryApi.delete({
        officeId: this.office_id,
        data: data,
      }).then(() => {
        this.reportHeaderToDelete = null
        this.nextPath = ''
        this.getReportEntries()
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    confirmBeforeUnload(e) {
      if (this.hasChangedWorkSummaryReport) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    closeConfirmBeforeLeaveModal() {
      this.forceLeavePage = true
      this.$router.push(this.nextPath)
      this.nextPath = ''
      this.showConfirmBeforeLeaveModal = false
    },
  },
  components: { TimeInput }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
