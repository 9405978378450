<template>
<div id="header"
    class="top-navigation row border-bottom white-bg"
    :class="{'no-header': hideHeader}">
  <nav class="navbar navbar-fixed-top" role="navigation">
    <div class="navbar-header">
      <button aria-controls="navbar" aria-expanded="false"
          @click="toggleHamburgerMenu"
          data-target="#navbar" data-toggle="collapse"
          class="navbar-toggle collapsed" type="button">
        <i class="fa fa-reorder"></i>
      </button>
      <a class="opt-logo1" ui-sref="dashboard"></a>
      <a ui-sref="dashboard" class="navbar-brand">
        施工管理
        <span class="small">on Cloud</span>
        <span class="badge badge-pill badge-secondary env dev" v-if="isDev">dev</span>
        <span class="badge badge-pill badge-secondary env stg" v-if="isStg">stg</span>
      </a>
    </div>
    <div ref="navbarCollapse" class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav" @click="onNavVarClick">
        <li class="nav-item" :class="{active: routeName === 'top'}">
          <router-link :to="{name:'top'}" class="page-link">
            トップ
          </router-link>
        </li>
        <template v-if="isPageGroupA">
          <li class="nav-item" :class="{active: isInspectionReportPageGroup}">
            <router-link :to="{name:'inspectionReportList'}" class="page-link">
              立会検査報告書
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isCorrectionReportPageGroup}">
            <router-link :to="{name:'correctionReportList'}" class="page-link">
              指摘事項報告書
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isWorkReportPageGroup}">
            <router-link :to="{name:'workSummaryReportCalendar'}" class="page-link">
              業務状況報告書
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isPhotoPageGroup}">
            <router-link :to="{name:'photoList'}" class="page-link">
              写真台帳
            </router-link>
          </li>
        </template>
        <template v-if="isPageGroupB">
          <li class="nav-item" :class="{active: isConstructionPageGroup}">
            <router-link :to="{name:'constructionList'}" class="page-link">
              工事件名
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isOfficePageGroup}" v-if="has_role_shunin">
            <router-link :to="{name:'officeList'}" class="page-link">
              事務所
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isMasterPageGroup}" v-if="has_role_admin">
            <router-link :to="{name:'masterList'}" class="page-link">
              マスタ
            </router-link>
          </li>
          <li class="nav-item" :class="{active: isUserPageGroup}" v-if="has_role_admin">
            <router-link :to="{name:'userList'}" class="page-link">
              ユーザー
            </router-link>
          </li>
        </template>
      </ul>
      <ul class="nav navbar-top-links navbar-right">
        <li class="username">
          ユーザー名: <span>{{ username }}</span>
        </li>
        <li class="logout">
          <a href="#" @click="logout">
            <i class="fa fa-sign-out"></i> ログアウト
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import routeMixin from '@/mixin/routeMixin'

export default {
  name: 'global-header',
  data() {
    return {}
  },
  computed: {
    ...mapState('user', {
      username: state => state.name,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
      env: state => state.env,
    }),
    ...mapGetters('user', [
      'isLoggedIn'
    ]),
    isDev() {
      return this.env === 'development'
    },
    isStg() {
      return this.env === 'staging'
    },
    routeName() {
      return this.$route.name
    },
    hideHeader() {
      return !this.isLoggedIn || this.isGuestPage() || this.isPrintPage()
    },
    isInspectionReportPageGroup() {
      return [
        'inspectionReportList',
        'inspectionReportNew',
        'inspectionReportDetail',
        'inspectionReportDetailCopy',
        'inspectionReportPhotos',
        'inspectionReportDocuments',
        'inspectionReportContentPhotos',
        'inspectionReportResultContentPhotos',
      ].includes(this.$route.name)
    },
    isCorrectionReportPageGroup() {
      return [
        'correctionReportList',
        'inspectionReportCorrectionReportList',
        'correctionReportNew',
        'correctionReportDetail',
        'correctionReportCorrectionBeforePhotos',
        'correctionReportCorrectionAfterPhotos',
      ].includes(this.$route.name)
    },
    isWorkReportPageGroup() {
      return [
        'workSummaryReportCalendar',
        'workSummaryReportDetailDate',
        'workSummaryReportEntryList',
      ].includes(this.$route.name)
    },
    isPhotoPageGroup() {
      return [
        'photoList',
      ].includes(this.$route.name)
    },
    isEmergencyContactPageGroup() {
      return [
        'emergencyContactList',
        'emergencyContactNew',
        'emergencyContactDetail',
        'emergencyContactPhotos',
      ].includes(this.$route.name)
    },
    isConstructionPageGroup() {
      return [
        'constructionList',
        'constructionNew',
        'constructionDetail',
      ].includes(this.$route.name)
    },
    isOfficePageGroup() {
      return [
        'officeList',
        'officeNew',
        'officeDetail',
      ].includes(this.$route.name)
    },
    isMasterPageGroup() {
      return [
        'masterList',
        'masterDetail',
        'masterDetailInsideWorkContent',
      ].includes(this.$route.name)
    },
    isUserPageGroup() {
      return [
        'userList',
      ].includes(this.$route.name)
    },
    isPageGroupA() {
      return this.isInspectionReportPageGroup ||
        this.isCorrectionReportPageGroup ||
        this.isWorkReportPageGroup ||
        this.isPhotoPageGroup
    },
    isPageGroupB() {
      return this.isMasterPageGroup ||
        this.isUserPageGroup ||
        this.isConstructionPageGroup ||
        this.isOfficePageGroup
    }
  },
  mixins: [routeMixin],
  methods: {
    onNavVarClick(evt) {
      // 押されたら閉じる
      const target = evt.target
      if (target.classList.contains('page-link')) {
        this.toggleHamburgerMenu()
      }
    },
    toggleHamburgerMenu() {
      const navBar = this.$refs.navbarCollapse
      if (navBar.classList.contains('in')) {
        navBar.classList.remove('in')
      } else {
        navBar.classList.add('in')
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      await this.$store.dispatch('localStorage/set', {
        key: 'serverToken',
        val: null,
      })
      // want to explicitly reload
      location.href = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
/* 微妙に狭くしたときの段落ちを避けたい */
@media (min-width: 768px) {
  .navbar {
    white-space: nowrap;

    .navbar-header {
      display: inline-block;
      float: none;
      vertical-align: top;
    }
    .navbar-collapse.collapse {
      display: inline-block !important;
      float: none;
      vertical-align: top;

      .nav {
        display: inline-block;
        float: none;
        vertical-align: top;

        &.navbar-right {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.username {
  display: inline-block;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 1084px) {
    display: inline-block;
  }
}

.logout {
  display: inline-block;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 960px) {
    display: inline-block;
  }
}
.navbar-brand {
  position: relative;

  .env {
    position: absolute;
    font-size: 10px;
    top: 4px;
    left: 10px;
    padding: 1px 4px;
    &.dev {
      color: #00b300;
    }
    &.stg {
      color: orange;
    }
  }
}
</style>
