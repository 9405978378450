<template>
<div class="container">
  <div class="page page-top">
    <div class="ibox float-e-margins ibox1">
      <div class="ibox-content clearfix">
        <p class="dt">
          <span>{{ dt | dtFormatYMDA1 }}</span>
        </p>
      </div>
    </div>

    <div class="ibox float-e-margins ibox2">
      <div class="ibox-content clearfix">
        <div class="col-md-12">
          <div class="col-md-3">
            <router-link :to="{name:'inspectionReportList'}" class="btn btn-lg btn-default">
              <span>立会検査報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'correctionReportList'}" class="btn btn-lg btn-default">
              <span>指摘事項報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'workSummaryReportCalendar'}" class="btn btn-lg btn-default">
              <span>業務状況報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'photoList'}" class="btn btn-lg btn-default">
              <span>写真台帳</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox3">
      <div class="ibox-content clearfix">

        <div class="col-md-12 row2">
          <div class="col-md-3">
            <router-link :to="{name:'constructionList'}" class="btn btn-lg btn-default">
              <span>工事件名登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'officeList'}" class="btn btn-lg btn-default" v-if="has_role_shunin">
              <span>事務所登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'masterList'}" class="btn btn-lg btn-default" v-if="has_role_admin">
              <span>その他マスタ登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'userList'}" class="btn btn-lg btn-default" v-if="has_role_admin">
              <span>ユーザー管理</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox4">
      <div class="ibox-content clearfix">
        <div class="col-md-12 row1">
          <div class="system-manual">
            <a class="btn btn-link" v-if="is_admin"
                href="/static/pdf/報告書作成システム操作マニュアル(管理者用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(管理者用)ダウンロード
            </a>
            <a class="btn btn-link"  v-if="is_soukatsu || is_shunin"
                href="/static/pdf/報告書作成システム操作マニュアル(主任・総括用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(主任・総括用)ダウンロード
            </a>
            <a class="btn btn-link"  v-if="is_sagyou"
                href="/static/pdf/報告書作成システム操作マニュアル(作業者用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(作業者用)ダウンロード
            </a>
          </div>
        </div>
        <div class="col-md-12 row2" v-if="false && is_admin">
          <div class="db-dump">
            <http-link href="/logs/download_db_dump" class="btn btn-link">
              <i class="fa fa-download"></i>
              DBダンプダウンロード
            </http-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox5" v-if="false && is_admin">
      <div class="ibox-content clearfix">
        <div class="col-md-12 row1">
          <div class="mb-5 font-bold">HDD使用率</div>
          <table class="ml-8">
            <tbody>
              <tr class="ta-right b-all" v-for="(diskUsage, idx) in diskUsages"
                  style="border-color: #e7eaec;" :key="idx">
                <td class="p-10 br ta-left" style="border-color: #e7eaec;">{{diskUsage.filesystem}}</td>
                <td class="pl-10 ">{{diskUsage.used}}&nbsp;/&nbsp;</td>
                <td>{{diskUsage.size}}（</td>
                <td class="pr-10">{{diskUsage.capacity}} 使用中）</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 row2">
          <div class="mb-5 font-bold">エラーログ</div>
          <table class="ml-8" v-if="errorAlerts.length > 0">
            <tbody>
              <tr class="ta-right b-all" v-for="(e, idx) in errorAlerts"
                  style="border-color:#e7eaec;" :key="idx">
                <td :class="{ disabled: e.isClicked }">
                  <div class="btn btn-link" @click="downloadErrorAlert(e)">
                    <i class="fa fa-download"></i>
                    {{e.dt | dtFormatYMDA2 }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="ml-8" v-else>
            新しいエラーログはありません
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox5" v-if="false & is_admin">
      <div class="ibox-content clearfix bkup-status">
        <div class="col-md-12 row1">
          <div class="title-area mb-8">
            <span class="title font-bold">バックアップ状況</span>
            <span class="last-bkup-time ml-8">
              最終バックアップ実施時刻: {{bkupInfo.created_at | dtFormatYMDA2}}
            </span>
          </div>
          <div class="tbl">
            <template v-for="(result, i) in bkupInfo.results">
              <div class="tbl-row row-type1" v-if="result.isEmpty" :key="i">
                <div class="col1 cell">{{result.mountPoint}}</div>
                <div class="col2 cell failed">ディスクにアクセスできません</div>
              </div>
              <div class="tbl-row row-type2" v-else :key="i">
                <div class="col1 cell">{{result.mountPoint}}</div>
                <div class="col2 cell">{{result.diskUsedDisp}}</div>
                <div class="col3 cell">/</div>
                <div class="col4 cell">{{result.diskSizeDisp}}</div>
                <div class="col5 cell">({{result.capacity}}% 使用中)</div>
                <div class="col6 cell">
                  <div class="db-bkup-result">
                    <span v-if="result.db_bkup_succeeded">
                      <span class="success">成功</span> (DBダンプ所要時間: {{result.dbBkupExecTime}})
                    </span>
                    <span v-else>
                      <span class="failed">失敗 (DBダンプ: {{result.db_bkup_err_reason}})</span>
                    </span>
                  </div>
                  <div class="image-dir-bkup-result">
                    <span v-if="result.image_dir_bkup_succeeded">
                      <span class="success">成功</span> (画像ダンプ所要時間: {{result.imageDirBkupExecTime}})
                    </span>
                    <span v-else>
                      <span class="failed">失敗 (画像ダンプ: {{result.image_dir_bkup_err_reason}})</span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
// import logApi from '@/api/log'
// import bkupResultApi from '@/api/bkupResult'
import masterMixin from '@/mixin/masterMixin'
import mediaMixin from '@/mixin/mediaMixin'

export default {
  name: 'top',
  data() {
    return {
      dt: new Date(),
      // diskUsages: [],
      // errorAlerts: [],
      // bkupInfo: {},
    }
  },
  computed: {
    ...mapState('user', {
      is_admin: state => state.is_admin,
      is_soukatsu: state => state.is_soukatsu,
      is_shunin: state => state.is_shunin,
      is_sagyou: state => state.is_sagyou,
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
  },
  mixins: [masterMixin, mediaMixin],
  mounted() {
    this.waitForUserAndMasters().then(() => {
      if (this.is_admin) {
        // this.getDiskUsage()
        // this.getErrorAlerts()
        // this.getBkupResult()
      }
    })
  },
  methods: {
    // getDiskUsage() {
    //   logApi.getDiskUsage().then(({ data }) => {
    //     data.forEach(diskUsage => {
    //       if (diskUsage.length < 4) { return }
    //       const obj = {
    //         filesystem: diskUsage[0],
    //         // 桁を揃える
    //         // 単位はG/M/Kのどれがくるかわからないので、文字列から取ってつけ直す
    //         size: parseFloat(diskUsage[1]).toFixed(1) + diskUsage[1].slice(-1),
    //         used: parseFloat(diskUsage[2]).toFixed(1) + diskUsage[2].slice(-1),
    //         capacity: diskUsage[4],
    //       }
    //       this.diskUsages.push(obj)
    //     })
    //   })
    // },
    // getErrorAlerts() {
    //   logApi.getErrorAlerts().then(({ data }) => {
    //     data.forEach(e => {
    //       e.isClicked = false
    //     })
    //     this.errorAlerts = data
    //   })
    // },
    // downloadErrorAlert(e) {
    //   if (e.isClicked) { return }
    //   logApi.downloadErrorAlert({ id: e.id })
    //     .then(res => {
    //       e.isClicked = true
    //       const { url, filename } = this.toBlobUrl(res)
    //       const link = document.createElement('a')
    //       link.href = url
    //       link.download = filename
    //       link.click()
    //     })
    // },
    // getBkupExecTimeForDisp(seconds) {
    //   let h = parseInt(seconds / 3600)
    //   const tmp = seconds % 3600
    //   const m = parseInt(tmp / 60)
    //   const s = tmp % 60

    //   let ret = ''
    //   if (h > 0) {
    //     ret += `${h}時間`
    //   }
    //   if (m > 0) {
    //     ret += `${m}分`
    //   }
    //   ret += `${s}秒`
    //   return ret
    // },
    // getBkupDiskSpaceForDisp(bytes) {
    //   // 容量に応じてM, G, Tで表示
    //   let unit = 'M'
    //   if (bytes >= (1024 ** 4)) {
    //     unit = 'T'
    //   } else if (bytes >= (1024 ** 3)) {
    //     unit = 'G'
    //   }

    //   let mult = 1
    //   if (unit === 'M') {
    //     mult = 1024 ** 2
    //   } else if (unit === 'G') {
    //     mult = 1024 ** 3
    //   } else if (unit === 'T') {
    //     mult = 1024 ** 4
    //   }

    //   return `${parseFloat(bytes / mult).toFixed(1)}${unit}`
    // },
    // getBkupResult() {
    //   bkupResultApi.getLatestBkupResult().then(({ data }) => {
    //     const bkupInfo = data.bkup_result || {}
    //     let bkupResultMapByMountPoint = {}
    //     if (bkupInfo && bkupInfo.result) {
    //       bkupResultMapByMountPoint = JSON.parse(bkupInfo.result)
    //     }
    //     const bkupResults = []
    //     for (const mp of data.bkup_mount_points) {
    //       if (!bkupResultMapByMountPoint[mp]) {
    //         const result = {
    //           mountPoint: mp,
    //           isEmpty: true,
    //         }
    //         bkupResults.push(result)
    //         continue
    //       }
    //       const result = bkupResultMapByMountPoint[mp]
    //       result.mountPoint = mp
    //       result.diskUsedDisp = this.getBkupDiskSpaceForDisp(result.disk_used)
    //       result.diskSizeDisp = this.getBkupDiskSpaceForDisp(result.disk_size)
    //       result.capacity = parseFloat((result.disk_used / result.disk_size) * 100).toFixed(1)
    //       result.dbBkupExecTime = this.getBkupExecTimeForDisp(result.db_bkup_exec_seconds)
    //       result.imageDirBkupExecTime = this.getBkupExecTimeForDisp(result.image_dir_bkup_exec_seconds)
    //       bkupResults.push(result)
    //     }
    //     bkupInfo.results = bkupResults

    //     this.bkupInfo = bkupInfo
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
.ibox1 {
  margin-top: 24px;
}

.dt {
  font-size: 18px;
  margin: 0;
}

.btn.btn-lg {
  width: 90%;
  height: 70px;
  margin: 0;

  line-height: 2.8;
  &.two-lines {
    padding-top: 14px;
    line-height: 1.2;
  }
}

.ibox2 .btn {
  color: #ffffff;
  background-color: #348ce2;
  border-color: #3283d3;
}
.ibox3 .btn {
  color: #ffffff;
  background-color: #e25134;
  border-color: #bf442b;
}

.ibox3 .row1 {
  margin-bottom: 16px;
}

.ibox4 .ibox-content {
  padding-bottom: 16px;

  .system-manual, .db-dump {
    margin-left: 4px;
    .btn-link {
      margin: 0;
    }
  }
}

.ibox5 .ibox-content {
  padding: 15px 34px;

  .row2 {
    margin-top: 16px;

    .disabled {
      background-color: #eeeeee;
      .btn-link {
        cursor: default;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
.bkup-status {
  .tbl {
    display: grid;
    grid-template-columns: auto auto auto auto auto 1fr;
    border: solid 1px #e7eaec;
  }
  .cell {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e7eaec;
    padding: 10px 0;
  }
  .tbl-row {
    display: contents;
    .col1 {
      border-right: solid 1px #e7eaec;
      padding: 10px;
    }
  }
  .row-type1 {
    .col2 {
      grid-column: 2 / span 5;
      padding-left: 10px;
    }
  }
  .row-type2 {
    .col2 {
      justify-self: end;
      padding-left: 10px;
    }
    .col3 {
      padding: 10px 4px;
    }
    .col4 {
      justify-self: end;
    }
    .col5 {
      padding: 10px
    }
    .col6 {
      display: grid;
      grid-template-rows: auto;
      padding: 10px;
    }
  }
  .success {
    color: #00aa00;
    font-weight: bold;
  }
  .failed {
    color: #e25134;
    font-weight: bold;
  }
}
</style>
