import axios from 'axios'
import * as utils from './apiUtils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/offices'), {
      params: obj || {}
    })
  },
  search(obj) {
    return axios.get(utils.getApiUrl('/offices/search'), {
      params: obj || {}
    })
  },
  create({ data }) {
    return axios.post(utils.getApiUrl(`/offices`), data)
  },
  show({ id }) {
    return axios.get(utils.getApiUrl(`/offices/${id}`))
  },
  update({ id, data }) {
    return axios.put(utils.getApiUrl(`/offices/${id}`), data)
  },
  delete({ id }) {
    return axios.delete(utils.getApiUrl(`/offices/${id}`))
  },
  update_office_work_summary_pattern({ id, data }) {
    return axios.patch(utils.getApiUrl(`/offices/${id}/office_work_summary_patterns`), data)
  },
  delete_office_work_summary_pattern({ id, patternId }) {
    return axios.delete(utils.getApiUrl(`/offices/${id}/office_work_summary_patterns/${patternId}`))
  },
}
