<template>
  <div class="photo-selector" ref="photoSelector"
      :class="{'overflow-y-scroll': true}">
    <div>
      <button class="btn btn-delete-photo btn-m btn-danger"
          @click="showDeleteConfirmModal = true"
          :disabled="selectedPhotoIds.length === 0">
        一括削除
      </button>
      <button class="btn btn-delete-photo btn-m btn-primary"
        @click="selectAllPhotos">
        全選択
      </button>
      <button class="btn btn-delete-photo btn-m btn-default"
        @click="clearSelectedPhotos">
        全解除
      </button>
      <div class="drop" @dragleave.prevent @dragover.prevent @drop.prevent="addPhoto">
        <div class="photo-list">
          <div class="btn-add-photo">
            <label class="btn btn-lg btn-default" for="photoFileInput">
              <i class="fa fa-spinner fa-spin" v-if="isUploadingPhoto"></i>
              <span v-if="!isUploadingPhoto">写真<br>登録</span>
            </label>
            <input type="file" @change="addPhoto"
              accept=".jpg, .jpeg, .png, .gif"
              style="display:none;"
              id="photoFileInput"
              multiple>
          </div>
          <div class="thumbnail" v-for="(photo, idx) in photos"
              :class="{ selected: isSelectedPhoto(photo.id) }"
              :key="photo.id">
            <http-img class="photo-content"
              :src="photo.photo_url_medium"
              @click="selectPhoto(photo, idx, $event)">
            </http-img>
          </div>
        </div>
      </div>
      <div class="selected-photo-area row2" v-if="selectedPhoto">
        <hr>
        <div class="col-xs-12 ta-center">
          <div>
            <http-img class="photo-img"
              :src="selectedPhoto.photo_url_original">
            </http-img>
          </div>
          <div class="mt-4">
            <http-link class="btn btn-sm btn-default"
                :href="selectedPhoto.photo_url_original">
              <i class="fa fa-download"></i>
              ダウンロード
            </http-link>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showDeleteConfirmModal"
      title="削除確認"
      @close="deletePhotos"
      @dismiss="closeDeleteComfirmModal">
      <div slot="body">
        <div class="wrap1">
          <span v-if='hasReports'>
            <p class="p1 err-msg">※ 報告書に紐付いている写真が選択されています。</p><br>
          </span>
          <p class="p1">選択された{{selectedPhotoIds.length}}枚の写真を削除します。</p>
          <p class="p2">よろしいですか？</p>
        </div>
      </div>
      <div slot="footer-top">
        <div v-show="hasError" class="err-msg create ta-left">
          <div v-for="msg in errorMessages" :key="msg">・{{ msg }}</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import photoApi from '@/api/photo'
import imageMixin from '@/mixin/imageMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'photo-selector2',
  props: {
    photos: {
      type: Array,
      required: true,
    },
    photoDate: {
      type: [String, Date],
      required: true,
    },
  },
  data() {
    return {
      uploadFile: null,
      selectedPhotoIds: [],
      selectedPhotoIdx: null,
      isUploadingPhoto: false,
      showDeleteConfirmModal: false,
      onResizeFunc: () => {},

      errors: {},
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    errorMessages() {
      const msgs = Object.keys(this.errors).map(k => {
        return this.errors[k]
      }, [])
      return Array.from(new Set([].concat(...msgs)))
    },
    hasReports() {
      const selectedPhotos = this.photos.filter(photo => this.selectedPhotoIds.includes(photo.id))
      return selectedPhotos.filter(photo => photo.has_reports).length > 0
    },
    selectedPhoto() {
      if (this.selectedPhotoIds.length > 1) { return }
      return this.photos.find(p => p.id === this.selectedPhotoIds[0])
    },
  },
  mounted() {
    this.setListeners()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResizeFunc)
  },
  mixins: [imageMixin, errorHandleMixin],
  methods: {
    setMaxHeight() {
      this.$refs.photoSelector.style.maxHeight =
        (window.innerHeight - (60 + 70 + 50)) + 'px'
    },
    setListeners() {
      this.isUploadingPhoto = false
      this.showDeleteConfirmModal = false

      this.onResizeFunc = () => {
        this.setMaxHeight()
      }
      window.addEventListener('resize', this.onResizeFunc)
      this.onResizeFunc()
    },
    isSelectedPhoto(id) {
      return this.selectedPhotoIds.includes(id)
    },
    clearSelectedPhotos() {
      this.selectedPhotoIds = []
    },
    selectAllPhotos() {
      this.selectedPhotoIds = this.photos.map(photo => photo.id)
    },
    selectPhoto(photo, idx, e) {
      if (e.shiftKey) {
        const prevSelectedPhotoIdx = this.selectedPhotoIdx
        this.selectedPhotoIdx = idx
        const minIdx = Math.min(prevSelectedPhotoIdx, this.selectedPhotoIdx)
        const maxIdx = Math.max(prevSelectedPhotoIdx, this.selectedPhotoIdx)
        const subsetPhotoIds = this.photos.slice(minIdx, maxIdx + 1).map(photo => photo.id)
        if (this.selectedPhotoIds.length === 0) {
          this.selectedPhotoIds = subsetPhotoIds
          return
        }
        if (this.isSelectedPhoto(photo.id)) {
          this.selectedPhotoIds = this.selectedPhotoIds.filter(id => !subsetPhotoIds.includes(id))
          return
        }
        this.selectedPhotoIds = Array.from(new Set(this.selectedPhotoIds.concat(subsetPhotoIds)))
      } else if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
        // Windows ctrlキーまたはMac commandキーで複数選択
        if (this.isSelectedPhoto(photo.id)) {
          this.selectedPhotoIds = this.selectedPhotoIds.filter(id => id !== photo.id)
          this.selectedPhotoIdx = this.selectedPhotoIds.length === 0 ? null
            : this.photos.findIndex(p => p.id === this.selectedPhotoIds[this.selectedPhotoIds.length - 1])
          return
        }
        this.selectedPhotoIdx = idx
        this.selectedPhotoIds.push(photo.id)
      } else {
        if (this.isSelectedPhoto(photo.id)) {
          this.selectedPhotoIdx = null
          this.clearSelectedPhotos()
          return
        }
        this.selectedPhotoIdx = idx
        this.clearSelectedPhotos()
        this.selectedPhotoIds.push(photo.id)
      }
    },
    addPhoto(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files || files.length === 0) { return }
      this.isUploadingPhoto = true
      const formData = new FormData()
      formData.append('photo_date', this.photoDate)
      Object.keys(files).forEach(k => {
        const file = files[k]
        formData.append('photos[]', file, file.name)
      })
      const obj = {
        formData: formData
      }
      photoApi.create(obj).then(() => {
        this.isUploadingPhoto = false
        this.emitRefresh()
      }).catch(() => {
        this.isUploadingPhoto = false
      })
    },
    emitRefresh() {
      this.$emit('should-refresh')
    },
    deletePhotos() {
      if (this.selectedPhotoIds.length === 0) { return }
      photoApi.bulkDelete({ ids: this.selectedPhotoIds })
        .then(() => {
          this.selectedPhotoIds = []
          this.showDeleteConfirmModal = false
          this.emitRefresh()
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    closeDeleteComfirmModal() {
      this.errors = {}
      this.showDeleteConfirmModal = false
    }
  },
}
</script>

<style lang="scss" scoped>
.overflow-y-scroll {
  overflow-y: scroll;
}
.row1 {
  margin: 0 -4px;
}
.row2 {
  margin: 0 -10px;
}
.photo-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px 25px;
  display: flex;
  flex-wrap: wrap;
}
.thumbnail{
  width: 100px;
  height: 100px;
  padding: 4px;
  margin: 6px 10px;
  cursor: pointer;
  &.selected {
    border-color: #fff;
    box-shadow: 0 0 0 3px red;
  }
  .photo-content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.btn-add-photo {
  width: 100px;
  margin: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photo-img {
  max-height: 300px;
  max-width: 100%;
}
.photo-add-bottom-bar {
  margin-top: 8px;
}
.drop {
  margin-top: 5px;
  border: 2px dotted #ddd;
}
</style>
