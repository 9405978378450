<template>
  <div class="quill-editor">
    <div :id="toolbarId" @click="onToolbarClick">
      <span class="ql-formats">
        <select class="ql-size">
          <option value="large">大</option>
          <option selected>中</option>
          <option value="small">小</option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-align"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-clean"></button>
      </span>
    </div>
    <div
      :id="editorId"
      :class="editorClass"
      :style="editorStyleObj"
      @click="focusEditor">
    </div>
  </div>
</template>

<script>
import Quill from 'quill'

export default {
  name: 'quill-editor',
  props: {
    value: {
      type: String,
      default: '',
    },
    propName: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'html',
      validator(val) {
        return ['html', 'delta'].includes(val)
      }
    },
    theme: {
      type: String,
      default: 'snow',
      validator(val) {
        return ['snow', 'bubble'].includes(val)
      }
    },
    editorClass: {
      type: String,
      default: '',
    },
    editorStyleObj: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      editor: {},
    }
  },
  watch: {
    value() {
      if (!this.editor.hasFocus()) {
        this.setEditorContents(this.value, { scrollTop: true })
      }
    },
  },
  computed: {
    editorId() {
      return `editor-container${this._uid}`
    },
    toolbarId() {
      return `toolbar-container${this._uid}`
    },
  },
  mounted() {
    this.setupQuillEditor()
    this.setEditorContents(this.value, { scrollTop: false })
    this.setEditorEventListners()
  },
  methods: {
    setupQuillEditor() {
      this.editor = new Quill(`#${this.editorId}`, {
        modules: {
          toolbar: `#${this.toolbarId}`,
        },
        theme: this.theme,
      })
      if (this.theme === 'bubble') {
        // disables user input
        this.editor.disable()
      }
    },
    setEditorContents(value, { scrollTop }) {
      const x = scrollTop ? 0 : window.scrollX
      const y = scrollTop ? 0 : window.scrollY
      if (value && value !== '') {
        if (this.mode === 'html') {
          this.editor.root.innerHTML = value
        } else if (this.mode === 'delta') {
          this.editor.setContents(JSON.parse(value))
        }
      }
      this.editor.blur()
      window.scrollTo(x, y)
    },
    setEditorEventListners() {
      this.editor.on('text-change', () => this.handleTextChange())
    },
    handleTextChange() {
      // Clear the input manually if the editor has no content
      // because Quill returns empty paragraph tags even when it is empty.

      let editorText = ''
      let editorContent = ''
      if (this.editor.root.textContent.length > 0 || this.editor.root.childElementCount > 1) {
        editorText = this.editor.getText()
        if (this.mode === 'html') {
          editorContent = this.editor.root.innerHTML
        } else if (this.mode === 'delta') {
          editorContent = JSON.stringify(this.editor.getContents())
        }
      }
      this.$emit('input', editorContent)
      this.$emit('editor-input', this.propName, editorText)
    },
    onToolbarClick(e) {
      this.$emit('toolbar-click', e)
    },
    focusEditor() {
      this.editor.focus()
    },
  }
}
</script>

<style lang="scss" scoped></style>
