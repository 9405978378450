<template src="./template.html"></template>

<script>
import PhotoSelector2 from '@/components/lib/PhotoSelector2'
import photoApi from '@/api/photo'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'photo',
  data() {
    const { dtStrTo } =
      JSON.parse(sessionStorage.getItem(this.$route.name)) ||
      this.getListInitialSearchParams()
    return {
      search: {
        dt: dtStrTo,
      },
      hasInputError: false,
      photos: [],
    }
  },
  computed: {
  },
  mounted() {
    this._doSearch()
  },
  mixins: [listPageMixin, dateMixin],
  methods: {
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dt)) {
        this.hasInputError = true
      }
      this.doSearch()
    },
    doSearch() {
      if (this.hasInputError) { return }
      this._doSearch()
    },
    _doSearch() {
      sessionStorage.setItem(this.$route.name, JSON.stringify({
        dtStrTo: this.search.dt,
      }))

      const obj = {
        date: this.search.dt,
      }
      photoApi.search(obj).then(({ data }) => {
        this.photos = data
      })
    },
  },
  components: { PhotoSelector2 },
}
</script>

<style lang="scss" scoped></style>
