<template src="./template.html"></template>

<script>
import Vue from 'vue'
let api
import documentMixin from '@/mixin/documentMixin'
import errorHandleMixin from '@/mixin/errorHandleMixin'

export default {
  name: 'detail-documents',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      meta: {},
      report: {},
      documents: [],
      documentMap: [],
      pages: [],

      currentPage: 1,
      maxPage: 20,
      addedDocument: null,
      selectedDocument: null,
      showDocumentEditorModal: false,
    }
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1
    },
    isLastPage() {
      return this.currentPage === this.pages.length
    },
    hasMaxPage() {
      return this.pages.length >= this.maxPage
    },
    printPageRouteName() {
      const routeName = this.$route.name
      const first = routeName[0]
      const rest = routeName.slice(1)
      return `print${first.toUpperCase()}${rest}`
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  mounted() {
    api = require(`@/api/inspectionReport`).default
    this.getData()
  },
  mixins: [documentMixin, errorHandleMixin],
  methods: {
    fileSelected(e) {
      if (!e.target.files || e.target.files.length === 0) {
        return
      }
      const f = e.target.files[0]
      this.uploadFile = {
        name: f.name,
        type: f.type,
        file: f,
        src:  window.URL.createObjectURL(f),
      }
      this.addDocument()
    },
    addDocument: function() {
      this.isAddingDocument = true

      const formData = new FormData()
      formData.append('document',
        this.uploadFile.file,
        this.uploadFile.name)

      const obj = {
        id: this.id,
        formData: formData
      }
      api.addDocument(obj).then(({data}) => {
        this.isAddingDocument = false
        this.uploadFile = null
        this.documents.push(data)
        this.addedDocument = data
      }).catch(() => {
        this.isAddingDocument = false
      })
    },
    confirmDeleteReport(e) {
      this.selectedDocument = e
    },
    deleteDocument() {
      if (!this.selectedDocument) { return }
      let self = this;
      api.deleteDocument({
        id: this.id,
        document: {
          id: this.selectedDocument.id,
        }
      }).then(() => {
        let selectedDocumentId = this.selectedDocument.id
        this.selectedDocument = null
        this.documents = this.documents.filter( function(e){
          return e.id != selectedDocumentId
        })
      })
      .catch(err => {
        this.handleErrorResponse(err)
      })
    },
    getData() {
      return api.show({id: this.id})
        .then(({ data }) => {
          data.documents.forEach(document => {
            if (document.used_index !== null) {
              document.idx = document.used_index
            }
            document.orig_name = document.name
          })
          this.documents = data.documents
          this.report = data
          this.pages = this.getPages(data)
        })
    },
    getEmptyPage(idx) {
      return {
        idx: idx,
        is_empty: true,
        name: '',
        orig_name: '',
      }
    },
    getPages(data) {
      const ret = []
      const usedDocumentIds = this.documents.reduce((arr, document) => {
        if (document.used_index !== null) {
          arr[document.used_index] = document.id
        }
        return arr
      }, [])
      const len = usedDocumentIds.length
      if (len === 0) {
        ret.push({documents: [
          this.getEmptyPage(0), this.getEmptyPage(1),
        ]})
        return ret
      }

      const halfLen = Math.ceil(len / 5)
      for (let i = 0; i < halfLen; i++) {
        const idxBase = i * 10
        const page = []
        let document1 = this.documentMap[usedDocumentIds[idxBase]]
        if (!document1) {
          document1 = this.getEmptyPage(idxBase)
        }

        let document2 = (idxBase + 1 < len) ?
          this.documentMap[usedDocumentIds[idxBase + 1]] : null;
        if (!document2) {
          document2 = this.getEmptyPage(idxBase + 1)
        }

        let document3 = (idxBase + 2 < len) ?
          this.documentMap[usedDocumentIds[idxBase + 2]] : null;
        if (!document3) {
          document3 = this.getEmptyPage(idxBase + 2)
        }

        let document4 = (idxBase + 3 < len) ?
          this.documentMap[usedDocumentIds[idxBase + 3]] : null;
        if (!document4) {
          document4 = this.getEmptyPage(idxBase + 3)
        }

        let document5 = (idxBase + 4 < len) ?
          this.documentMap[usedDocumentIds[idxBase + 4]] : null;
        if (!document5) {
          document5 = this.getEmptyPage(idxBase + 4)
        }

        ret.push({documents: [document1, document2, document3, document4, document5]})
      }
      return ret
    },
    toPrevPage() {
      this.currentPage = Math.max(1, this.currentPage - 1)
    },
    toNextPage() {
      this.currentPage = Math.min(this.currentPage + 1, this.pages.length)
    },
    addNewPage() {
      const nextIdx = this.pages.length * 2
      this.pages.push({documents: [
        this.getEmptyPage(nextIdx), this.getEmptyPage(nextIdx + 1),
      ]})
    },
    toNewPage() {
      this.addNewPage()
      this.toNextPage()
    },
    showDocumentEditor(document) {
      this.selectedDocument = document
      this.showDocumentEditorModal = true
    },
    onDocumentShouldRefresh() {
      this.getData()
    },
    onUseDocument(document) {
      const promises = []
      promises.push(api.updateDocument({
        id: this.meta.id,
        document: {
          id: document.id,
          used_index: this.selectedDocument.idx,
        }
      }))
      // 付け替えの場合は元のやつから紐付けを削除する
      if (this.selectedDocument.id &&
          this.selectedDocument.id !== document.id) {
        promises.push(api.updateDocument({
          id: this.meta.id,
          document: {
            id: this.selectedDocument.id,
            used_index: null,
          }
        }))
      }
      Promise.all(promises)
        .then(() => {
          this.getData()
          this.showDocumentEditorModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    onUnuseDocument(document) {
      api.updateDocument({
        id: this.meta.id,
        document: {
          id: document.id,
          used_index: null,
        }
      }).then(() => {
        this.getData()
          .then(() => {
            // 最後のページから削除してた場合は
            // currentPageまでたどり着かないので増やす
            let pageDiff = this.currentPage - this.pages.length
            while (pageDiff > 0) {
              this.addNewPage()
              pageDiff--
            }
          })
        this.showDocumentEditorModal = false
      })
      .catch(err => {
        this.handleErrorResponse(err)
      })
    },
    saveDocumentName(document) {
      api.updateDocument({
        id: this.meta.id,
        document: {
          id: document.id,
          name: document.name,
        }
      }).then(() => {
        document.orig_name = document.name
      })
    }
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
