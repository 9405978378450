<template>
  <a class="http-link" :href="internalHref">
    <slot></slot>
  </a>
</template>
<script>
import mediaMixin from '@/mixin/mediaMixin'

export default {
  name: 'http-link',
  props: {
    href: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      internalHref: '',
    }
  },
  watch: {
    href() {
      this.setLinkUrl()
    }
  },
  mounted() {
    this.setLinkUrl()
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.internalHref)
  },
  mixins: [mediaMixin],
  methods: {
    setLinkUrl() {
      this.getBlobUrl(this.href)
        .then(({ url, filename }) => {
          this.internalHref = url
          if (filename) {
            this.$el.download = filename
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
