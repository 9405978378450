<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import officeApi from '@/api/office'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import validatorMixin from '@/mixin/validatorMixin'
import masterMixin from '@/mixin/masterMixin'

const WORK_SUMMARY_FORMAT = {
  '8_4': [8, 4, 4],
  '9_5': [9, 5, 5],
  '9_6': [9, 6, 6],
}
const defaultWorkSummaryFormat = WORK_SUMMARY_FORMAT['8_4']

export default {
  name: 'officeDetail',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      isNew: true,
      office: {
        name: '',
        shutokou_department_name: '',
        shutokou_office_name: '',
        office_work_summary_patterns: [],
        work_summary_format: defaultWorkSummaryFormat,
      },
      workSummaryFormatToSet: null,
      officeWorkSummaryPattern: null,
      officeWorkSummaryPatternToDelete: null,

      showCreateCompleteModal: false,
      showUpdateCompleteModal: false,
      showUpdatePatternCompleteModal: false,

      errors: {},
      patternErrors: {},
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    hasPatternError() {
      return Object.keys(this.patternErrors).length > 0
    },
    errorMessages() {
      const msgs = Object.keys(this.errors).map(k => {
        return this.errors[k]
      }, [])
      return Array.from(new Set([].concat(...msgs)))
    },
    patternErrorMessages() {
      const msgs = Object.keys(this.patternErrors).map(k => {
        return this.patternErrors[k]
      }, [])
      return Array.from(new Set([].concat(...msgs)))
    },
    isWorkSummaryFormat_8_4() {
      const format = WORK_SUMMARY_FORMAT['8_4']
      return this.office.work_summary_format.join() === format.join()
    },
    isWorkSummaryFormat_9_5() {
      const format = WORK_SUMMARY_FORMAT['9_5']
      return this.office.work_summary_format.join() === format.join()
    },
    isWorkSummaryFormat_9_6() {
      const format = WORK_SUMMARY_FORMAT['9_6']
      return this.office.work_summary_format.join() === format.join()
    },
    canSeeOfficeDetailPage() {
      if (this.has_role_admin) {
        return true
      }
      if (this.has_role_shunin) {
        // 管理者以外は自事務所のみ
        return parseInt(this.id) === this.user.office_id
      }
      return false
    },
  },
  async mounted() {
    await this.waitForUserAndMasters()
    if (!this.canSeeOfficeDetailPage) {
      location.href = '/'
    }

    if (this.$route.name === 'officeNew') {
      this.isNew = true
    } else {
      this.isNew = false
      this.getOffice_()
    }
  },
  mixins: [errorHandleMixin, validatorMixin, masterMixin],
  methods: {
    convOffice_(data) {
      data.users = data.users.filter(user => {
        // 管理者・総括を除外
        if (user.role === 100 || user.role === 201) { return false }
        // 有効なユーザーのみ取得
        const today = new Date(new Date().setHours(0, 0, 0, 0))
        const dateStrToDate = Vue.filter('ensureDate')
        const useStartDate = dateStrToDate(user.use_start_date)
        const useEndDate = dateStrToDate(user.use_end_date)
        return useStartDate <= today && today <= useEndDate
      })

      data = this.parseJson_(data)
      return data
    },
    parseJson_(data) {
      try {
        // 配列に変換
        data.work_summary_format =
          JSON.parse(data.work_summary_format) || defaultWorkSummaryFormat
      } catch (e) {
        // デフォルト値をセット
        data.work_summary_format = defaultWorkSummaryFormat
      }
      data.office_work_summary_patterns =
        data.office_work_summary_patterns.map(pattern => {
          try {
            // 配列に変換
            pattern.inspector_ids = JSON.parse(pattern.inspector_ids) || []
          } catch (e) {
            // 仕方ないので初期化
            pattern.inspector_ids = []
          }
          return pattern
        })

      return data
    },
    getOffice_() {
      const obj = {id: this.id}
      officeApi.show(obj)
        .then(({ data }) => {
          this.office = this.convOffice_(data)
        })
    },
    onChangeWorkSummaryFormat(format) {
      this.workSummaryFormatToSet = WORK_SUMMARY_FORMAT[format]
    },
    setWorkSummaryFormat() {
      this.office.work_summary_format = this.workSummaryFormatToSet
      this.workSummaryFormatToSet = null
    },
    addOfficeWorkSummaryPattern() {
      this.officeWorkSummaryPattern = {
        pattern_name: '',
        chief_supervisor: '',
        management_engineer: '',
        inspector_ids: this.office.users.map(user => user.id),
      }
      if (this.officeWorkSummaryPattern.inspector_ids.length === 0) {
        this.officeWorkSummaryPattern.inspector_ids = ['']
      }
    },
    editOfficeWorkSummaryPattern(pattern) {
      // 編集用にコピー
      this.officeWorkSummaryPattern = JSON.parse(JSON.stringify(pattern))
    },
    copyOfficeWorkSummaryPattern(pattern) {
      const newPattern = JSON.parse(JSON.stringify(pattern))
      // コピーしたくない項目を初期化する
      newPattern.id = null

      const props = [
        'pattern_name',
      ]
      props.forEach(val => {
        newPattern[val] = ''
      })
      this.officeWorkSummaryPattern = newPattern
    },
    addOfficeWorkSummaryPatternInspector() {
      this.officeWorkSummaryPattern.inspector_ids.push('')
    },
    removeOfficeWorkSummaryPatternInspector(idx) {
      this.officeWorkSummaryPattern.inspector_ids.splice(idx, 1)
    },
    checkInputs() {
      let inputErrors = {}
      let val

      if (this.officeWorkSummaryPattern) {
        // 業務状況報告書パターンの場合
        this.patternErrors = {}
        const validateProps = {
          pattern_name: 'パターン名',
          chief_supervisor: '主任監督員名',
          management_engineer: '管理技術者名',
        }
        Object.keys(validateProps).forEach(k => {
          val = this.officeWorkSummaryPattern[k]
          if (!val) {
            this.patternErrors[k] = [validateProps[k] + 'は必須項目です']
          } else if (val.length > 225) {
            this.patternErrors[k] = [validateProps[k] + 'は255文字以内で入力してください']
          }
        })
        // 検査員
        this.officeWorkSummaryPattern.inspector_ids =
          this.officeWorkSummaryPattern.inspector_ids.filter(id => id)
        const uniqueIds = Array.from(new Set(this.officeWorkSummaryPattern.inspector_ids))
        val = this.officeWorkSummaryPattern.inspector_ids
        if (val.length === 0) {
          this.officeWorkSummaryPattern.inspector_ids = ['']
          this.patternErrors['inspector_ids'] = ['検査員は必須項目です']
        } else if (val.length > uniqueIds.length) {
          this.patternErrors['inspector_ids'] = ['検査員が重複しています']
        }
        inputErrors = this.patternErrors
      } else {
        // 事務所の場合
        this.errors = {}
        const validateProps = {
          name: '事務所名',
          shutokou_department_name: '首都高速道路 部署名',
          shutokou_office_name: '首都高速道路 事務所名',
        }
        Object.keys(validateProps).forEach(k => {
          val = this.office[k]
          if (!val) {
            this.errors[k] = [validateProps[k] + 'は必須項目です']
          } else if (val.length > 225) {
            this.errors[k] = [validateProps[k] + 'は255文字以内で入力してください']
          }
        })
        inputErrors = this.errors
      }

      return Object.keys(inputErrors).length === 0
    },
    createOffice() {
      if (!this.checkInputs()) { return }

      const office = JSON.parse(JSON.stringify(this.office))
      // conv work_summary_format
      office.work_summary_format = JSON.stringify(office.work_summary_format)

      const obj = { data: office }
      officeApi.create(obj)
        .then(({ data }) => {
          this.office = this.convOffice_(data)
          this.showCreateCompleteModal = true
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    saveOffice() {
      if (!this.checkInputs()) { return }

      const office = JSON.parse(JSON.stringify(this.office))
      // conv work_summary_format
      office.work_summary_format = JSON.stringify(office.work_summary_format)

      const obj = {
        id: this.office.id,
        data: office
      }
      officeApi.update(obj)
        .then(() => {
          this.showUpdateCompleteModal = true
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    saveOfficeWorkSummaryPattern() {
      if (!this.checkInputs()) { return }

      const pattern = JSON.parse(JSON.stringify(this.officeWorkSummaryPattern))
      // conv inspector_ids
      pattern.inspector_ids = JSON.stringify(pattern.inspector_ids)

      const obj = {
        id: this.office.id,
        data: {
          pattern_id: pattern.id || null,
          office_work_summary_pattern: pattern
        }
      }
      officeApi.update_office_work_summary_pattern(obj)
        .then(({ data }) => {
          this.office = this.convOffice_(data)
          this.officeWorkSummaryPattern = null
          this.showUpdatePatternCompleteModal = true
        })
        .catch(err => {
          this.patternErrors = err.response.data
        })
    },
    deleteOfficeWorkSummaryPattern() {
      const obj = {
        id: this.office.id,
        patternId: this.officeWorkSummaryPatternToDelete.id,
      }
      officeApi.delete_office_work_summary_pattern(obj)
        .then(() => {
          this.officeWorkSummaryPatternToDelete = null
          this.getOffice_()
        })
        .catch(err => {
          this.errors = err.response.data
        })
    },
    closeCreateCompleteModal() {
      this.$router.replace({
        name: 'officeDetail',
        params: { id: this.office.id }
      })
      this.showCreateCompleteModal = false
      window.setTimeout(() => {
        this.isNew = false
        this.getOffice_()
      }, 0)
    },
    closeOfficeWorkSummaryPatternModal() {
      this.officeWorkSummaryPattern = null
      this.patternErrors = {}
    }
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
