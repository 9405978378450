import masterApi from '@/api/master'

export default {
  methods: {
    loadMasters() {
      window.master = {
        $promise: masterApi.getAll()
      }
      window.master.$promise.then(({ data }) => {
        const now = new Date()
        Object.values(data.lovs).forEach(obj => {
          obj.map = obj.vals.reduce((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
          // 期限内のもののみ抽出したもの
          obj.vals_inuse = obj.vals.filter(e => {
            return new Date(e.start_use) <= now &&
              now < new Date(e.end_use)
          })
          obj.map_inuse = obj.vals_inuse.reduce((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
        })

        const prms = window.master.$promise
        window.master = data
        window.master.$promise = prms
      })
    },
    async waitForMasters(arg) {
      await window.master.$promise
      return arg
    },
    async waitForUserAndMasters(arg) {
      const promises = [
        this.$store.dispatch('user/getMe'),
        window.master.$promise,
      ]
      await Promise.all(promises)
      return arg
    },
  }
}
