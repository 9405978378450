<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import workSummaryReportApi from '@/api/workSummaryReport'
import userApi from '@/api/user'
import masterMixin from '@/mixin/masterMixin'
import listPageMixin from '@/mixin/listPageMixin'
import textAreaMixin from '@/mixin/textAreaMixin'
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'workSummaryReportEntryList',
  data() {
    return {
      isReady: false,

      search: {
        dtFrom: '',
        dtTo: '',
        officeId: null,
        userId: null,
      },

      entries: [],
      offices: [],
      usersOrig: [],

      defaultWorkSummaryFormat: [8, 4, 4],
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    users() {
      if (!this.search.officeId) { return this.usersOrig }
      return this.usersOrig.filter(e => {
        return e.office_id === this.search.officeId
      })
    },
    areSearchParamsReady() {
      const { dtFrom, dtTo, officeId, userId } = this.search

      if (!this.isDateString(dtFrom)) {
        return false
      }
      if (!this.isDateString(dtTo)) {
        return false
      }
      const dtFormat = Vue.filter('dtFormat')
      const dtStrFrom = dtFormat(dtFrom, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(dtTo, 'yyyy/mm/dd')
      if ((new Date(dtStrTo) - new Date(dtStrFrom)) > (31 - 1) * 24 * 60 * 60 * 1000) {
        return false
      }

      return !!dtFrom && !!dtTo && !!officeId && !!userId
    },
    hasList() {
      return this.entries.length > 0
    },
    workSummaryFormatClass() {
      const { outsideWorkRows, insideWorkRows } = this.getWorkSummaryFormat()
      return `work-summary-format-${outsideWorkRows}-${insideWorkRows}`
    },
  },
  async mounted() {
    await this.waitForUserAndMasters()
    this.prepareMasters()
    await this.getUsers()
    this.prepareSearchParams()
    await this.doSearch()
    this.isReady = true
  },
  mixins: [masterMixin, listPageMixin, textAreaMixin, dateMixin],
  methods: {
    prepareMasters() {
      let offices = window.master.offices
      if (
        this.user.is_soukatsu ||
        this.user.is_shunin ||
        this.user.is_sagyou
      ) {
        offices = offices.filter(e => {
          return e.id === this.user.office_id
        })
      }
      this.offices = offices
    },
    async getUsers() {
      let usersOrig = []
      if (this.user.is_sagyou) {
        usersOrig = [this.user]
      } else {
        let prms = null
        if (this.user.is_admin) {
          prms = userApi.getAll()
        } else if (
          this.user.is_soukatsu ||
          this.user.is_shunin
        ) {
          prms = userApi.getMyOfficeUser()
        }
        const { data } = await prms
        usersOrig = data
      }

      const dateStrToDate = Vue.filter('ensureDate')
      const todayTs = new Date(new Date().setHours(0, 0, 0, 0)).valueOf()
      this.usersOrig = usersOrig.filter(e => {
        // 総括以上は日報を作成しないので除外
        if (e.has_role_soukatsu) { return false }

        // 現在有効なユーザーのみ表示
        const useStartDate = dateStrToDate(e.use_start_date)
        const useEndDate = dateStrToDate(e.use_end_date)
        return useStartDate.valueOf() <= todayTs &&
          todayTs <= useEndDate.valueOf()
      })
    },
    prepareSearchParams() {
      let dtFrom, dtTo, officeId, userId

      // 当月初日〜末日
      const { dtStrFrom, dtStrTo } = this.getListSearchParamsForTheMonth()
      const storage = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        dtFrom: dtStrFrom,
        dtTo: dtStrTo,
        officeId: this.user.office_id,
        userId: this.has_role_soukatsu ? null : this.user.id,
      }
      if (storage.dtFrom) {
        dtFrom = storage.dtFrom
      }
      if (storage.dtTo) {
        dtTo = storage.dtTo
      }
      if (storage.officeId) {
        officeId = storage.officeId
      }
      if (storage.userId) {
        userId = storage.userId
      }

      if (
        this.user.is_soukatsu ||
        this.user.is_shunin
      ) {
        officeId = this.user.office_id
        const tmpUser = this.usersOrig.find(e => {
          return e.id === userId
        })
        if (!tmpUser || tmpUser.office_id !== this.user.office_id) {
          userId = this.user.is_soukatsu ? null : this.user.id
        }
      } else if (this.user.is_sagyou) {
        officeId = this.user.office_id
        userId = this.user.id
      }

      this.search = { dtFrom, dtTo, officeId, userId }
    },
    async doSearch() {
      if (!this.areSearchParamsReady) { return }

      const { dtFrom, dtTo, officeId, userId } = this.search
      sessionStorage.setItem(
        this.$route.name,
        JSON.stringify({ dtFrom, dtTo, officeId, userId })
      )

      const reqObj = {
        from: dtFrom,
        to: dtTo,
        office_id: officeId,
      }
      const { data } = await workSummaryReportApi.search(reqObj)
      this.entries = this._getWorkSummaryReportEntries(data)
    },
    _getWorkSummaryReportEntries(data) {
      let entries = []
      if (data.length === 0) { return entries }

      const entryMap = data.reduce((acc, workSummaryReport) => {
        const entry = workSummaryReport.work_summary_report_entries.find(entry => {
          return entry.inspector_id === this.search.userId
        }) || {}
        entry.workSummaryDate = workSummaryReport.work_summary_date // yyyy-mm-dd
        acc[entry.workSummaryDate] = entry
        return acc
      }, {})

      const { dtFrom, dtTo } = this.search
      const dateStrToDate = Vue.filter('ensureDate')
      const dtFromTs = dateStrToDate(dtFrom).valueOf()
      const dtToTs = dateStrToDate(dtTo).valueOf()
      const dayDiff = parseInt((dtToTs - dtFromTs) / 24 / 60 / 60 / 1000)
      const dayIdxs = [...Array(dayDiff + 1).keys()]

      const dtFormat = Vue.filter('dtFormat')
      const user = this.usersOrig.find(e => {
        return e.id === this.search.userId
      })
      entries = dayIdxs.map(dayIdx => {
        const dt = new Date(dtFromTs + dayIdx * 24 * 3600 * 1000)
        const workSummaryDate = dtFormat(dt, 'yyyy-mm-dd')
        const entry = entryMap[workSummaryDate] || { workSummaryDate }
        if (user.latest_transfer_date) {
          // 直近異動日より前の日報は無効
          const latestTransferDate = dateStrToDate(user.latest_transfer_date)
          entry.isInvalid = dt.valueOf() < latestTransferDate.valueOf()
        }
        return entry
      })

      return entries
    },
    onOfficeChange() {
      this.search.userId = null
    },
    getWorkSummaryFormat() {
      let workSummaryFormat = this.defaultWorkSummaryFormat

      const office = this.offices.find(e => {
        return e.id === this.search.officeId
      })
      if (office) {
        workSummaryFormat =
          this._parseJsonWorkSummaryFormat(office.work_summary_format)
      }

      return {
        outsideWorkRows: workSummaryFormat[0],
        insideWorkRows: workSummaryFormat[1],
        otherWorkRows: workSummaryFormat[2],
      }
    },
    _parseJsonWorkSummaryFormat(jsonFormat) {
      let format = []
      try {
        // 配列に変換
        format = JSON.parse(jsonFormat) || this.defaultWorkSummaryFormat
      } catch (e) {
        format = this.defaultWorkSummaryFormat
      }
      return format
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
