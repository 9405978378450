<template>
  <div class="footer" style="display:none;">
  </div>
</template>

<script>
export default {
  name: 'global-footer',
}
</script>
