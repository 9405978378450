<template src="./template.html"></template>

<script>
import Vue from 'vue'
import Stream from '@/lib/Stream'
import EVT from '@/consts/eventNames'
import photoApi from '@/api/photo'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import textAreaMixin from '@/mixin/textAreaMixin'

let api

export default {
  name: 'detail-photos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    apiName: {
      type: String,
      required: true,
    },
    photoType: {
      type: String,
      required: true,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      meta: {},

      report: {},
      photos: [],
      photoMap: [],
      pages: [],
      maxRows: 3,

      currentPage: 1,
      maxPage: 20,

      photoDate: '',
      photoList: [],
      selectedPhoto: null,
      photoSelectorModalTitle: '写真選択',
      showPhotoEditorModal: false,
      showUpdateCompleteModal: false,

      stream: new Stream(),
      photoSelectorStream: new Stream(),
      errors: {},
    }
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1
    },
    isLastPage() {
      return this.currentPage === this.pages.length
    },
    hasMaxPage() {
      return this.pages.length >= this.maxPage
    },
    printPageRouteName() {
      const routeName = this.$route.name
      const first = routeName[0]
      const rest = routeName.slice(1)
      return `print${first.toUpperCase()}${rest}`
    },
    errorMessages() {
      const arr = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        arr.push(...msgs)
      })
      return Array.from(new Set(arr))
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
  },
  mounted() {
    api = require(`@/api/${this.apiName}`).default
    this.getData()
    this.connectStreams()
  },
  mixins: [errorHandleMixin, textAreaMixin],
  methods: {
    getPhotos() {
      photoApi.search({ date: this.photoDate })
        .then(({ data }) => {
          this.photoList = data
        })
    },
    connectStreams() {
      const photoSelector = this.photoSelectorStream
      const me = this.stream
      me.chain(EVT.PHOTO.SELECTOR_RESET).to(photoSelector).end()
    },
    getData() {
      return api.show({id: this.id, photo_type: this.photoType}).then(({ data }) => {
        if (this.photoType === 'inspectionContentPhotoList') {
          data.photos = data.inspection_content_photos
        } else if (this.photoType === 'inspectionResultContentPhotoList') {
          data.photos = data.inspection_result_content_photos
        } else if (this.photoType === 'correctionContentBeforePhotoList') {
          data.photos = data.correction_content_before_photos
        } else if (this.photoType === 'correctionContentAfterPhotoList') {
          data.photos = data.correction_content_after_photos
        }

        data.photos.forEach(photo => {
          if (photo.used_index !== null) {
            photo.idx = photo.used_index
          }
        })
        this.photoDate = data.inspection_date || data.inspection_report.inspection_date
        data.inspection_date = Vue.filter('dtFormatYMDA1')(data.inspection_date)
        this.report = data

        this.photos = data.photos
        this.photoMap = this.photos.reduce((obj, photo) => {
          obj[photo.report_photo_id] = photo; return obj
        }, {})
        this.pages = this.getPages(data)
      }).then(() => {
        this.getPhotos()
      })
    },
    getEmptyPage(idx) {
      return {
        idx: idx,
        is_empty: true,
        caption: '',
        location: '',
      }
    },
    canPrint(photoType) {
      return photoType !== 'inspectionContentPhotoList' &&
        photoType !== 'inspectionResultContentPhotoList' &&
        photoType !== 'correctionContentBeforePhotoList' &&
        photoType !== 'correctionContentAfterPhotoList'
    },
    getPages(data) {
      const ret = []
      const usedPhotoIds = this.photos.reduce((arr, photo) => {
        if (photo.used_index !== null) {
          arr[photo.used_index] = photo.report_photo_id
        }
        return arr
      }, [])
      const len = usedPhotoIds.length
      if (len === 0) {
        ret.push({photos: [
          this.getEmptyPage(0), this.getEmptyPage(1), this.getEmptyPage(2),
        ]})
        return ret
      }

      const halfLen = Math.ceil(len / this.maxRows)
      for (let i = 0; i < halfLen; i++) {
        const idxBase = i * this.maxRows
        let photos = []

        for (let index = 0; index < this.maxRows; index++) {
          const idx = idxBase + index
          let photo = (idx < len) ? this.photoMap[usedPhotoIds[idx]] : null
          if (!photo) {
            photo = this.getEmptyPage(idx)
          }
          photos.push(photo)
        }
        ret.push({photos: photos})
      }
      return ret
    },
    toPrevPage() {
      this.currentPage = Math.max(1, this.currentPage - 1)
    },
    toNextPage() {
      this.currentPage = Math.min(this.currentPage + 1, this.pages.length)
    },
    addNewPage() {
      const nextIdx = this.pages.length * this.maxRows
      this.pages.push({
        photos: [
          this.getEmptyPage(nextIdx),
          this.getEmptyPage(nextIdx + 1),
          this.getEmptyPage(nextIdx + 2)
        ]
      })
    },
    toNewPage() {
      this.addNewPage()
      this.toNextPage()
    },
    showPhotoEditor(photo) {
      this.selectedPhoto = photo
      this.stream.send(EVT.PHOTO.SELECTOR_RESET)
      this.showPhotoEditorModal = true
    },
    onPhotoSelectorModeChange({ mode, title }) {
      this.photoSelectorModalTitle = title
    },
    onPhotoShouldRefresh() {
      this.$emit('reset-edit-status')
      this.getData()
    },
    onUsePhoto(photo) {
      const promises = []
      if (this.selectedPhoto.id !== photo.id) {
        promises.push(api.addPhoto({
          id: this.id,
          photo: {
            photo_id: photo.id,
            used_index: this.selectedPhoto.idx,
            photo_type: this.photoType
          }
        }))
        if (this.selectedPhoto.id) {
          // 付け替えの場合は元のやつから紐付けを削除する
          promises.push(api.deletePhoto({
            id: this.id,
            reportPhotoId: this.selectedPhoto.report_photo_id
          }))
        }
      }
      Promise.all(promises)
        .then(() => {
          this.getData()
          this.showPhotoEditorModal = false
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
      this.$emit('reset-edit-status')
    },
    onUnusePhoto(photo) {
      api.deletePhoto({
        id: this.id,
        reportPhotoId: this.selectedPhoto.report_photo_id
      }).then(() => {
        this.getData()
          .then(() => {
            // 最後のページから削除してた場合は
            // currentPageまでたどり着かないので増やす
            let pageDiff = this.currentPage - this.pages.length
            while (pageDiff > 0) {
              this.addNewPage()
              pageDiff--
            }
          })
        this.showPhotoEditorModal = false
      }).catch(err => {
        this.handleErrorResponse(err)
      })
      this.$emit('reset-edit-status')
    },
    savePhotoCaption() {
      this.errors = {}
      this.photos.forEach(photo => {
        const photoNum = photo.used_index + 1
        if (photo.location.length > 255) {
          this.errors[`location${photoNum}`] = [`写真${photoNum}の場所は255文字以内で入力して下さい`]
        }
        if (photo.caption.length > 255) {
          this.errors[`caption${photoNum}`] = [`写真${photoNum}の説明は255文字以内で入力して下さい`]
        }
      })
      if (Object.keys(this.errors).length > 0) { return }

      api.bulkUpdatePhotos({
        id: this.id,
        photos: this.photos,
      }).then(() => {
        this.showUpdateCompleteModal = true
        // TODO
      }).catch(err => {
        this.handleErrorResponse(err)
      })
      this.$emit('reset-edit-status')
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
