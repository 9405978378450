import axios from 'axios'
import * as utils from './apiUtils'

export default {
  login(data) {
    // wrap data in 'user' object for devise.
    return axios.post(utils.getApiUrl('/users/login'), {
      user: data
    })
  },
  logout() {
    return axios.delete(utils.getApiUrl('/users/logout'))
  },
  getMe() {
    return axios.get(utils.getApiUrl('/users/me'))
  },
  getMyOfficeUser() {
    return axios.get(utils.getApiUrl('/users/my_office_users'))
  },
  getMyOfficeNotAdminUser() {
    return axios.get(utils.getApiUrl('/users/my_office_not_admin'))
  },
  getMyOfficeChiefUser() {
    return axios.get(utils.getApiUrl('/users/my_office_chiefs'))
  },
  getAll() {
    return axios.get(utils.getApiUrl('/users/all'))
  },
  create(data) {
    return axios.post(utils.getApiUrl(`/users`), data)
  },
  update(data) {
    return axios.put(utils.getApiUrl(`/users/${data.id}`), data)
  },
  delete(data) {
    return axios.delete(utils.getApiUrl(`/users/${data.id}`))
  },
  downloadCsv(ids) {
    return axios.post('/users/download.csv', ids, {
      responseType: 'blob'
    })
  },
  uploadCsv({ formData }) {
    return axios.post(utils.getApiUrl(`/users/upload`), formData)
  },
}
