import axios from 'axios'
import * as utils from './apiUtils'

export default {
  getDiskUsage() {
    return axios.get(utils.getApiUrl('/logs/get_disk_usage'))
  },
  getErrorAlerts() {
    return axios.get(utils.getApiUrl('/logs/error_alerts'))
  },
  downloadErrorAlert({ id }) {
    return axios.get(`/logs/error_alerts/download/${id}`, {
      responseType: 'blob'
    })
  },
}
